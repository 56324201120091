<template>
    <div class="mainbar" >
        <template v-if="this.menu.items && this.menu.items.length">
            <div
            
                v-for="chatEntry,index in currentChatItems"
                class="chat-item text-no-underline d-flex align-items-center py-2 py-md-3 px-3 position-relative"
                :class="{ new: chatEntry.unread }"
                :key="index"
            >
                <a :href="chatEntry.route" @click.prevent="$emit('replace-url', chatEntry.route)" class="overlay"></a>

                <div v-if="chatEntry.customer" class="logo-box">
                    <img
                        v-if="chatEntry.customer.type === 'private'"
                        width="20"
                        height="20"
                        src="/images/icons/icon-privatperson.svg"
                        alt="Icon Privatperson"
                    />
                    <img v-else-if="chatEntry.customer.logo" :src="chatEntry.customer.logo" :alt="'Logo ' + chatEntry.customer.display_name" />
                    <strong v-else class="text-muted text-center">{{ chatEntry.customer.display_name }}</strong>
                </div>
                <div class="text-box px-lg-3 mt-2 mt-lg-0">
                    <strong class="d-flex align-items-center">
                        {{ chatEntry.title }}
                        <img v-if="chatEntry.badge === 'immo'" class="ms-2" src="/images/immo/bagde-immo.svg" alt="Badge Immomarkt" />
                        <img v-if="chatEntry.badge === 'job'" class="ms-2" src="/images/job/badge-job.svg" alt="Badge Jobmarkt" />
                    </strong>
                    <span class="message-text mt-1 d-block text-ellipsis">
                        {{ chatEntry.description }}
                    </span>
                </div>
                <div class="action-box">
                    <span class="text-right text-small text-nowrap text-muted d-block">{{ chatEntry.date }}</span>
                    <div v-if="!chatEntry.archived" class="justify-content-end mt-1 d-none d-lg-flex">
                        <!-- <a href="#" @click.prevent="$emit('trash-chat', chatEntry.chat_id)" class="action-button text-no-underline">
                            <img src="/images/icons/icon-trash.svg" alt="Icon Delete" />
                        </a> -->
                        <a href="#" @click.prevent="$emit('archive-chat', chatEntry.id)" class="action-button text-no-underline me-2 ">
                            <img src="/images/icons/icon-archiv.svg" alt="Icon Archiv" />
                        </a>
                        <a :href="chatEntry.route" @click.prevent="$emit('replace-url', chatEntry.route)" class="action-button text-no-underline  me-2 ">
                            <img src="/images/icons/icon-reply.svg" alt="Icon Reply" />
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <div v-else class="h-100 d-flex align-items-center justify-content-center p-3">
            <p class="text-muted text-center mt-3">{{ $t('fe.chat.no_entries') }}</p>
        </div>

        <div v-if="this.menu.hasMorePages" class="text-no-underline d-flex align-items-center py-2 py-md-3 px-3 position-relative">
            <div v-if="loading" class="mt-3">
                <div class="loader loader-sm"></div>
            </div>
            <a @click="loadmore()" v-else class="btn btn-primary btn-bs mt-3">{{ $t('fe.chat.loadmore') }}</a>
        </div>

    </div>
</template>

<script>
import Swal from 'sweetalert2'
export default {
    name: 'ChatList',
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        async loadmore(){
            if(!this.menu.hasMorePages) return
            if (this.loading) return
            this.loading = true
            try {
                const response = await fetch(`/api/profil/${this.theme}/chat/loadmore/${this.menu.type}/?page=${this.menu.currentPage+1}`)
                const data = await response.json()
                this.menu.items.push(...data.items)
                this.menu.currentPage = data.currentPage
                this.menu.hasMorePages = data.hasMorePages
                this.loading = false
            } catch (error) {
                Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
            }

        }
    },
    computed: {
        currentChatItems(){
            if(this.filteredCustomer){
                return this.menu.items.filter(item => item.customer && item.customer.id === this.filteredCustomer);
            }
            return this.menu.items
        }
    },
    props: {
        menu: null,
        theme: String,
        filteredCustomer: null
    },
}
</script>
