<template>
    <div class="info-content">
        <p class="tincx-h5 mt-3 mt-lg-0">
            <strong>{{ $t('fe.category.floh.list.info.info_title') }}</strong>
        </p>
        <div class="d-flex align-items-center response-place-container" v-if="selectedItem.place">
            <img width="15" height="17" src="/images/icons/icon-location.svg" class="me-1 d-block" />
            <span class="response-place tincx-small">{{ selectedItem.place }}</span>
        </div>
        <div class="d-flex align-items-center mt-2" v-if="false && 'todo-clicks'">
            <img width="20" height="16" src="/images/icons/icon-views.svg" class="me-1 d-block" />
            <span class="tincx-small">
                <span class="response-views">112</span>
                <span class="single-views d-none">Klick</span>
                <span class="multiple-views">Klicks</span>
            </span>
        </div>
        <p v-if="selectedItem.price" class="tincx-h4 response-price-container">
            <span class="response-price">{{ $f.number(selectedItem.price) }}</span>&nbsp;€ <span v-if="selectedItem.verhandelbar">{{ $t('fe.category.floh.price_verhandelbar') }}</span>
        </p>
        <p v-else class="tincx-h4 response-price-container"><span class="response-price">{{ $t('fe.category.floh.list.info.price_on_request') }}</span></p>

        <div class="mt-2">
            <ContactButton :config="buttonConfig" :inline="true" />
        </div>

        <hr class="my-3" />

        <div class="response-user-container" v-if="private && selectedItem.customer">
            <span class="tincx-small text-muted">{{ $t('fe.category.floh.list.info.provider') }}</span>
            <p class="tincx-h5 mt-2 mb-1 response-user_name">{{ selectedItem.customer.agency }}</p>
            <div class="bewertungen" v-if="false && 'todo-rating'">
                <svg class="text-gold" width="14.7" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 14">
                    <path
                        fill="currentcolor"
                        d="M7.3,11.3l4.6,2.7-1.2-5.2,4-3.5-5.3-.5L7.3,0l-2.1,4.9L0,5.4l4,3.5-1.2,5.2,4.6-2.7h0Z"
                    ></path>
                </svg>
                <svg class="text-gold" width="14.7" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 14">
                    <path
                        fill="currentcolor"
                        d="M7.3,11.3l4.6,2.7-1.2-5.2,4-3.5-5.3-.5L7.3,0l-2.1,4.9L0,5.4l4,3.5-1.2,5.2,4.6-2.7h0Z"
                    ></path>
                </svg>
                <svg class="text-gold" width="14.7" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 14">
                    <path
                        fill="currentcolor"
                        d="M7.3,11.3l4.6,2.7-1.2-5.2,4-3.5-5.3-.5L7.3,0l-2.1,4.9L0,5.4l4,3.5-1.2,5.2,4.6-2.7h0Z"
                    ></path>
                </svg>
                <svg class="text-gold" width="14.7" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 14">
                    <path
                        fill="currentcolor"
                        d="M7.3,11.3l4.6,2.7-1.2-5.2,4-3.5-5.3-.5L7.3,0l-2.1,4.9L0,5.4l4,3.5-1.2,5.2,4.6-2.7h0Z"
                    ></path>
                </svg>
                <svg class="text-muted" width="14.7" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.7 14">
                    <path
                        fill="currentcolor"
                        d="M7.3,11.3l4.6,2.7-1.2-5.2,4-3.5-5.3-.5L7.3,0l-2.1,4.9L0,5.4l4,3.5-1.2,5.2,4.6-2.7h0Z"
                    ></path>
                </svg>
            </div>
            <p class="d-flex align-items-center mt-2 mb-0 response-offers">
                <img src="/images/icons/icon-rakete.svg" alt="Offers" class="me-2" />
                    <strong class="response-offers me-1"> {{ selectedItem.customer.floh }}</strong> <span class="single-offer">{{ $t('fe.category.floh.list.info.offers_online_info') }}</span>
            </p>
        </div>

        <div class="response-agency-container" v-else>
            <span v-if="selectedItem.customer && selectedItem.customer.top" class="badge mb-3 mb-xl-4 response-agency_top"
                >{{ $t('fe.category.floh.top_company')}}</span
            >
            <div id="logo-desktop" v-if="selectedItem.customer && selectedItem.customer.logo">
                <div class="logo-sidebar response-logo">
                    <img :src="selectedItem.customer.logo" :alt="`Logo ${selectedItem.customer.name}`" />
                </div>
            </div>



            <p class="d-flex align-items-center mt-2 mb-0 response-head_office-container" v-if="selectedItem.customer.head_office">
                <img src="/images/icons/icon-location.svg" class="me-2" /><strong
                    class="me-1"
                    >{{ $t('fe.category.floh.list.info.main_location') }}</strong
                >
                <span class="response-head_office">{{ selectedItem.customer.head_office }}</span>
            </p>


            <p class="mt-2 mt-lg-4 mb-4 response-location-container" v-if="selectedItem.customer.location">
                <a
                    :href="selectedItem.customer.location"
                    class="response-location"
                    target="_blank"
                    >{{ $t('fe.category.floh.list.info.view_location') }}</a
                >
            </p>

            <div class="response-agency_description-container" v-if="selectedItem.customer.description">
                <p class="mt-4 mb-1"><strong>{{ $t('fe.category.floh.list.info.about_us') }}</strong></p>
                <p class="mt-1 response-agency_description">
                    {{  selectedItem.customer.description }}
                </p>
            </div>

            <a
                v-if="selectedItem.customer.url"
                :href="selectedItem.customer.url"
                class="response-agency_id btn-transparent mt-4"
                >{{ $t('fe.category.floh.list.info.more_about_the_company') }}
                <svg
                    width="15.5"
                    height="15.5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 15.6 15.6"
                >
                    <path
                        fill="#231f20"
                        d="M7.8,0C3.5,0,0,3.5,0,7.8s3.5,7.8,7.8,7.8,7.8-3.5,7.8-7.8S12.1,0,7.8,0ZM7.8,14.3c-3.6,0-6.5-2.9-6.5-6.5S4.2,1.3,7.8,1.3c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5Z"
                    ></path>
                    <path
                        fill="#231f20"
                        d="M11.4,7.3s0,0,0,0l-2.3-2.3c-.3-.3-.7-.3-.9,0s-.3.7,0,.9l1.3,1.3h-4.8c-.4,0-.6.3-.6.6s.3.6.6.6h4.8l-1.3,1.3c-.3.3-.3.7,0,.9s.3.2.5.2.3,0,.5-.2l2.3-2.3s0,0,0,0c.1-.1.2-.3.2-.5s0-.4-.2-.5Z"
                    ></path>
                </svg>
            </a>
        </div>


        <p class="d-flex align-items-center mt-3 mb-0" v-if="selectedItem.editUrl">            
            <a :href="selectedItem.editUrl" class="btn btn-bs bg-warning">
                <img src="/images/icons/icon-edit-white.svg" alt="Edit" class="me-2" /> <strong>{{ $t('generic.edit_ad') }}</strong>
            </a>
        </p>
        <p class="d-flex align-items-center mt-3 mb-0 inserat-melden-container" v-else-if="selectedItem.reportUrl">
            <img src="/images/icons/icon-flag.svg" alt="Offers" class="me-2" />
            <a :href="selectedItem.reportUrl" class="text-muted inserat-melden text-small">
                <strong>{{ $t('generic.report_ad') }}</strong>
            </a>
        </p>



        <div class="mt-4" v-if="false && 'todo-ads'">
            <img class="d-none d-lg-block mx-auto" width="160" height="600" src="/images/demo-160x600.jpg" title="Demo 160 x 600" />
            <img
                class="d-block d-lg-none mx-auto"
                width="300"
                height="250"
                src="/images/demo-optional-300x250.jpg"
                title="Demo 300 x 250"
            />
        </div>
    </div>
</template>

<script>
import ContactButton from './ContactButton.vue'

export default {
    name: 'FlohTitleInfo',
    props: ['selectedItem', 'buttonConfig'],
    components: { ContactButton },
    inject: ['$t', '$f'],
    computed: {
        private: function () {
            return this.selectedItem.customer && this.selectedItem.customer.type == 'private'
        },
    },
}
</script>
