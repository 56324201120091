import { createApp } from 'vue/dist/vue.esm-bundler.js'
import { i18nVue, trans } from 'laravel-vue-i18n'

import Login from './components/Login.vue'
import PasswordReset from './components/PasswordReset.vue'
import JobList from './components/JobList.vue'
import ImmoList from './components/ImmoList.vue'
import FlohList from './components/FlohList.vue'
import AutoList from './components/AutoList.vue'
import Register from './components/Register.vue'
import Profile from './components/Profile.vue'
import JobCandidatesList from './components/JobCandidatesList.vue'
import ProfileOfferList from './components/ProfileOfferList.vue'
import ProfileJobSettings from './components/ProfileJobSettings.vue'
import ProfileCompanyPage from './components/ProfileCompanyPage.vue'
import OffersCreateEntry from './components/offers/CreateEntry.vue'
import OffersFormFloh from './components/offers/inc/FormFloh.vue'
import OffersFormJob from './components/offers/inc/FormJob.vue'
import OffersFormAuto from './components/offers/inc/FormAuto.vue'
import OffersFormImmo from './components/offers/inc/FormImmo.vue'

import php_de from '../../resources/lang/php_de.json'

import './plugins/vee-validate'
import mitt from './plugins/mitt'
window.mitt = mitt
import {DateTime } from 'luxon'
//window.luxon = DateTime

import Swal from 'sweetalert2'
import JobAdvisor from '@/components/JobAdvisor.vue'
import JobApplication from '@/components/inc/form/JobApplication.vue'
import GenericApplication from '@/components/inc/form/GenericApplication.vue'
import Chat from "@/components/Chat.vue"
import GenericComplain from "@/components/inc/form/GenericComplain.vue"
window.Swal = Swal

window.$t = trans



const filters = {
    number: function(val) {
        if(!val) return val
        var tmp = parseInt(val)
        if(isNaN(tmp)) return val
        return tmp.toLocaleString()
    }
}

/*import { all } from '@vee-validate/rules';
Object.entries(all).forEach(([name, rule]) => {
  defineRule(name, rule);
});*/

/* MODALS */

var relativeTime = function (timeValue) {
    const dateTime = DateTime.fromISO(timeValue, { locale: 'de' }); // Parse with German locale
    const now = DateTime.now();
    return dateTime.toRelative({ base: now, locale: 'de' });
}
window.relativeTime = relativeTime

var displayDate = function (timeValue) {
    const dateTime = DateTime.fromISO(timeValue, { locale: 'de' }); // Parse with German locale
    const now = DateTime.now();
    return dateTime.toFormat('D')
}
window.displayDate = displayDate

if (document.getElementById('v-login-container')) {
    createApp(Login)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-login-container')
}

if (document.getElementById('v-register-container')) {
    createApp(Register)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-register-container')
}

if (document.getElementById('v-password-reset')) {
    createApp(PasswordReset)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-password-reset')
}

/* PROFILE */

if (document.getElementById('v-profile')) {
    createApp(Profile)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-profile')
}

if (document.getElementById('v-profile-job-settings')) {
    createApp(ProfileJobSettings)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-profile-job-settings')
}

if (document.getElementById('v-profile-company-page')) {
    createApp(ProfileCompanyPage)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-profile-company-page')
}

if (document.getElementById('v-profile-offer-list')) {
    createApp(ProfileOfferList)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-profile-offer-list')
}

/* EDIT AND CREATE PAGES */
if (document.getElementById('v-offers-create-entry')) {
    createApp(OffersCreateEntry)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-offers-create-entry')
}
if (document.getElementById('v-offers-form-floh')) {
    createApp(OffersFormFloh)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-offers-form-floh')
}
if (document.getElementById('v-offers-form-job')) {
    createApp(OffersFormJob)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-offers-form-job')
}
if (document.getElementById('v-offers-form-auto')) {
    createApp(OffersFormAuto)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-offers-form-auto')
}
if (document.getElementById('v-offers-form-immo')) {
    createApp(OffersFormImmo)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-offers-form-immo')
}

/* JOB-ADVISOR */
if (document.getElementById('v-job-advisor')) {
    createApp(JobAdvisor)
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-job-advisor')
}
/* Job-application */
if (document.getElementById('v-job-application')) {
    createApp(JobApplication, { ...document.getElementById('v-job-application').dataset })
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-job-application')
}

/* Generic-application */
if (document.getElementById('v-generic-application')) {
    createApp(GenericApplication, { ...document.getElementById('v-generic-application').dataset })
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-generic-application')
}
/* Generic Beschwerde */
if (document.getElementById('v-generic-complain')) {
    createApp(GenericComplain, { ...document.getElementById('v-generic-complain').dataset })
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-generic-complain')
}

/* LISTING PAGES */

if (document.getElementById('v-list-jobs')) {
    createApp(JobList)
        .provide('$t', trans)
        .provide('$f', filters)
        .provide('$rtime', relativeTime)
        .provide('$displayDate', displayDate)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-list-jobs')
}

if (document.getElementById('v-list-immo')) {
    createApp(ImmoList)
        .provide('$t', trans)
        .provide('$f', filters)
        .provide('$rtime', relativeTime)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-list-immo')
}

if (document.getElementById('v-list-auto')) {
    createApp(AutoList)
        .provide('$t', trans)
        .provide('$f', filters)
        .provide('$rtime', relativeTime)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-list-auto')
}

if (document.getElementById('v-list-floh')) {
    createApp(FlohList)
        .provide('$t', trans)
        .provide('$f', filters)
        .provide('$rtime', relativeTime)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-list-floh')
}

/* Chat */
if (document.getElementById('v-chat')) {
    createApp(Chat, { ...document.getElementById('v-chat').dataset })
        .provide('$t', trans)
        .provide('$f', filters)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-chat')
}

/* CANDIDATES LIST */
if (document.getElementById('v-job-candidates-list')) {
    createApp(JobCandidatesList, { ...document.getElementById('v-job-candidates-list').dataset })
        .provide('$t', trans)
        .provide('$f', filters)
        .provide('$rtime', relativeTime)
        .provide('$displayDate', displayDate)
        .use(i18nVue, {
            lang: 'de',
            resolve: (lang) => php_de,
        })
        .mount('#v-job-candidates-list')
}

