<template>
    <div class="row-large mx-0" v-if="type">
        <div class="sidebar ps-md-0 d-none d-md-flex sticky-card pt-0">
            <h2 class="tincx-h5 text-center mt-0 w-100">Vorschau</h2>
            <OffersPreview
                :data="formValues"
                :type="type"
                />
        </div>
        <div class="mainbar pe-0">
            <div class="scroll-box" id="scroll-box">
                <div class="large-card pt-4">
                    <div class="row-large">
                        <a :href="backButton" class="d-flex align-items-center tincx-p text-muted mb-3 mt-0  close-detail go-back">
                            <svg class="me-1" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                                <path fill="currentcolor" d="M7.75,15.5c4.27,0,7.75-3.48,7.75-7.75S12.02,0,7.75,0,0,3.48,0,7.75s3.48,7.75,7.75,7.75ZM7.75,1.29c3.58,0,6.46,2.88,6.46,6.46s-2.88,6.46-6.46,6.46S1.29,11.33,1.29,7.75,4.17,1.29,7.75,1.29Z"></path>
                                <path fill="currentcolor" d="M6.46,10.53c.3.3.7.3.89,0s.3-.7,0-.89l-1.29-1.29h4.77c.4,0,.6-.3.6-.6s-.3-.6-.6-.6h-4.77l1.29-1.29c.3-.3.3-.7,0-.89s-.3-.2-.5-.2-.3,0-.5.2l-2.29,2.29h0c-.1.1-.2.3-.2.5s2.58,2.78,2.58,2.78Z"></path>
                            </svg> Zurück 
                        </a>
                        <div class="steps-box mb-4" data-steps="3">
                            <p class="mt-0">
                                Schritt <strong> <span class="counter">{{ step }}</span> von 3 </strong>
                            </p>
                            <span class="steps d-block bg-light">
                                <span class="active-step d-block bg-primary" :style="`width:${Math.round(step * 100 / 3)}%`"></span>
                            </span>
                        </div>

                        <FormFloh
                            v-if="step==2 && type=='floh'"
                            @formValues="onFormValues"
                            @success="onSuccess"
                            />
                        <FormJob
                            v-if="step==2 && type=='job'"
                            @formValues="onFormValues"
                            @success="onSuccess"
                            />
                        <FormAuto
                            v-if="step==2 && type=='auto'"
                            @formValues="onFormValues"
                            @success="onSuccess"
                            />
                        <FormImmo
                            v-if="step==2 && type=='immo'"
                            @formValues="onFormValues"
                            @success="onSuccess"
                            />

                        <template v-if="step==3 && successData">
                            <div class="col-12">
                                <div class="alert alert-success pb-3 mb-3">
                                    <b>Vielen Dank!</b><br><br>
                                    <span v-if="successData.entry.active">Ihre Anzeige wurde veröffentlicht und ist nun auf unserem Portal sichtbar.</span>
                                    <span v-else>
                                        Ihre Anzeige wurde gespeichert und kann nun im Profil bearbeitet werden.<br><br>
                                        Um diese zu veröffentlichen, kannst du diese dort aktiv stellen!
                                    </span>
                                </div>

                                <OffersPreview
                                    :data="formValues"
                                />
                    
                                <a v-if="successData.entry.url" class="btn btn-primary btn-large mt-3" :href="successData.entry.url">Anzeige ansehen</a>
                                <a v-else class="btn btn-primary btn-large mt-3" :href="successData.entry.editUrl">Anzeige bearbeiten</a>

                                <div class="text-right mt-3">
                                    <a @click="reloadpage" href="" class="btn btn-general btn-bs btn-small mt-3">Weitere Anzeige erstellen</a>
                                </div>
                            </div>
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OffersPreview from '@/components/offers/inc/Preview.vue'
import FormFloh from '@/components/offers/inc/FormFloh.vue'
import FormJob from '@/components/offers/inc/FormJob.vue'
import FormAuto from '@/components/offers/inc/FormAuto.vue'
import FormImmo from '@/components/offers/inc/FormImmo.vue'

export default {
    name: 'OffersCreateEntry',
    components: {
        OffersPreview,
        FormFloh,
        FormJob,
        FormAuto,
        FormImmo
    },
    mounted: async function () {
        let parent = document.getElementById('v-offers-create-entry')
        if (parent && parent.dataset.type) {
            this.type = parent.dataset.type
        }
    },
    methods: {
        onFormValues: function(data) {
            console.log('createEntry onFormValues', data)
            this.formValues = {...data}
        },
        reloadPage: function() {
            window.location.reload()
        },
        onSuccess: function(data) {
            this.successData = data
            this.step++;
            setTimeout(() => {
                $('html, body').animate({scrollTop: $('.container').offset().top+50});
            }, 500)
        }
    },
    data() {
        return {
            backButton: '/anzeige-aufgeben', //todo: get from server
            data: null,
            popupChangePassword: false,
            private: true,
            step: 2,
            type: null,

            formValues: {},
            successData : null
        }
    },
}
</script>
<style lang="css" scoped>
.sticky-card { /*todo:verify mobile*/
    position: sticky !important;
    top: 150px;
}
</style>