<template>
    <div class="select-container col-auto mt-2 mt-lg-0 position-relative" v-if="multiple">
        <input type="text" :value="displayedTitle" readonly="readonly" class="badge-info"  :data-badge="value.length ? displayedTitle : undefined" >
        <div class="select-box checkboxes bg-white btn btn-ts text-left d-none" tabindex="-1" style="outline: 0;">
            <label class="d-flex align-items-center  me-2 mb-2">
                <input type="checkbox"  :checked="!value.length" @click="value = []"><span>{{ title }}: {{ $t('generic.show_all') }}</span>
            </label>
            <label class="d-flex align-items-center  me-2" v-for="item in items" :value="item.id" :key="item.id">
                <input type="checkbox"  :value="item.id" v-model="value"><span>{{ item.name }}</span>
            </label>
        </div>
    </div>
    <select v-else v-model="value" class="col-auto mt-2 mt-lg-0">
        <option :value="null">{{  title }}</option>
        <option v-for="item in items" :value="item.id">{{ item.name }}</option>
    </select>
</template>

<script setup>
import { computed } from 'vue'

const [value] = defineModel({
    value: {
        default: []
    }
})
const props = defineProps({
  items: {
    required: true
  },
  title: {
    required: true
  },
  multiple: {
    default: false
  }
})

const displayedTitle = computed(() => {
    const val = value.value
    if(!props.multiple) {
        return val && props.items.find(i => val == i.id) ? props.items.find(i => val == i.id).name : props.title
    }
    return val.length ? props.items.filter(i => val.includes(i.id)).map(i => i.name).join(', ') : props.title
})

</script>