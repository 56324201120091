<template>
    <div class="map-containter" v-if="uuid">
        <div class="col-12 mb-2">
            <div class="form-floating">
                <input 
                    @keypress.enter.prevent
                    :id="uuid" 
                    class="form-control"
                    type="text"
                    placeholder="Adresse auf Karte suchen..." />
                <label for="map-place-search">Adresse auf Karte suchen...</label>
            </div>
            <div class="info-feedback">Tippe, um eine Addresse auf der Karte zu suchen.</div>
        </div>
        <GoogleMap
            ref="map"
            :api-key="apiKey"
            style="width: 100%; height: 500px"
            :center="center"
            :zoom="zoom"
            @click="onMapClick"
        >
            <Marker 
                ref="marker"
                @dragend="onMarkerDrag"
                @click="marker = null"
                :options="{ position: marker, draggable: true, clickable: true }"  
                />
        </GoogleMap>
    </div>
</template>
<script>
import { GoogleMap, Marker, CustomControl } from 'vue3-google-map'
import { Loader } from "@googlemaps/js-api-loader"
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'MapForm',
    components: {
        CustomControl,
        GoogleMap,
        Marker
    },
    inject: ['$t'],
    props: {
        modelValue: {
            required: false,
        },
    },
    mounted: async function () {
        this.uuid = uuidv4()
        await this.$nextTick()
        const input = document.getElementById(this.uuid);
        if(input || true) {
            const loader = new Loader({
                apiKey: this.apiKey,
                version: "weekly",
                language: 'de'
            });
            const Places = await loader.importLibrary('places')
            const { Geocoder } = await loader.importLibrary('geocoding')
            this.mapsGeocoder = new Geocoder()
            const searchBox = new Places.SearchBox(input);
            searchBox.addListener('places_changed', () => {
                const places = searchBox.getPlaces();
                if (places.length == 0) {
                    return;
                }
                let firstPlace = places.find((place) => {
                    if (!place.geometry || !place.geometry.location) {
                        return false;
                    }
                    return true
                })
                if(!firstPlace) return

                let location = firstPlace.geometry.location
                console.log('firstPlace', firstPlace);
                console.log('places', places);
                this.center = {lat: location.lat(), lng: location.lng()}
                this.marker = {...this.center}
                this.zoom = 12

                if(firstPlace.address_components) {
                    let addr = firstPlace.address_components
                    let resolvedAddr = this.resolveAddressComponents(addr)
                    console.log('found location', resolvedAddr)
                    this.$emit('onAddressData', resolvedAddr)
                }
                input.value = ''
            });
        }
    },
    computed: {
        apiKey: function() {
            return import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY
        }
    },
    watch: {
        marker: {
            handler: function() {
                this.$emit('update:modelValue', this.marker)
            },
            deep: true
        },
        'modelValue': {
            handler(gps, gpsOld) {
                if (gps?.lat == gpsOld?.lat && gps?.lng == gpsOld?.lng) return
                this.setLocation(gps)
            },
            immediate: true
        },
    },
    methods: {
        resolveAddressComponents: function (addr) {
            console.log('resolveAddressComponents', addr)
            var street = addr.find(i => i.types.includes('route'))?.long_name || null
            var street_number = addr.find(i => i.types.includes('street_number'))?.long_name || null
            var city = addr.find(i => i.types.includes('locality'))?.long_name || null
            if(city==null) {
                city = addr.find(i => i.types.includes('administrative_area_level_3'))?.long_name || null
            }
            var country = addr.find(i => i.types.includes('country'))?.long_name || null
            var postal_code = addr.find(i => i.types.includes('postal_code'))?.long_name || null
            return {
                street,
                street_number,
                city,
                postal_code
            };
        },
        setLocation: function(location) {
            if(!location) return
            this.center = {lat: location.lat, lng: location.lng}
            this.marker = {...this.center}
            this.zoom = 12
        },
        onMapClick: function(event) {
            let lat = event.latLng.lat()
            let lng = event.latLng.lng()
            this.center = {lat, lng}
            this.marker = {...this.center}
            this.zoom = 12


            this.mapsGeocoder.geocode({location: {lat, lng}}).then((response) => {
                if(!response || !response.results.length) return
                let firstPlace = response.results.find((place) => {
                    if(place.types.includes('street_address')) return true
                    return false
                })
                if(!firstPlace || !firstPlace.address_components) return
                let resolvedAddr = this.resolveAddressComponents(firstPlace.address_components)
                console.log('found location', resolvedAddr)
                this.$emit('onAddressData', resolvedAddr)
            })


        },
        onMarkerDrag: function(event) {
            let lat = event.latLng.lat()
            let lng = event.latLng.lng()
            this.marker = {lat, lng}
        }
    },
    data() {
        return {
            mapsGeocoder: null,
            uuid: null,
            center: { lat: 46.500000, lng: 11.350000 }, //bozen
            marker: null,
            zoom: 9 //entire south tyrol
        }
    },
}
</script>