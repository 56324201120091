<template>


    <div v-if="profileRedirectInfo">
        <div class="alert-warning alert" v-html="profileRedirectInfo.message">
        </div>

        <a class="btn btn-primary btn-small mt-2" v-if="profileRedirectInfo.url" :href="profileRedirectInfo.url">{{ profileRedirectInfo.buttonText }}</a>

    </div>

    <Form
        v-else
        ref="form"
        novalidate
        @submit="onSubmit"
        @invalid-submit="onInvalid"
        @change="onChangeFormValues"
        class="form check-required-fields"
        v-slot="{ errors, values, meta }"
    >
        <template v-if="editId">
            <h3 class="tincx-h5 mt-0 mb-0">Anzeige bearbeiten</h3>
        </template>


        <h3 v-else class="tincx-h5 mt-0 mb-0">Allgemeine Informationen</h3>

        <div class="row-small">

            <label class="d-flex align-items-center mt-4">
                <input type="checkbox" name="active" v-model="active" /><span :class="{'text-success' : active, 'text-danger': !active}">{{  active ? 'Anzeige sichtbar' : 'Anzeige nicht sichtbar' }}</span>
                <Field type="hidden" name="active" :modelValue="active" />
            </label>

            <OffersPreview
                v-if="editId && formValues"
                :data="formValues"
                @onPreviewClick="onPreviewClick"
                type="floh"
                class="mt-3"
                />

            <Field type="hidden" name="new" :value="editId ? false : true" />

            <Field type="hidden" name="location" />

            <p class="mt-4 mb-2">
                <strong>Anzeigenart</strong>
            </p>

            <div class="col-12">
                <Field 
                    as="select"
                    name="type"
                    :label="$t('floh.entry.type')"
                    rules="required"
                    :class="{ 'is-invalid': errors.type }"
                >
                    <option value="" disabled>{{ $t('floh.entry.type') }} auswählen</option>
                    <option value="BUY">{{ $t('floh.entry.typeselect.BUY') }}</option>
                    <option value="SALE">{{ $t('floh.entry.typeselect.SALE') }}</option>
                    <option value="CHANGE">{{ $t('floh.entry.typeselect.CHANGE') }}</option>
                </Field>
                <ErrorMessage as="div" name="type" class="invalid-feedback" />
            </div>

            <p class="mt-4 mb-2">
                <strong>Kategorie*</strong>
            </p>

            <div class="col-12" v-if="options.categories">
                <Field 
                    as="select"
                    name="category"
                    :label="$t('floh.entry.category')"
                    rules="required"
                    :class="{ 'is-invalid': errors.category }"
                >
                    
                    <option value="" disabled>{{ $t('floh.entry.category') }} auswählen</option>
                    <optgroup v-for="(mainCategory,k) in options.categories" :key="k" :label="mainCategory.name">
                        <option v-for="category in mainCategory.categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                    </optgroup>
                </Field>
                <ErrorMessage as="div" name="category" class="invalid-feedback" />
            </div>
        </div>
        <hr>
        <p class="mt-2 mb-3">
            <strong>Inhalt der Anzeige</strong>
        </p>

        <Field 
            type="text" 
            name="title" 
            @input="onChangeFormValues"
            :class="{ 'is-invalid': errors.title }"
            :label="$t('validation.attributes.title')"
            placeholder="Titel der Anzeige einfügen*" 
            rules="required"
        />
        <ErrorMessage as="div" name="title" class="invalid-feedback" />

        <Field 
            as="textarea"
            rows="7"
            class="mt-2 mt-sm-3 mt-md-2 mt-lg-3" 
            name="description" 
            :class="{ 'is-invalid': errors.description }"
            :label="$t('validation.attributes.description')"
            placeholder="Beschreibung einfügen*"
            :rules="values.type=='BUY' ? '' : 'required'"
        />
        <ErrorMessage as="div" name="description" class="invalid-feedback" />

        <hr>

        <p class="mt-0 mb-2">
            <strong>Preis-Infos</strong>
        </p>

        <template v-if="values.type=='CHANGE'">
            <p class="text-muted mt-0">
                Bei einem Tausch kann kein Preis angegeben werden.
            </p>
        </template>

        <template v-else>
            <div class="row-small">
                <div class="col-12  col-sm-6 col-md-6 mt-2 align-items-center">
                    <Field 
                        as="select"
                        name="price_detail"
                        :label="$t('floh.entry.priceDetail')"
                        :rules="values.type=='BUY' ? '' : 'required'"
                        :class="{ 'is-invalid': errors.price_detail }"
                    >
                        <option value="" disabled>{{ $t('floh.entry.priceDetail') }} auswählen</option>
                        <option value="EXACT">{{ $t('floh.entry.priceselect.EXACT') }}</option>
                        <option value="NEGOTIABLE">{{ $t('floh.entry.priceselect.NEGOTIABLE') }}</option>
                        <option value="GIVEAWAY">{{ $t('floh.entry.priceselect.GIVEAWAY') }}</option>
                        <option v-if="false && 'todo'" value="PROTECTIONFEE">{{ $t('floh.entry.priceselect.PROTECTIONFEE') }}</option>
                        <option v-if="false && 'todo'" value="EXCHANGE">{{ $t('floh.entry.priceselect.EXCHANGE') }}</option>
                    </Field>
                    <ErrorMessage as="div" name="price_detail" class="invalid-feedback" />
                </div>
                <div class="col-12 col-sm-6 col-md-6 mt-2 position-relative">
                    <template v-if="!['GIVEAWAY'].includes(values.price_detail)">
                        <Field 
                            type="text" 
                            name="price" 
                            @input="onChangeFormValues"
                            :class="{ 'is-invalid': errors.price }"
                            :label="$t('floh.entry.price')"
                            placeholder="Preis einfügen" 
                            :rules="values.type=='BUY' ? 'numeric' : 'required|numeric'"
                        />
                        <span class="input-info">€</span>
                    </template>

                    <ErrorMessage as="div" name="price" class="invalid-feedback" />
                </div>
            </div>
        </template>


        <hr>
        <p class="my-2">
            <strong>Medienverwaltung</strong>
        </p>


        <p class="my-2 tincx-small text-muted">Bilder hochladen</p>
        <Field type="hidden" name="files" v-model="files" />
        <FileUpload 
            ref="files"
            v-model="files"
            @files="onNewFiles"
            accept=".jpg,.png,.jpeg"
            :multiple="true"
            :maxFiles="10"
            :imageIcons="true"
            :imagePreview="true"
        />

        <hr>
        <button 
            :class="{ disabled: Object.keys(errors).length || !meta.valid }"
            type="submit" class="ms-auto d-flex align-items-center justify-content-center mt-4 btn btn-primary btn-large px-3 btn-te" 
        > 
                {{ editId ? 'Anzeige speichern' : 'Anzeige veröffentlichen' }}
                <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                    <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
            C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                    <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
            c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
            c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
                </svg>
        </button>
    </Form>
</template>

<script>
import axios from 'axios'
//import emitter from '../plugins/mitt'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'
import MapForm from "@/components/inc/form/MapForm.vue"
import FileUpload from "@/components/inc/form/FileUpload.vue"
import OffersPreview from '@/components/offers/inc/Preview.vue'
import emitter from '../../../plugins/mitt'

export default {
    name: 'FormFloh',
    components: {
        Form,
        Field,
        ErrorMessage,
        MapForm,
        FileUpload,
        OffersPreview
    },
    inject: ['$t'],
    mounted: async function () {
        let parent = document.getElementById('v-offers-form-floh')
        if (parent && parent.dataset.id) {
            this.editId = parent.dataset.id
        }
        this.loadData()
    },
    computed: {
        offer: function() {
            return {
                ...this.$refs.form.values,
            }
        },
    },
    watch: {

    },
    methods: {
        onPreviewClick: function() {
            if(this.active && this.options && this.options.viewUrl) {
                window.open(this.options.viewUrl, '_blank')
            }
        },
        onNewFiles: function() {
            setTimeout(() => {
                this.onChangeFormValues()
            }, 100)
        },
        onChangeFormValues: function() {
            if(this.$refs.form) {
                console.log('onChangeFormValues FormFloh', this.$refs.form.values)
                this.formValues = {...this.$refs.form.values}
            } else {
                this.formValues = null
            }
            this.$emit('formValues', this.formValues)
        },

        onInvalid: function ({ values, errors, results }) {
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        onSubmit: function (values) {
            Swal.fire({
                title: this.$t('popup.register.title'),
                text: this.$t('generic.loading'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            const data = {
                ...this.$refs.form.values
            }
            axios
                .post(`/api/profile/offers/${this.type}/create${this.editId ? '/'+this.editId : ''}`, data)
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    if(this.editId) {
                        Swal.fire({
                            icon: 'success',
                            text: $t('generic.save_alert'),
                            didClose: () => window.scrollTo(0,0)
                        })
                        this.loadData();
                        emitter.emit('profile_reload_offer_list', true)
                        return;
                    }

                    Swal.close()
                    this.$emit('success', response.data)
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
        loadData: function () {
            if (this.loading) return
            this.loading = true
            var editUrlPostfix = ''
            if(this.editId) {
                editUrlPostfix = '/'+this.editId
            }
            axios
                .get(`/api/profile/offers/${this.type}/create${editUrlPostfix}`)
                .then(async (response) => {
                    this.loading = false

                    this.options = {...response.data.options}
                    this.profileRedirectInfo = response.data.profileRedirectInfo
                    let formData = response.data.formData
                    this.active = response.data.formData.active

                    setTimeout(() => {
                        if (this.$refs && this.$refs.form) {
                            this.$refs.form.resetForm({
                                values: formData
                            })

                            this.onChangeFormValues()
                        }
                    }, 100)
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
    },
    data() {
        return {
            editId: null,
            active: true,
            type: 'floh',
            profileRedirectInfo: null,
            success: false,
            files: [],
            options: {},
            formValues: null,
        }
    },
}
</script>