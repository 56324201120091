<template>
    <div id="rating-box-a">
        <div v-if="pagetype === 'berater' || showAllSteps" class="steps-box" :data-steps="preferences.length">
            <p>
                <strong
                    ><span class="counter">{{ answeredSteps }}</span> / {{ preferences.length }}</strong
                >
                {{ $t('fe.rating.answered') }}
            </p>
            <span class="steps d-block bg-light">
                <span class="active-step d-block bg-primary" :style="{ width: `${(100 / preferences.length) * answeredSteps}%` }"></span>
            </span>
            <!--            <p class="mt-2 text-small text-right"><strong>92 Jobs</strong> gefunden</p>-->
        </div>

        <!-- Titel und Text -->
        <h4 class="mb-3">{{ title }}</h4>
        <p>{{ text }}</p>

        <p v-if="text2">{{ text2 }}</p>

        <!-- Fortschrittsbalken für nicht-Berater-Seite -->
        <div v-if="pagetype !== 'berater' && !showAllSteps" class="steps-box" :data-steps="preferences.length">
            <p>
                {{ $t('fe.rating.step') }}
                <strong
                    ><span class="counter">{{ currentStep }}</span> {{ $t('fe.rating.from') }} {{ preferences.length }}</strong
                >
            </p>
            <span class="steps d-block bg-light">
                <span class="active-step d-block bg-primary" :style="{ width: `${(100 / preferences.length) * currentStep}%` }"></span>
            </span>
        </div>

        <!-- Bewertungs-Box -->
        <div class="rating-box steps-content" :class="{'show-all-steps': showAllSteps}">
            <div
                v-for="(preference, index) in preferences"
                :key="index"
                :class="{
                    active: currentStep === index + 1 || showAllSteps,
                    next: currentStep < index + 1 && !showAllSteps,
                    prev: currentStep > index + 1 && !showAllSteps,
                }"
                :data-step-box="index + 1"
                class="step-box"
                :style="showAllSteps ? '' : 'position: absolute; width: 100%'"
            >
                <p class="mt-4">
                    <strong>{{ preference.title }}{{ requiredText }}</strong>
                </p>
                <div class="rating d-lg-flex justify-content-between" v-if="company == false">

                    <div
                        v-for="(value, key) in ratingSettings"
                        :key="key"
                        class="position-relative d-flex align-items-center d-lg-block mb-2 mb-lg-0"
                    >
                        <input
                            type="radio"
                            :id="`${preference.name}${key}`"
                            :name="preference.name"
                            :value="key"
                            @click="onClickOnPreference(preference, key)"
                            v-model="selectedUserRatings[preference.name]"
                            required
                        />
                        <span class="rating-number mb-lg-2">{{ key }}</span>
                        <label :for="`${preference.name}${key}`" :title="value" v-html="value"> </label>
                    </div>
                </div>
                <div class="rating d-lg-flex justify-content-between" v-else>

                    <div
                        v-for="(value, key) in ratingSettingsCompany[index+1]"
                        :key="key"
                        class="position-relative d-flex align-items-center d-lg-block mb-2 mb-lg-0"
                    >

                        <input
                            type="radio"
                            :id="`${preference.name}${key}`"
                            :name="preference.name"
                            :value="key"
                            @click="onClickOnPreference(preference, key)"
                            v-model="selectedUserRatings[preference.name]"
                            required
                        />
                        <span class="rating-number mb-lg-2">{{ key }}</span>
                        <label :for="`${preference.name}${key}`" :title="value[key]" v-html="value"> </label>
                    </div>
                </div>

                <!-- Buttons für Navigation -->
                <div class="text-right mt-4" v-if="!showAllSteps">
                    <a v-if="index > 0" href="#" class="btn btn-white btn-bs me-2" @click.prevent="previousStep">{{
                        $t('fe.rating.prev')
                    }}</a>
                    <a
                        v-if="index < preferences.length - 1"
                        href="#"
                        class="btn btn-primary btn-bs show-badge"
                        :data-badge="!selectedUserRatings[preference.name] ? 'Bitte wähle eine Präferenz' : null"
                        :class="{ disabled: !selectedUserRatings[preference.name] }"
                        @click.prevent="nextStep(preference)"
                    >
                        {{ $t('fe.rating.next') }}
                    </a>

                    <a
                        v-if="index == preferences.length - 1 && showSearch"
                        href="#"
                        class="btn btn-primary btn-bs show-badge"
                        :data-badge="!selectedUserRatings[preference.name] ? 'Bitte wähle eine Präferenz' : null"
                        :class="{ disabled: !selectedUserRatings[preference.name] }"
                        @click.prevent="$emit('onSearch')"
                    >
                        {{ $t('fe.rating.search') }}
                    </a>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Rating',
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        text2: {
            type: String,
            required: false,
        },
        showAllSteps: {
            type: Boolean,
            default: false,
        },
        preferences: {
            type: Array,
            required: true,
            default: () => [],
        },
        pagetype: {
            type: String,
            default: '',
        },
        requiredInfo: {
            type: Boolean,
            default: true,
        },
        showSearch: {
            type: Boolean,
            default: false
        },

        modelValue: {
            type: Object,
            default: {}
        },
        company: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            selectedUserRatings: {},
            currentStep: 1,
        }
    },
    computed: {
        requiredText: function() {
            return this.requiredInfo ? '*' : ''
        },
        answeredSteps: function() {
            var count = 0
            for(var key in this.selectedUserRatings) {
                var u = this.selectedUserRatings[key]
                if(u) {
                    count++
                }
            }
            return count
        },
        ratingSettings: function() {
            return {
                1: this.$t('dm.preferences.1').replace(' ','<br>'),
                2: this.$t('dm.preferences.2').replace(' ','<br>'),
                3: this.$t('dm.preferences.3').replace(' ','<br>'),
                4: this.$t('dm.preferences.4').replace(' ','<br>'),
                5: this.$t('dm.preferences.5').replace(' ','<br>'),
            }

        },
        ratingSettingsCompany: function(){
            return {
                    1 : {
                        1: this.$t('dm.preferences_company.1.1').replace(' ','<br>'),
                        2: this.$t('dm.preferences_company.1.2').replace(' ','<br>'),
                        3: this.$t('dm.preferences_company.1.3').replace(' ','<br>'),
                        4: this.$t('dm.preferences_company.1.4').replace(' ','<br>'),
                        5: this.$t('dm.preferences_company.1.5').replace(' ','<br>'),
                        },
                    2 : {
                        1: this.$t('dm.preferences_company.2.1').replace(' ','<br>'),
                        2: this.$t('dm.preferences_company.2.2').replace(' ','<br>'),
                        3: this.$t('dm.preferences_company.2.3').replace(' ','<br>'),
                        4: this.$t('dm.preferences_company.2.4').replace(' ','<br>'),
                        5: this.$t('dm.preferences_company.2.5').replace(' ','<br>'),
                        },
                    3 : {
                        1: this.$t('dm.preferences_company.3.1').replace(' ','<br>'),
                        2: this.$t('dm.preferences_company.3.2').replace(' ','<br>'),
                        3: this.$t('dm.preferences_company.3.3').replace(' ','<br>'),
                        4: this.$t('dm.preferences_company.3.4').replace(' ','<br>'),
                        5: this.$t('dm.preferences_company.3.5').replace(' ','<br>'),
                    },
                    4 : {
                        1: this.$t('dm.preferences_company.4.1').replace(' ','<br>'),
                        2: this.$t('dm.preferences_company.4.2').replace(' ','<br>'),
                        3: this.$t('dm.preferences_company.4.3').replace(' ','<br>'),
                        4: this.$t('dm.preferences_company.4.4').replace(' ','<br>'),
                        5: this.$t('dm.preferences_company.4.5').replace(' ','<br>'),
                    }
            }
        },
    },
    watch: {
        selectedUserRatings: {
            handler: function(val) {
                this.$emit('update:modelValue', val)


                if(Object.keys(val).length==4) {
                   var run = true
                   Object.keys(val).forEach(k => {
                       if(!val[k]) {
                            run = false
                       }
                   })
                }
                if(run) {
                    localStorage.setItem("dm_preferences", JSON.stringify(val));
                }
            },
            deep: true
        },
        modelValue: {
            handler: function(val) {
                this.selectedUserRatings = this.modelValue
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        setStep(step) {
            this.currentStep = step
        },
        onClickOnPreference(preference, key) {
            var current = this.selectedUserRatings[preference.name]
            console.log('onClickOnPreference', {preference, key, current})
            if(current == key) {
                if(this.showAllSteps) {
                    delete this.selectedUserRatings[preference.name]
                } else {
                    //if we have a step by step then not do anything here
                }
            } else {
                setTimeout(() => {
                    if(this.currentStep == this.preferences.length) {
                        this.$emit('onFinishRating', true)
                        return;
                    }
                    this.nextStep(preference)
                }, 500);
            }
        },
        nextStep(preference) {
            if(!this.selectedUserRatings[preference.name]) return
            if (this.currentStep < this.preferences.length) {
                this.currentStep++
            }
        },
        previousStep() {
            if (this.currentStep > 1) {
                this.currentStep--
            }
        },
    },
}
</script>
