<template>
    <!-- liste -->
    <section class="asymetric-columns block-liste text-box pt-small pb-large" id="content">
        <div class="container px-0 px-md-3">
            <div class="px-3 px-md-0 block-title-filter d-md-block" :class="{'d-none': detailId}">


                <div class="d-md-block dashboard-headline dashboard">
                    <div class="mb-3 d-md-flex align-items-center justify-content-between">
                        <div>
                            <div class="px-0 mb-2">
                                <div class="breadcrumbs tincx-small text-muted text-ellipsis">
                                    <a class="text-muted text-no-underline" :href="$t('fe.job_candidates_search.url')">{{ $t('fe.job_candidates_search.breadcrumb_title') }}</a>
                                    <span class="px-1 px-xs-2">/</span>
                                    <span class="text-ellipsis">{{ $t('generic.search_results') }}</span>
                                </div>
                            </div>
                            <h2 class="my-0 tincx-h4">
                                Alle verfügbaren Kandidat:innen<br>
                                <span v-if="candidates && candidates.length==0" class="text-normal">Keine Personen gefunden</span>
                                <span v-else class="text-normal">{{ $t('fe.job_candidates_search.search_results_results', {count: this.count ? $f.number(this.count) : '...' }) }}</span>
                                <span class="loader loader-sm ms-2" v-if="loading"></span>

                            </h2>
                        </div>


                        <div class="mt-3 mt-md-0 actions d-flex justify-content-between ms-md-3">
                            <a href="/profil/job" @click.prevent="jobList=true" data-tooltip="Resultate basierend auf deiner Job-Anzeige optimieren" class="px-3 bg-auto text-no-underline text-nowrap">
                                <span v-if="jobReference">Job-Filter: J-{{ jobReference }}</span>
                                <span v-else>Job-Filter</span>
                            </a>
                            <div class="d-flex ms-0 ms-xs-3">
                                <a href="/profil/job/chat" data-tooltip="Chat" class="ms-1 bg-white position-relative "><img src="/images/icons/icon-message.svg" alt="Nachrichten">
                                    <span class="badge-counter d-flex align-items-center justify-content-center position-absolute" v-if="unreadCount>0">{{ unreadCount }}</span>
                                </a>
                                <a href="/profil" data-tooltip="Profil" class="ms-1 bg-white"><img src="/images/icons/icon-user.svg" alt="Profil"></a>
                            </div>
                        </div>
                    </div>
                </div>

                <a href="#" class="mobile-filter with-map btn btn-white px-5 mb-3 d-inline-flex justify-content-center align-items-center d-lg-none"><img class="me-1" src="/images/icons/icon-filter.svg">Filter anzeigen</a>

                <div class="filter-bar mt-0 d-flex align-items-center justify-content-between">
                    <div class="d-block d-lg-flex flex-wrap filter-desktop">
                        <div class="d-flex d-lg-none align-items-center justify-content-between">
                            <p class="my-0"><strong>{{ $t('generic.filter') }}:</strong></p>

                            <a href="#" class="close-filter p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                    <path
                                        d="M7.2,6L11.7,1.5c.3-.3.3-.9,0-1.2-.3-.3-.9-.3-1.2,0l-4.5,4.5L1.5.3C1.2,0,.6,0,.3.3,0,.6,0,1.2.3,1.5l4.5,4.5L.3,10.5c-.3.3-.3.9,0,1.2.2.2.4.2.6.2s.4,0,.6-.2l4.5-4.5,4.5,4.5c.2.2.4.2.6.2s.4,0,1.6-.2c.3-.3.3-.9,0-1.2l-4.5-4.5Z"
                                    />
                                </svg>
                            </a>
                        </div>



                        <MultiSelect :multiple="true" :items="categories" :title="$t('fe.generic.job_category')" v-model="filter.category" />
                        <MultiSelect  :multiple="true" :items="districts" :title="$t('fe.generic.district')" v-model="filter.district" />
                        <MultiSelect :multiple="true" :items="employmentTypes" :title="$t('fe.generic.employment_types')" v-model="filter.employmentType" />
                        <MultiSelect :multiple="true" :items="languages" :title="$t('fe.generic.language_skills')" v-model="filter.language" />
                        <MultiSelect :multiple="true" :items="educations" :title="$t('fe.generic.education')" v-model="filter.education" />

                        <a href="#"
                                @click.prevent="ratingBox=true"
                                :data-tooltip="preferencesSet ? 'Präferenzen ändern' : 'Präferenzen definieren'"
                                :class="{'preferences-active': Object.keys(selectedUserRatings).length,'preferences-incomplete': !preferencesSet}"
                                class="more-filter select-container more-filter-jq-disable bg-white align-items-center justify-content-center text-no-underline d-flex mt-2 mt-lg-0">
                                <img class="" src="/images/icons/icon-filter.svg">
                            </a>


                        <div class="text-center pt-3 mt-0 d-lg-none">
                            <a href="#" class="btn btn-primary btn-large btn-te px-3 search-button">
                                {{ $t('generic.show_results',{count: count ? $f.number(count) : 0}) }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <template v-if="!loading">
                <div class="alert alert-warning mt-3 mb-0" v-if="!preferencesSet">
                    <b>! Präferenzen</b><br><br>
                    <template v-if="jobReference">
                        In Ihrer Jobanzeige haben Sie noch nicht alle Präferenzen definiert.<br>
                        Definieren Sie die Präferenzen um die Relevanz der Suchergebnisse zu erhöhen.<br><br>
                        <a
                            @click.prevent="ratingBox=true"
                            target="_blank"
                            class="btn btn-primary"
                        >
                            Präferenzen im JOB definieren
                        </a>
                    </template>
                    <template v-else>
                        In Ihren Firmenprofil haben Sie noch nicht alle Präferenzen definiert.<br>
                        Definieren Sie die Präferenzen für Ihr Firmenprofil um die Relevanz der Suchergebnisse zu erhöhen.<br><br>

                        <a
                            href="#"
                            @click.prevent="ratingBox=true"
                            class="btn btn-primary">
                            Präferenzen im Firmenprofil definieren
                        </a>
                    </template>
                </div>
                <div v-else class="alert alert-info  mt-3 mb-0">

                    <template v-if="jobReference">
                        Hier finden Sie, die <b>relevantesten</b> Kandidat:innen, basierend auf den Präferenzen Ihrer <b>Jobanzeige</b>.<br><br>
                        <ul class="my-0">
                            <li class="mb-2"><a @click.prevent="ratingBox=true" href="/profile/jobs">Präferenzen im JOB aktualisieren</a></li>
                        </ul>
                    </template>
                    <template v-else>
                        Hier finden Sie, die <b>relevantesten</b> Kandidat:innen, basierend auf den Präferenzen Ihres <b>Unternehmensprofils</b>.<br><br>
                        <ul class="my-0">
                            <li class="mb-2"><a @click.prevent="ratingBox=true" href="#">Präferenzen im Firmenprofil aktualisieren</a></li>
                            <li class="mb-2"><a @click.prevent="jobList=true" href="#">Ergebnisse mit JOB-Anzeige optimieren</a></li>
                        </ul>
                    </template>

                    <template v-if="categoryPresetDone">
                        <br>
                        Die "Branche" haben wir bereits auf Basis Ihrer Einstellungen im Firmenprofil voreingestellt.<br>
                        Gerne können Sie den Filter löschen oder ergänzen.
                    </template>
                </div>
            </template>

            <div class="row-large mx-3 mx-sm-0 pt-1 pt-md-5">
                <div class="sidebar pt-0 px-0 px-xs-3 ps-md-0  d-md-flex" :class="{'d-none': detailId}">
                    <a href="#" @click.prevent="resetFilter" class="text-no-underline mt-4 text-small text-left d-block text-normal mb-3" v-if="hasFilters">
                        <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15" fill="#5f6368"><path d="m592-481-57-57 143-182H353l-80-80h487q25 0 36 22t-4 42L592-481ZM791-56 560-287v87q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-247L56-791l56-57 736 736-57 56ZM535-538Z"/></svg>
                        {{ $t('fe.reset_search') }}
                    </a>

                    <div class="d-flex mb-2 text-right" style="margin-left: auto">
                        <select v-model="filter.sort" class="wemo-select wemo-select-small" style="margin-left: auto">
                            <option value="relevance">{{ $t('generic.sort_relevance')}}</option>
                            <option value="new">{{ $t('generic.sort_newest') }}</option>
                            <option value="old">{{ $t('generic.sort_oldest') }}</option>
                        </select>
                    </div>

                    <div class="d-flex flex-wrap w-100">
                        <div v-if="!loading && candidates && candidates.length==0">
                            {{ $t('fe.generic.no_items_found')}}
                        </div>

                        <template v-for="(candidate, index) in candidates">
                            <div
                                :data-id="candidate.id"
                                @click="selectItem(candidate)"
                                :class="{ active: detailId == candidate.id, first: index == 0, 'mt-3': index != 0 }"
                                class="card position-relative d-flex align-items-center py-3"
                            >
                                <div class="offer-box w-100">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="tincx-h5 col">
                                            <div class="d-flex align-items-center">
                                            {{ candidate.name }} <span v-if="candidate.verified" class="ms-1" data-tooltip="Verifiziert"><img width="23" height="23" src="/images/icons/icon-verified.svg" alt="Icon Verifiziert"></span>
                                            </div>
                                            <span class="text-muted">{{ candidate.title }}</span>
                                        </span>

                                        <span class="type-badge d-flex align-items-center" v-if="!candidate.public">
                                            <img src="/images/icons/icon-anonym.svg" alt="Anonymes Profil" class="me-1">Anonym
                                        </span>
                                        <span class="type-badge d-flex align-items-center" v-else>
                                            <img src="/images/icons/icon-public.svg" alt="Öffentliches Profil" class="me-1">Öffentlich
                                        </span>
                                    </div>
                                    <div class="d-flex justify-content-between my-2" v-if="candidate.types.length">
                                        <span class="d-flex align-items-center">
                                            <img width="14.23" height="14.23" src="/images/icons/icon-time.svg" alt="Job Art" class="me-1 d-block">
                                            <span>{{ candidate.types.join(', ') }}</span>
                                        </span>
                                    </div>
                                    <div class="steps-box mt-3" v-if="candidate.percent">
                                        <span class="steps d-block bg-light">
                                            <span class="active-step d-block bg-auto" :style="{width: `${candidate.percent}%`}"></span>
                                        </span>
                                        <small class="text-right d-block mt-1">
                                            <strong>{{ candidate.percent }}%</strong>
                                            Übereinstimmung
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </template>


                        <div class="loader-container" v-if="loading">
                            <span class="loader" ></span>
                        </div>

                        <div class="position-relative text-center" style="order: 3;padding-top: 15px;margin: auto" v-if="candidates && candidates.length && !loading && !hideLoadMore">
                            <a @click="loadMore" class="btn btn-bs btn-white" :class="{disabled: loading}">{{ loading ? $t('generic.loading') : $t('generic.load_more') }}</a>
                        </div>

                        <div class="placeholder-item"></div>
                    </div>


                    <div class="bg-job text-white card " @click.prevent="ratingBox=true">
                            <p class="mt-0"><strong>Nichts passendes dabei?</strong></p>
                            <p>Ändern Sie Ihre Präferenzen, um mehr potenzielle Kandidat:innen für Ihre Stellenanzeige
                                zu finden.</p>
                            <a href="#" @click.prevent="ratingBox=true" class="btn-transparent">Präferenzen anpassen
                                <svg class="ms-1" width="15.6" height="15.6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.6 15.6">
                                    <path fill="#fff" d="M7.8,0C3.5,0,0,3.5,0,7.8s3.5,7.8,7.8,7.8,7.8-3.5,7.8-7.8S12.1,0,7.8,0ZM7.8,14.3c-3.6,0-6.5-2.9-6.5-6.5S4.2,1.3,7.8,1.3c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5Z"></path>
                                    <path fill="#fff" d="M11.4,7.3s0,0,0,0l-2.3-2.3c-.3-.3-.7-.3-.9,0s-.3.7,0,.9l1.3,1.3h-4.8c-.4,0-.6.3-.6.6s.3.6.6.6h4.8l-1.3,1.3c-.3.3-.3.7,0,.9s.3.2.5.2.3,0,.5-.2l2.3-2.3s0,0,0,0c.1-.1.2-.3.2-.5s0-.4-.2-.5Z"></path>
                                </svg>
                            </a>

                        </div>


                </div>

                <div class="mainbar pe-0 d-md-block" :class="{'d-none': !detailId}">
                    <!-- main-job start -->

                    <div class="scroll-box" :class="{'d-none': !detailId}" id="scroll-box">
                        <div class="large-card" ref="largeCard">
                            <div class="row-large">
                                <a
                                    @click.prevent="selectItem(null)"
                                    href="#"
                                    class="d-flex align-items-center tincx-p text-muted mb-3 mt-0 d-md-none close-detail"
                                >
                                    <svg class="me-1" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                                        <path
                                            fill="currentcolor"
                                            d="M7.75,15.5c4.27,0,7.75-3.48,7.75-7.75S12.02,0,7.75,0,0,3.48,0,7.75s3.48,7.75,7.75,7.75ZM7.75,1.29c3.58,0,6.46,2.88,6.46,6.46s-2.88,6.46-6.46,6.46S1.29,11.33,1.29,7.75,4.17,1.29,7.75,1.29Z"
                                        ></path>
                                        <path
                                            fill="currentcolor"
                                            d="M6.46,10.53c.3.3.7.3.89,0s.3-.7,0-.89l-1.29-1.29h4.77c.4,0,.6-.3.6-.6s-.3-.6-.6-.6h-4.77l1.29-1.29c.3-.3.3-.7,0-.89s-.3-.2-.5-.2-.3,0-.5.2l-2.29,2.29h0c-.1.1-.2.3-.2.5s2.58,2.78,2.58,2.78Z"
                                        ></path>
                                    </svg>
                                    {{ $t('generic.back_to_search') }}
                                </a>

                                <!-- start title mobile -->
                                <div id="title-mobile" class="d-lg-none" v-if="selectedItem">
                                    <JobCandidateContent :selectedItem="selectedItem" />
                                </div>
                                <!-- end title mobile -->

                                <div class="col-12 col-lg" v-if="selectedItem">
                                    <div id="title-desktop" class="d-none d-lg-block">
                                        <JobCandidateContent :selectedItem="selectedItem" />
                                    </div>

                                    <div id="main">
                                        <hr>
                                        <p>
                                            <strong>Unter welchen Bedingungen dürfen Unternehmen dich kontaktieren?</strong><br>
                                            <span>{{ selectedItem.contact_conditions ? selectedItem.contact_conditions : 'keine Angabe'}}</span>
                                        </p>

                                        <p>
                                            <strong>Womit zeichnest du dich aus?</strong><br>
                                            <span>{{ selectedItem.skills ? selectedItem.skills : 'keine Angabe'}}</span>
                                        </p>

                                        <hr>

                                        <p class="my-2"><strong>Absolvierte Ausbildung:</strong> <span class="">{{  selectedItem.education ? $t(`jobs.education.${selectedItem.education}`) : 'keine Angabe' }}</span></p>

                                        <p class="my-2 response-arbeitszeitmodell-container" v-if="selectedItem.salary_min_value || selectedItem.salary_min_value">
                                            <strong>Gehaltsvorstellung netto (monatlich):</strong> <span>{{ selectedItem.salary_min_value ? $f.number(selectedItem.salary_min_value) : '?' }} - {{ selectedItem.salary_max_value ? $f.number(selectedItem.salary_max_value) : '?' }} €</span>
                                        </p>
                                        <p class="my-2 response-arbeitszeitmodell-container" v-else>
                                            <strong>Gehaltsvorstellung netto (monatlich):</strong> <span>keine Angabe</span>
                                        </p>


                                        <p class="my-2">
                                            <strong>Arbeitszeitmodell:</strong> <span>{{ selectedItem.types.length ? selectedItem.types.join(', ') : 'keine Angabe' }}</span>
                                        </p>

                                        <p class="my-2">
                                            <strong>Home-Office-Optionen:</strong> <span>{{ selectedItem.home_office.length ? selectedItem.home_office.join(', ') : 'keine Angabe' }}</span>
                                        </p>

                                        <p class="my-2">
                                            <strong>Bevorzugte Branchen:</strong> <span>{{ selectedItem.categories.length ? selectedItem.categories.join(', ') : 'keine Angabe' }}</span>
                                        </p>

                                        <p class="my-2"><strong>Sprachkenntnisse:</strong> <span class="">{{ selectedItem.languages.length ? selectedItem.languages.join(', ') : 'keine Angabe' }}</span>
                                        </p>

                                        <hr>

                                        <p class="my-2"><strong>Aktivierung Profil:</strong> <span class="">{{ $displayDate(selectedItem.active_at) }}</span>
                                        </p>

                                        <p v-if="selectedItem.active_at != selectedItem.updated_at" class="my-2"><strong>Letzte Aktualisierung:</strong> <span class="">{{ $displayDate(selectedItem.updated_at) }}</span>
                                        </p>

                                        <hr>


                                        <div class="">
                                            <a
                                                :href="selectedItem.button.url"
                                                class="d-flex align-items-center justify-content-center mt-2 btn btn-primary btn-large btn-bs hide-contact bg-primary">
                                                <svg class="me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M10.4,20.8c5.7,0,10.4-4.7,10.4-10.4S16.2,0,10.4,0S0,4.7,0,10.4v0C0,16.2,4.7,20.8,10.4,20.8z M10.4,2
                                            c4.6,0,8.4,3.8,8.4,8.4c0,4.6-3.8,8.4-8.4,8.4S2,15,2,10.4C2,5.8,5.8,2,10.4,2z"
                                                    />
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M5.6,11.2C5.6,11.2,5.6,11.2,5.6,11.2l3.1,3.1c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-1.4-1.4h5.9
                                            c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6L10.1,8c0.4-0.4,0.4-1,0-1.4C9.9,6.4,9.6,6.3,9.4,6.3S8.9,6.4,8.7,6.6l-3,3c0,0,0,0,0,0.1
                                            c-0.2,0.2-0.3,0.4-0.3,0.7C5.2,10.7,5.4,11,5.6,11.2z"
                                                    />
                                                </svg>
                                                {{ selectedItem.button.title }}
                                            </a>
                                        </div>
                                        <hr>

                                        <div class="d-none d-sm-block">

                                            <h3 class="tincx-h5 mt-0 mb-2">Präferenzen im Job</h3>

                                            <p class="mb-5">Die Punkte spiegeln wider, wie wichtig dem Bewerber die jeweilige Präferenz ist.</p>

                                            <BarChart v-if="true" :chartData="{
                                                labels: ['Gehalt', 'Karrierechancen', 'Flexible Arbeitszeiten', 'Wohnort'],
                                                datasets: [
                                                    {
                                                    //barThickness: 30,
                                                    label: 'Kandidat',
                                                    data: [selectedItem.selectedUserRatings.money, selectedItem.selectedUserRatings.career, selectedItem.selectedUserRatings.homeoffice, selectedItem.selectedUserRatings.location],
                                                    backgroundColor: '#709DC6',
                                                    },
                                                    {
                                                    //barThickness: 30,
                                                    label:jobReference ? 'Job' : 'Firma',
                                                    data: [selectedUserRatings.money, selectedUserRatings.career, selectedUserRatings.homeoffice, selectedUserRatings.location],
                                                    backgroundColor: '#AFCA05',
                                                    }
                                                ],
                                                }"
                                                :options="{
                                                    scales: {
                                                        y: {
                                                            min: 0,
                                                            max: 5,
                                                            ticks: {
                                                                stepSize: 1,
                                                                autoSkip: false
                                                            },
                                                        }
                                                    }
                                                }"
                                            />


                                            <div v-else class="d-flex align-items-end justify-content-between statistics-container position-relative">
                                                <div class="position-absolute statistics-lines d-flex align-items-center">
                                                    <span class="percentage">100%</span><span class="line"></span>
                                                </div>
                                                <div class="position-absolute statistics-lines d-flex align-items-center">
                                                    <span class="percentage">75%</span><span class="line"></span>
                                                </div>
                                                <div class="position-absolute statistics-lines d-flex align-items-center">
                                                    <span class="percentage">50%</span><span class="line"></span>
                                                </div>
                                                <div class="position-absolute statistics-lines d-flex align-items-center">
                                                    <span class="percentage">25%</span><span class="line"></span>
                                                </div>
                                                <div class="position-absolute statistics-lines d-flex align-items-center">
                                                    <span class="percentage">0%</span><span class="line"></span>
                                                </div>
                                                <div class="text-center statistics">
                                                    <div class="statistics-box position-relative">
                                                        <div class="box position-absolute bg-job" :style="{height: `${selectedItem.selectedUserRatings.money ? Math.ceil(100*selectedItem.selectedUserRatings.money/5) : 0}%` }"></div>
                                                    </div>
                                                    <small class="d-block text-bold mt-2">Gehalt</small>
                                                </div>
                                                <div class="text-center statistics">
                                                    <div class="statistics-box position-relative">
                                                        <div class="box position-absolute bg-job" :style="{height: `${selectedItem.selectedUserRatings.money ? Math.ceil(100*selectedItem.selectedUserRatings.career/5) : 0}%` }"></div>
                                                    </div>
                                                    <small class="d-block text-bold mt-2">Karrierechancen</small>
                                                </div>
                                                <div class="text-center statistics">
                                                    <div class="statistics-box position-relative">
                                                        <div class="box position-absolute bg-job" :style="{height: `${selectedItem.selectedUserRatings.money ? Math.ceil(100*selectedItem.selectedUserRatings.homeoffice/5) : 0}%` }"></div>
                                                    </div>
                                                    <small class="d-block text-bold mt-2">Flexible Arbeitszeiten</small>
                                                </div>
                                                <div class="text-center statistics">
                                                    <div class="statistics-box position-relative">
                                                        <div class="box position-absolute bg-job" :style="{height: `${selectedItem.selectedUserRatings.money ? Math.ceil(100*selectedItem.selectedUserRatings.location/5) : 0}%` }"></div>
                                                    </div>
                                                    <small class="d-block text-bold mt-2">Wohnort</small>
                                                </div>
                                            </div>
                                        </div>

                                        <template v-if="selectedItem.percent">
                                            <hr>
                                            <h3 class="tincx-h5 mt-0 mb-3">Übereinstimmung mit Ihren Präferenzen</h3>
                                            <div class="steps-box mb-4">
                                                <span class="steps d-block bg-light">
                                                <span class="main-step active-step d-block bg-auto bg-auto" :style="{width: `${selectedItem.percent}%`}"></span>
                                                <small class="main-step-info step-info position-absolute mt-3 text-bold" :style="{'margin-left': `${selectedItem.percent}%`}">{{ selectedItem.percent }}%</small>
                                                </span>
                                            </div>
                                        </template>

                                        <br>
                                        <hr class="mt-3">


                                    </div>
                                </div>

                                <div class="infos d-none d-lg-block" id="info-desktop" v-if="selectedItem && false">
                                    <JobCandidateInfo :selectedItem="selectedItem" />
                                </div>

                                <div id="info-mobile" class="d-lg-none position-relative mt-3" v-if="selectedItem && false">
                                    <JobCandidateInfo :selectedItem="selectedItem" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- main-job end -->
                </div>
            </div>
        </div>


        <div id="job_list" class="popup-container d-flex align-items-center justify-content-center text-box" :class="{'d-none': !jobList}">
            <div class="popup-box popup-list px-3 px-sm-4 px-lg-5 pt-5 pb-4 position-relative" style="background: #f2f2f2 !important">

                <a
                    href="#"
                    @click.prevent="jobList=false"
                    class="close-popup position-absolute">
                    <img src="/images/icons/icon-close-popup.svg"
                    >
                </a>

                <div class="mt-4 mb-3 alert alert-info">
                    Wählen Sie hier eine Ihrer Jobanzeige aus.<br>
                    Wir suchen dann aufgrund der Präferenzen Ihrer Stellenanzeige die besten Kandidat:innen.
                </div>

                <div class="mt-2" v-if="jobReference">
                    <a href="/job/candidates" class="text-no-underline mt-4 text-small text-left d-block text-normal mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15" fill="#5f6368"><path d="m592-481-57-57 143-182H353l-80-80h487q25 0 36 22t-4 42L592-481ZM791-56 560-287v87q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-247L56-791l56-57 736 736-57 56ZM535-538Z"/></svg>
                        JOB-Filter löschen
                    </a>
                </div>

                <div class="mt-2">
                    <ProfileOfferList
                        :onlyActive="true"
                        :minimal="true"
                        @select="onReferenceJobSelect"
                        :eventOnClick="true"
                        pType="job"
                    />
                </div>


            </div>
        </div>

        <div id="rating_box" class="popup-container d-flex align-items-center justify-content-center text-box" :class="{'d-none': !ratingBox}">
            <div class="popup-box bg-white px-3 px-sm-4 px-lg-5 pt-5 pb-4 position-relative">

                <a
                    href="#"
                    @click.prevent="ratingBox=false"
                    class="close-popup position-absolute">
                    <img src="/images/icons/icon-close-popup.svg"
                    >
                </a>

                <Rating
                    ref="ratingItem"
                    :title="preferences.title"
                    :text="preferences.text"
                    :preferences="preferences.list"
                    @onSearch="onPreferencesSearch"
                    pagetype="list"
                    :showSearch="true"
                    :showAllSteps="false"
                    v-model="selectedUserRatings"
                />

            </div>
        </div>


    </section>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Slick from './inc/Slick.vue'
import emitter from '../plugins/mitt';
import JobCandidateInfo from './inc/JobCandidateInfo.vue'
import JobCandidateContent from './inc/JobCandidateContent.vue'
import ContactButton from './inc/ContactButton.vue'
import MultiSelect from './inc/MultiSelect.vue';
import Rating from './inc/form/Rating.vue'
import ProfileOfferList from './ProfileOfferList.vue'
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    name: 'JobCandidatesList',
    components: {
        Slick,
        BarChart,
        ProfileOfferList,
        JobCandidateInfo,
        JobCandidateContent,
        ContactButton,
        MultiSelect,
        Rating
    },
    data() {
        return {
            unreadCount: 0,
            loading: false,
            ratingBox: false,
            jobList: false,
            hideLoadMore: false,
            detailId: null,
            landingPage: {
                detailId: null,
                category: null
            },
            showContactButtons: false,
            activeTab: 'main',
            fetchStep: 20,

            selectedUserRatings: {},
            initialLoadDone: false,
            categoryPresetDone: false,
            filter: {
                category: [],
                district: [],
                language: [],
                education: [],
                employmentType: [],
                benefit: [],
                search: null,
                sort: 'relevance',
            },
            candidates: null,
            count: null,

            categories: [],
            educations: [],
            employmentTypes: [],
            languages: [],
            districts: [],

            jobReference: null,


            slickOptions: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 639,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        },
                    }
                ],
            }
        }
    },
    inject: ['$t', '$rtime', '$displayDate', '$f'],
    computed: {
        preferences() {
            //todo: copied from JobAdvisor.vue, we need to refactor this ones
            return {
                title: this.$t('fe.company_profile.preferences.title'),
                text: this.$t('fe.company_profile.preferences.text'),
                text2: this.$t('fe.company_profile.preferences.text2'),
                list: [
                    { title: this.$t('fe.job_advisor.preferences.list_company.0.title'), name: 'money' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.1.title'), name: 'career' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.2.title'), name: 'homeoffice' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.3.title'), name: 'location' },
                ],
            }
        },
        preferencesSet() {
            var count = 0
            for(var key in this.selectedUserRatings) {
                var u = this.selectedUserRatings[key]
                if(u) {
                    count++
                }
            }
            return count==4
        },
        hasFilters() {
            var has = false
            //console.log(this.filter)
            Object.keys(this.filter).forEach(f => {
                if(f=='sort') return
                var key = this.filter[f]
                //console.log(f, {k: key, typeof: typeof key, array: Array.isArray(key)})
                //if (key != null && typeof key == 'object' && key.from == null && key.to == null) {
                //    return
                //}
                if(key!=null && !Array.isArray(key)) {
                    has = true
                }
                if(key!=null && Array.isArray(key) && key.length>0) {
                    has = true
                }
            })
            return has
        },
        selectedItem() {
            if (!this.detailId || !this.candidates) return null
            return this.candidates.find((i) => i.id == this.detailId)
        },
        buttonConfig() {
            if(!this.selectedItem) return null
            let buttons = this.selectedItem.buttons || []
            if(!buttons.length) return null
            return {
                title: this.$t('jobs.apply_now'),
                icon: true,
                buttons: buttons
            }
        }
    },

    mounted: function () {
        let parent = document.getElementById('v-job-candidates-list')
        if(parent && parent.dataset.detailId) {
            this.landingPage.detailId = parent.dataset.detailId
        }

        if(parent && parent.dataset.unread) {
            this.unreadCount = parent.dataset.unread
        }

        if(parent && parent.dataset.jobReference) {
            this.jobReference = parent.dataset.jobReference
        }


        /*if(parent.length && parent[0].dataset.category) {
            var category = JSON.parse(parent[0].dataset.category)
            this.landingPage.category = category
            this.filter.category = category
        }

        //load filters
        var search = window.location.search ? new URLSearchParams(window.location.search) : null
        if(search) {
            var s,c,d,t = null
            if(s = search.get('s')) {
                this.filter.search = s
            }
            if(c = search.get('job_category')) {
                this.filter.category = parseInt(c)
            }
            if(t = search.getAll('job_types[]')) {
                this.filter.employmentType = t.map(i => parseInt(i))
            }
            if(d = search.get('districts')) {
                this.filter.district = parseInt(d)
            }
        }


        emitter.on('searchbanner_search', (data) => {
            this.filter.category = null
            this.filter.search = null
            this.filter.district = null
            data.forEach(i => {
                if(i.name=='s') {
                    this.filter.search = i.value || null
                }
                if(i.name=='job_category') {
                    this.filter.category = i.value || null
                }
                if(i.name=='districts') {
                    this.filter.district = i.value || null
                }
            })
            //reload data on filter update
            this.loadData(false);
        })

        //initial load
        this.loadData(this.landingPage.detailId)

        //load preferences
        let dm_preferences = localStorage.getItem('dm_preferences')
        if(dm_preferences) {
            dm_preferences = JSON.parse(dm_preferences)
            this.selectedUserRatings = dm_preferences
        }

        // Listen on URL Change
        window.addEventListener('popstate', this.updateLink)*/

        this.loadData(this.landingPage.detailId)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkTextClamped)
    },

    beforeUnmount() {
        window.removeEventListener('popstate', this.updateLink)
    },
    watch: {
        filter: {
            handler(val) {
                //console.log('hasFilters: ', this.hasFilters)
                this.loadData(false)
            },
            deep: true,
        },
        ratingBox: function(val) {
            if(val && this.$refs.ratingItem) {
                this.$refs.ratingItem.setStep(1)
            }
        }
    },

    methods: {
        onReferenceJobSelect: function(job) {
            console.log('onReferenceJobSelect', job)
            window.location.href = job.urlCandidatePool.url
        },
        onPreferencesSearch: function() {
            this.ratingBox = false
            this.loadData(null, true)
        },
        resetFilter: function() {
            this.filter.category = []
            this.filter.district = []
            this.filter.language = []
            this.filter.education = []
            this.filter.employmentType = []
            this.filter.benefit = []
            this.filter.search = null
            //this.filter.sort = 'relevance'
        },
        share: async function(method) {
            if(!this.selectedItem) return
            let text = $t('fe.category.job.share_text')
            let shareUrl = this.selectedItem.url
            if(method=='whatsapp') {
                var whatsappUrl = 'https://wa.me/?text=' + encodeURIComponent(text + shareUrl);
                window.open(whatsappUrl, '_blank');
            } else if(method=='facebook') {
                var facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl)+'';
                window.open(facebookUrl, '_blank');
            } else if(method=='email') {
                var emailUrl = 'mailto:?subject=dolomitenmarkt.it&body=' + encodeURIComponent(text+shareUrl);
                window.open(emailUrl, '_blank');
            } else if(method=='copy') {
                navigator.clipboard.writeText(shareUrl)
            }
        },
        loadMore: function() {
            if(this.hideLoadMore) return
            if(this.loading) return

            this.loading = true
            axios
                .get('/api/job/candidates', {
                    params: {
                        step: this.fetchStep,
                        filter: this.filter,
                        ids: this.candidates.map(i => i.id).join(','),
                        jobReference: this.jobReference,
                        p: this.selectedUserRatings
                    },
                })
                .then((response) => {
                    this.loading = false
                    if(response.data.candidates.length < this.fetchStep) {
                        this.hideLoadMore = true
                    }
                    this.candidates = [...this.candidates, ...response.data.candidates]
                    this.count = response.data.count
                }).catch((error) => {
                    console.log(error)
                    this.loading = false
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
        /*onMoreFilterSearch: function() {
            emitter.emit('more_filter_search')
        },*/
        onTab: function(tab) {
            emitter.emit('list_tab_change', tab)
        },
        loadData: function (loadId = null, updatePreferences = false) {
            if(this.loading) return
            this.candidates = null
            this.loading = true
            this.hideLoadMore = false
            this.detailId = null
            if(loadId === false){
                loadId = null
                if(!this.jobReference) {
                    window.history.pushState('job_empty', '', '/job/candidates'+window.location.search);
                }
            }
            axios
                .get('/api/job/candidates', {
                    params: {
                        step: this.fetchStep,
                        filter: this.filter,
                        id: loadId,
                        p: this.selectedUserRatings,
                        updateP: updatePreferences,
                        jobReference: this.jobReference
                    },
                })
                .then((response) => {
                    emitter.emit('listing_page_data_loaded', true)
                    this.loading = false


                    if(!this.initialLoadDone) {
                        if(this.filter.category.length==0 && response.data.customer.job_category_id) {
                            this.categoryPresetDone = true
                            this.filter.category = [response.data.customer.job_category_id]
                        }
                        if(response.data.selectedUserRatings) {
                            this.selectedUserRatings = response.data.selectedUserRatings
                        }
                        /*if(this.filter.district.length==0 && response.data.customer.district_id) {
                            this.filter.district = [response.data.customer.district_id]
                        }*/
                        this.initialLoadDone = true
                    }

                    this.candidates = response.data.candidates
                    this.count = response.data.count
                    if(response.data.candidates.length < this.fetchStep) {
                        this.hideLoadMore = true
                    }
                    this.categories = response.data.categories
                    this.employmentTypes = response.data.employmentTypes
                    this.districts = response.data.districts
                    this.languages = response.data.languages
                    this.educations = response.data.educations

                    if(loadId) {
                        this.selectItem(this.candidates.find(i => i.id==loadId), false)
                        /*setTimeout(function() {
                            $('html, body').animate({scrollTop: $('#scroll-box').offset().top-150});
                        }, 1000)*/
                    } else {
                        //on larger viewports auto-select item
                        if ($(window).width() >= 768) {
                            this.$nextTick(() => {
                                if(this.candidates && this.candidates.length && !this.detailId) {
                                    this.selectItem(this.candidates[0], false)
                                }
                            });
                        }
                    }
                })
                .catch((error) => {
                    emitter.emit('listing_page_data_loaded', true)
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },

        selectItem: function (item, updateUrl = true) {

            if(item) {
                window.lastScrollPosition = $('html').scrollTop();
                this.detailId = item.id
                if(updateUrl) {
                    if(!this.jobReference) {
                        window.history.pushState('job_'+item.id, '', item.url+window.location.search);
                    }

                    console.log('Update URL Tracking: '+item.url)
                    _paq.push(['setCustomUrl',  item.url]);
                    _paq.push(['setDocumentTitle', item.title+" "+(item.customer ? item.customer.agency : '')+" | „Dolomiten“-Markt Online [VPW]"]);
                    _paq.push(['trackPageView']);

                }
            } else {
                //todo: - vermutlich ist dieser link nur responsive angezeigt
                //todo: wieder zum vorherigen scroll-position springen
                this.detailId = null
                if(!this.jobReference) {
                    window.history.pushState('job_empty', '', '/job/candidates'+window.location.search);
                }
            }

            emitter.emit('listing_page_detail', {
                id: this.detailId,
                initialLoad : false
            })
        },

        updateLink() {
            const listRegex = /\/jobs\/[^\/]+(\/*e-([^\/]+)\/*)?$/

            emitter.emit('hide_all_contact_buttons', true)

            const path = location.pathname
            let item = this.candidates[0]

            let match = path.match(listRegex)
            if (!match) {
                return;
            }
            if (match[2]) {
                const id = match[2] * 1
                item = this.candidates.find((i) => i.id === id)
            }
            if (this.detailId === item.id) {
                return
            }


            this.selectItem(item, false)
        },
    },
}
</script>
<style lang="css" scoped>
.preferences-active {
    background-color: #afca05 !important;
}
.preferences-incomplete {
    background-color: red !important;
}
</style>
<style lang="css">
.popup-list .card {
    padding: 15px 20px !important;
    margin-top: 15px !important
}
</style>
