<template>
    <div class="block-liste row-large mx-0">
        <div class="sidebar mt-3 mt-md-0 px-0 ps-md-0 pe-md-3 d-md-block">
            <Dashboard 
                v-if="data"
                :profile="profile"
            />
        </div>
        <div class="mainbar pe-0 mt-4 mt-md-0" v-show="data">
            <div class="large-card px-3 py-md-4 px-xl-4">
                <Form
                    ref="form"
                    novalidate
                    @submit="onSubmit"
                    @invalid-submit="onInvalid"
                    class="form"
                    v-slot="{ errors, values, meta }"
                >

                    <template v-if="!private">
                        <h4 class="mt-0 mb-2">{{ $t('fe.profile.company_data_title') }}</h4>
                        <div class="row-small">
                            <div class="col-12 col-sm-8 col-md-8 col-lg-8 mt-2 mt-sm-3">
                                <div class="form-floating">
                                    <Field
                                        type="text"
                                        name="company_name"
                                        id="company_name"
                                        class="form-control"
                                        :placeholder="`${$t('validation.attributes.company_name')}*`"
                                        rules="required"
                                        :class="{ 'is-invalid': errors.company_name }"
                                    />
                                    <label for="company_name">{{ $t('validation.attributes.company_name') }}</label>
                                    <ErrorMessage as="div" name="company_name" class="invalid-feedback" />
                                </div>                                
                            </div>


                            <div class="col-12 col-sm-4 col-md-4 col-lg-4 mt-2 mt-sm-3 position-relative">
                                <div class="form-floating">
                                    <Field 
                                        type="number"
                                        name="workers"
                                        rules="required|numeric"
                                        placeholder="Anzahl Mitarbeiter"
                                        class="form-control"
                                        :class="{ 'is-invalid': errors.workers }"
                                    />
                                    <label for="company_name">Anzahl Mitarbeiter</label>
                                    <ErrorMessage as="div" name="workers" class="invalid-feedback" />
                                </div>
                            </div>

                        </div>


                        <div class="row-small mt-2">
                            <hr>
                            <h5 class="mt-0 mb-2">{{ $t('fe.profile.company_data_contact_person') }}</h5>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="firstname"
                                    :placeholder="`${$t('validation.attributes.firstname')}`"
                                    :class="{ 'is-invalid': errors.firstname }"
                                />
                                <ErrorMessage as="div" name="firstname" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="lastname"
                                    :placeholder="`${$t('validation.attributes.lastname')}`"
                                    :class="{ 'is-invalid': errors.lastname }"
                                />
                                <ErrorMessage as="div" name="lastname" class="invalid-feedback" />
                            </div>
                        </div>


                        <div class="row-small mt-2">
                            <hr>
                            <h5 class="mt-0 mb-2">{{ $t('fe.profile.company_data_invoice_info') }}</h5>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="tax_id"
                                    :placeholder="`${$t('validation.attributes.tax_id')}`"
                                    :class="{ 'is-invalid': errors.tax_id }"
                                />
                                <ErrorMessage as="div" name="tax_id" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="vat_id"
                                    :placeholder="`${$t('validation.attributes.vat_id')}`"
                                    :class="{ 'is-invalid': errors.vat_id }"
                                />
                                <ErrorMessage as="div" name="vat_id" class="invalid-feedback" />
                            </div>

                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="pec"
                                    :placeholder="`${$t('validation.attributes.pec')}`"
                                    :class="{ 'is-invalid': errors.pec }"
                                />
                                <ErrorMessage as="div" name="pec" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="sdi_code"
                                    :placeholder="`${$t('validation.attributes.sdi_code')}`"
                                    :class="{ 'is-invalid': errors.sdi_code }"
                                />
                                <ErrorMessage as="div" name="sdi_code" class="invalid-feedback" />
                            </div>
                        </div>

                        <div class="row-small mt-2">
                            <hr>
                            <h5 class="mt-0 mb-2">{{ $t('fe.profile.company_data_contact') }}</h5>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="email"
                                    autocomplete="none"
                                    name="email"
                                    readonly
                                    data-badge="Die E-Mail kann nicht geändert werden!"
                                    class="disabled"
                                    :placeholder="`${$t('validation.attributes.email')}*`"
                                />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="phone"
                                    :placeholder="`${$t('validation.attributes.phone')}`"
                                    :class="{ 'is-invalid': errors.phone }"
                                />
                                <ErrorMessage as="div" name="phone" class="invalid-feedback" />
                            </div>

                            <p class="text-muted mt-4" v-html="$t('fe.profile.contact_methods_description')"></p>
                            <label class="d-flex align-items-center mt-2 mt-sm-1">
                                <Field rules="required" type="checkbox" name="contact_methods" value="form" /><span>{{ $t('validation.attributes.form') }}</span>
                            </label>
                            <label class="d-flex align-items-center mt-2 mt-sm-1">
                                <Field 
                                    type="checkbox"
                                    name="contact_methods"
                                    value="email"
                                    rules="required"
                                /><span
                                    >{{ $t('validation.attributes.email') }}</span
                                >
                            </label>
                            <label class="d-flex align-items-center mt-2 mt-sm-1">
                                <Field rules="required" type="checkbox" name="contact_methods" value="phone" /><span>{{ $t('validation.attributes.phone') }}</span>
                            </label>
                            <label class="d-flex align-items-center mt-2 mt-sm-1">
                                <Field rules="required" type="checkbox" name="contact_methods" value="incognito" /><span>{{ $t('validation.attributes.incognito') }}</span>
                            </label>

                        </div>


                        <div class="row-small mt-2">
                            <hr>
                            <h5 class="mt-0 mb-2">{{ $t('fe.profile.company_data_address') }}</h5>


                            <Field
                                type="hidden"
                                name="location"
                                :modelValue="location"
                                rules="required"
                            />
                            <ErrorMessage
                                as="div"
                                name="location"
                                class="invalid-feedback d-block"
                                :class="{ 'd-block': errors.location }"
                            />

                            <MapForm
                                class="mb-5 mt-2"
                                ref="map"
                                @onAddressData="(data) => $refs.form.setValues(data)"
                                v-model="location"
                            />

                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="street"
                                    :placeholder="`${$t('validation.attributes.street')}`"
                                    :class="{ 'is-invalid': errors.street }"
                                />
                                <ErrorMessage as="div" name="street" class="invalid-feedback" />
                            </div>

                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="street_number"
                                    :placeholder="`${$t('validation.attributes.street_number')}`"
                                    :class="{ 'is-invalid': errors.street_number }"
                                />
                                <ErrorMessage as="div" name="street_number" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="postal_code"
                                    :placeholder="`${$t('validation.attributes.postal_code')}*`"
                                    rules="required"
                                    :class="{ 'is-invalid': errors.postal_code }"
                                />
                                <ErrorMessage as="div" name="postal_code" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="city"
                                    :placeholder="`${$t('validation.attributes.city')}*`"
                                    rules="required"
                                    :class="{ 'is-invalid': errors.city }"
                                />
                                <ErrorMessage as="div" name="city" class="invalid-feedback" />
                            </div>

                        </div>
                    </template>

                    <template v-else>
                        <h4 class="mt-0 mb-2">{{ $t('fe.profile.personal_data_title') }}</h4>
                        <div class="row-small">
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="firstname"
                                    :placeholder="`${$t('validation.attributes.firstname')}*`"
                                    rules="required"
                                    :class="{ 'is-invalid': errors.firstname }"
                                />
                                <ErrorMessage as="div" name="firstname" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="lastname"
                                    :placeholder="`${$t('validation.attributes.lastname')}*`"
                                    rules="required"
                                    :class="{ 'is-invalid': errors.lastname }"
                                />
                                <ErrorMessage as="div" name="lastname" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="email"
                                    autocomplete="none"
                                    data-badge="Die E-Mail kann nicht geändert werden!"
                                    name="email"
                                    readonly
                                    class="disabled"
                                    :placeholder="`${$t('validation.attributes.email')}*`"
                                    rules="required"
                                    :class="{ 'is-invalid': errors.email }"
                                />
                                <ErrorMessage as="div" name="email" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="phone"
                                    :placeholder="`${$t('validation.attributes.phone')}`"
                                    :class="{ 'is-invalid': errors.phone }"
                                />
                                <ErrorMessage as="div" name="phone" class="invalid-feedback" />
                            </div>
                        </div>

                        <div class="row-small mt-2">
                            <hr>
                            <h5 class="mt-0 mb-2">{{ $t('fe.profile.personal_data_address') }}</h5>


                            <Field
                                type="hidden"
                                name="location"
                                :modelValue="location"
                                rules="required"
                            />
                            <ErrorMessage
                                as="div"
                                name="location"
                                class="invalid-feedback d-block"
                                :class="{ 'd-block': errors.location }"
                            />

                            <MapForm
                                class="mt-2"
                                ref="map"
                                @onAddressData="onPrivateAddressData"
                                v-model="location"
                            />

                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="postal_code"
                                    :placeholder="`${$t('validation.attributes.postal_code')}*`"
                                    rules="required"
                                    :class="{ 'is-invalid': errors.postal_code }"
                                />
                                <ErrorMessage as="div" name="postal_code" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="city"
                                    :placeholder="`${$t('validation.attributes.city')}*`"
                                    rules="required"
                                    :class="{ 'is-invalid': errors.city }"
                                />
                                <ErrorMessage as="div" name="city" class="invalid-feedback" />
                            </div>

                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field
                                    type="text"
                                    name="birth_year"
                                    rules="minValue:1900|maxValue:2100"
                                    :placeholder="`${$t('validation.attributes.birth_year')}`"
                                    :class="{ 'is-invalid': errors.birth_year }"
                                />
                                <ErrorMessage as="div" name="birth_year" class="invalid-feedback" />
                            </div>
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 mt-2 mt-sm-3">
                                <Field as="select" name="gender" :class="{ 'is-invalid': errors.gender }">
                                    <option value="" selected>{{ $t('validation.attributes.gender') }}</option>
                                    <option value="male">{{ $t('generic.gender.male') }}</option>
                                    <option value="female">{{ $t('generic.gender.female') }}</option>
                                    <option value="other">{{ $t('generic.gender.other') }}</option>
                                </Field>
                                <ErrorMessage as="div" name="gender" class="invalid-feedback" />
                            </div>

                        </div>

                        <hr />

                        <h4 class="my-0">{{ $t('fe.profile.contact_methods') }}*</h4>
                        <p class="text-muted" v-html="$t('fe.profile.contact_methods_description')"></p>
                        <label class="d-flex align-items-center mt-2 mt-sm-1">
                            <Field type="checkbox" name="contact_methods" value="form" /><span>{{ $t('validation.attributes.form') }}</span>
                        </label>
                        <label class="d-flex align-items-center mt-2 mt-sm-1">
                            <Field 
                                type="checkbox"
                                name="contact_methods"
                                rules="required"
                                value="email"
                            /><span
                                >{{ $t('validation.attributes.email') }}</span
                            >
                        </label>
                        <label class="d-flex align-items-center mt-2 mt-sm-1">
                            <Field type="checkbox" name="contact_methods" value="phone" /><span>{{ $t('validation.attributes.phone') }}</span>
                        </label>
                        <br>
                        <label class="d-flex align-items-center mt-2 mt-sm-1">
                                <Field type="checkbox" name="contact_hidden" :value="true"/><span>{{ $t('validation.attributes.contact_hidden') }}</span>
                        </label>
                    </template>
                    

                    <button
                        :class="{ disabled: Object.keys(errors).length || !meta.valid }"
                        class="mt-3 ms-lg-auto d-flex align-items-center btn btn-large btn-general btn-te px-3"
                    >
                        {{ $t('fe.generic.save_changes') }}
                        <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                            <path
                                fill="#FFFFFF"
                                d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
    C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"
                            ></path>
                            <path
                                fill="#FFFFFF"
                                d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
    c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
    c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"
                            ></path>
                        </svg>
                    </button>
                </Form>

                <hr />

                <h4 class="my-0">{{ $t('fe.profile.change_password') }}</h4>
                <a href="#" @click.prevent="changePassword" class="btn btn-general btn-bs mt-3">{{
                    $t('fe.profile.change_password')
                }}</a>

                <PopupChangePassword :show="popupChangePassword" @close="popupChangePassword = false" />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import emitter from '../plugins/mitt'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'
import PopupChangePassword from '@/components/inc/PopupChangePassword.vue'
import Dashboard from "@/components/inc/profile/Dashboard.vue"
import MapForm from "@/components/inc/form/MapForm.vue"

export default {
    name: 'Profile',
    components: {
        Dashboard,
        PopupChangePassword,
        Form,
        Field,
        ErrorMessage,
        MapForm
    },
    inject: ['$t'],
    mounted: async function () {
        this.loadData()
    },
    computed: {
        name: function() {
            if(this.private) {
                return (this.$refs.form.values.firstname || '')+' '+(this.$refs.form.values.lastname || '')
            }
            return this.$refs.form.values.company_name
        },
        profile: function() {
            return {
                ...this.$refs.form.values,
                private: this.private,
                name: this.name
            }
        },
    },
    watch: {
        popupChangePassword: function (val) {
            emitter.emit('popup', val ? 'change_password' : false)
        },
    },
    methods: {
        onPrivateAddressData: function(data) {
            this.$refs.form.setFieldValue('city', data.city)
            this.$refs.form.setFieldValue('postal_code', data.postal_code)
        },
        onInvalid: function ({ values, errors, results }) {
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        changePassword: function () {
            //emitter.emit('popup', 'change_password')
            this.popupChangePassword = true
        },

        onSubmit: function (values) {
            Swal.fire({
                title: this.$t('popup.register.title'),
                text: this.$t('generic.loading'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            axios
                .post('/api/profile', { ...values })
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    //Swal.close()
                    Swal.fire({
                        icon: 'success',
                        text: $t('generic.save_alert'),
                        didClose: () => window.scrollTo(0,0)
                    })
                    this.loadData()
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
        loadData: function () {
            if (this.loading) return
            this.loading = true
            axios
                .get('/api/profile')
                .then(async (response) => {
                    this.loading = false

                    this.private = response.data.private
                    this.data = {...response.data}
                    this.location = response.data.location
                    setTimeout(() => {
                        if (this.$refs && this.$refs.form) {
                            this.$refs.form.setValues(this.data)
                        }
                    }, 100)
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
    },
    data() {
        return {
            location: null,
            data: null,
            popupChangePassword: false,
            private: true,
        }
    },
}
</script>
