<template>
    <div v-bind="getRootProps()">
        <div class="upload-container text-center">
            <input v-bind="getInputProps()" />
            <svg class="text-primary" width="45" height="30px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 45 30">
                <path fill="currentcolor"
                        d="M36.29,11.32c-1.28-6.45-6.97-11.32-13.79-11.32-5.42,0-10.12,3.08-12.47,7.57C4.4,8.18,0,12.95,0,18.75c0,6.22,5.03,11.25,11.25,11.25h24.37c5.18,0,9.38-4.2,9.38-9.38s-3.85-8.96-8.71-9.31ZM26.25,16.88v7.5h-7.5v-7.5h-5.62l9.37-9.37,9.38,9.38h-5.62Z"/>
            </svg>
            <strong class="button-upload mt-1 tincx-small text-bold mt-3 mb-1">{{ !multiple ? 'Datei' : 'Dateien' }} auswählen</strong>
            <small>
                <span class="count-files">{{ files.length ? files.length : 'Keine' }}</span> <span class="single" v-if="files.length<=1">Datei ausgewählt</span><span class="multiple" v-else>Dateien ausgewählt</span>
            </small>

            <div v-if="files.length > 0 && !imageIcons" class="files mt-3" >
                <div class="file-item" v-for="(file, index) in files" :key="`index_${file.name}`" @click="openFile(file)">
                    <a :href="file.preview" v-if="imagePreview && file.preview" @click.stop="" target="_blank">
                        <img :src="file.preview"  class="file-preview" />
                    </a>
                    <input type="hidden" name="file[]" :value="file.path">
                    <span>{{ file.name }}</span>
                    <span class="delete-file ms-1 btn btn-danger btn-sm" @click.stop="onDeleteFile(index)">Löschen</span>
                </div>
            </div>

            <div v-if="filesUploading" class="mt-3">
                <span class="files-loader loader loader-sm me-2"></span>
            </div>

        </div>

        <div class="row-small mt-2" v-if="files.length && imageIcons">
            <div class="col-6 col-sm-4 col-lg-3 mt-3 upload-image-box" v-for="(file, index) in files" :key="`indexImgIcons_${file.name}`" @click="openFile(file)">
                <div class="position-relative">
                    <a :href="file.preview" @click.stop="" target="_blank">
                        <img :src="file.preview" :class="{'img-contain' : imagePreviewContain}" />
                    </a>
                    <input type="hidden" name="file[]" :value="file.path">
                    <div class="remove-image text-primary" @click.stop="onDeleteFile(index)">
                        <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.3 28.3">
                        <circle fill="#fff" cx="14" cy="14" r="14"></circle>
                        <path fill="currentcolor" d="M14.1,0C14.1,0,14.1,0,14.1,0C6.3,0,0,6.3,0,14.1c0,7.8,6.3,14.1,14.1,14.1c7.8,0,14.1-6.3,14.1-14.1
                    C28.3,6.3,22,0,14.1,0z M19.3,17.7c0.4,0.4,0.4,1.1,0,1.5c-0.4,0.4-1.1,0.4-1.5,0c0,0,0,0,0,0l-3.6-3.6l-3.6,3.6
                    c-0.4,0.4-1.1,0.5-1.5,0.1c-0.4-0.4-0.5-1.1-0.1-1.5c0,0,0.1-0.1,0.1-0.1l3.6-3.6L9,10.6C8.6,10.1,8.7,9.4,9.1,9
                    c0.4-0.4,1-0.4,1.4,0l3.6,3.6L17.7,9c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-3.6,3.6L19.3,17.7z"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {ref, toRaw} from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useDropzone } from "vue3-dropzone";
import { required } from '@vee-validate/rules';

export default {
    name: 'FileUpload',
    inject: ['$t'],
    methods: {
        onDeleteFile: function(index) {
            this.files.splice(index, 1)
        },
        openFile: function(file) {
            if(!file.url) return
            window.open(file.url, '_blank').focus()
        }
    },
    props: {
        accept: {
            required: true,
            default: '.pdf,.doc,.docx,.odt,.ott,.oth,.and,.odm,.jpg,.jpeg,.tiff,.png'
        },
        maxFiles: {
            required: false,
            default: null
        },
        multiple: {
            required: true,
            default: true
        },
        imagePreview: {
            required: false,
            default: false
        },
        modelValue: {
            required: false,
        },
        imageIcons: {
            required: false,
            default: false
        },
        imagePreviewContain: {
            required: false,
            default: false
        }
    },
    watch: {
        files: {
            handler(files) {
                if (JSON.stringify(toRaw(files))==JSON.stringify(this.modelValue)) return
                this.$emit('update:modelValue', toRaw(files))
                this.$emit('files', toRaw(files))
            },
            deep: true
        },
        modelValue: {
            handler(files, oldFiles) {                
                if (JSON.stringify(files)==JSON.stringify(oldFiles)) return
                this.files = [...files];
            },
            immediate: true
        },
    },
    setup(props) {
        const filesUploading = ref(false)
        const files = ref([])
        const url = "/api/upload-files"; // Your url on the server side
        const saveFiles = (saveFiles) => {

            if(props.maxFiles && files.value.length >= props.maxFiles) {
                Swal.fire({
                    icon: 'error',
                    html: "Fehler beim Hochladen der Dateien: "+`Du kannst maximal ${props.maxFiles} Dateien hochladen!`,
                    timer: 5000
                })
                return
            }
            if(!props.multiple && files.value.length) {
                Swal.fire({
                    icon: 'error',
                    html: "Fehler beim Hochladen der Dateien: "+`Du kannst maximal eine Datei hochladen!`,
                    timer: 5000
                })
                return
            }

            filesUploading.value = true
            const formData = new FormData(); // pass data as a form
            for (var x = 0; x < saveFiles.length; x++) {
                // append files as array to the form, feel free to change the array name
                formData.append("file[]", saveFiles[x]);
            }

            formData.append('imagePreview', props.imagePreview)

            axios
                .post(
                    url,
                    formData
                )
                .then((response) => {
                    console.info(response.data);
                    files.value = [...files.value, ...response.data.files]
                    filesUploading.value = false
                })
                .catch((err) => {
                    console.error(err);
                    filesUploading.value = false

                    Swal.fire({
                        icon: 'error',
                        html: "Fehler beim Hochladen der Dateien: "+saveFiles.map(f => f.name).join(', '),
                        timer: 5000
                    })

                });
        };

        function onDrop(acceptFiles, rejectReasons) {
            //console.log(acceptFiles);
            if(saveFiles.length) {
                saveFiles(acceptFiles); // saveFiles as callback
            }
            if(rejectReasons.length) {
                console.log(rejectReasons);
                var reasons = []
                rejectReasons.forEach(i => {
                    i.errors.forEach(e => {
                        var msg = e.message
                        if(e.code=='file-too-large') {
                            msg = 'Die Datei ist zu groß!'
                        } else if(e.code=='too-many-files') {
                            if(props.maxFiles==1) {
                                msg = `Du kannst maximal eine Datei hochladen!`
                            } else {
                                msg = `Du kannst maximal ${props.maxFiles} Dateien hochladen!`
                            }
                            reasons.push(msg)
                            return
                        }
                        reasons.push(i.file.name+': '+msg)
                    })
                })
                const uniqueReasons = [...new Set(reasons)]
                Swal.fire({
                    icon: 'error',
                    html: uniqueReasons.join('<br>'),
                    timer: 5000
                })
            }
        }

        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop, maxFiles: props.maxFiles, multiple: props.multiple, accept: props.accept/*, maxSize: 10*/});

        return {
            getRootProps,
            getInputProps,
            ...rest,

            //data values
            files,
            filesUploading
        };
    }
}
</script>

<style lang="scss" scoped>
.files-loader {
    border-bottom-color: #719dc6;
}
.files {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    font-size: 12px;
}
.file-item {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(112 158 199 / 20%);
  padding: 7px;
  padding-left: 15px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  .delete-file {
    background: white;
    color: #231f20;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
  }
}
.file-preview {
    width: auto;
    height: auto;
    max-width: 100px;
    max-height: 100px;
}
.remove-image {
    cursor: pointer;
}
.form .upload-image-box img.img-contain {
    object-fit: contain;
    border: 1px solid gray;
}
</style>