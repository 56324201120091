<template>
    <div
        class="upload-image-container"
        v-bind="getRootProps()"
        >

        <slot v-if="$slots.logo" name="logo" :logo="logo">
            
        </slot>

        <slot v-if="$slots.edit" name="edit">

        </slot>
        
        <input v-bind="getInputProps()" />
        <div class="loading-overlay" v-if="filesUploading">
            <div class="files-loader loader loader-sm me-2" ></div>
        </div>
    </div>
</template>
<script>
import {ref, toRaw} from 'vue'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useDropzone } from "vue3-dropzone";

export default {
    name: 'ImageUploadContainer',
    inject: ['$t'],
    methods: {
        openFile: function(file) {
            if(!file.url) return
            window.open(file.url, '_blank').focus()
        }
    },
    computed: {
        logo: function() {
            return this.file?.preview || this.image
        }
    },
    props: {
        image: {
            required: false,
            default: null
        },
        accept: {
            required: false,
            default: '.jpg,.jpeg,.png'
        },
        modelValue: {
            required: false,
        },
    },
    watch: {
        file: {
            handler(file) {
                if (JSON.stringify(toRaw(file))==JSON.stringify(this.modelValue)) return
                this.$emit('update:modelValue', toRaw(file))
            },
            deep: true
        },
        modelValue: {
            handler(file, oldFile) {                
                if (JSON.stringify(file)==JSON.stringify(oldFile)) return
                this.file = {...file};
            },
            immediate: true
        },
    },
    setup(props) {
        const filesUploading = ref(false)
        const file = ref(null)
        const url = "/api/upload-files"; // Your url on the server side
        const saveFiles = (saveFiles) => {

            filesUploading.value = true
            const formData = new FormData(); // pass data as a form
            formData.append("file[]", saveFiles[0]);
            formData.append('imagePreview', true)

            axios
                .post(
                    url,
                    formData
                )
                .then((response) => {
                    console.info(response.data);
                    file.value = {...response.data.files[0]}
                    filesUploading.value = false
                })
                .catch((err) => {
                    console.error(err);
                    filesUploading.value = false

                    Swal.fire({
                        icon: 'error',
                        html: "Fehler beim Hochladen der Datei: "+saveFiles.map(f => f.name).join(', '),
                        timer: 5000
                    })

                });
        };

        function onDrop(acceptFiles, rejectReasons) {
            //console.log(acceptFiles);
            if(saveFiles.length == 1) {
                saveFiles(acceptFiles); // saveFiles as callback
            }
            if(rejectReasons.length) {
                console.log(rejectReasons);
                var reasons = []
                rejectReasons.forEach(i => {
                    i.errors.forEach(e => {
                        var msg = e.message
                        if(e.code=='file-too-large') {
                            msg = 'Die Datei ist zu groß!'
                        } else if(e.code=='too-many-files') {
                            if(props.maxFiles==1) {
                                msg = `Du kannst maximal eine Datei hochladen!`
                            } else {
                                msg = `Du kannst maximal ${props.maxFiles} Dateien hochladen!`
                            }
                            reasons.push(msg)
                            return
                        }
                        reasons.push(i.file.name+': '+msg)
                    })
                })
                const uniqueReasons = [...new Set(reasons)]
                Swal.fire({
                    icon: 'error',
                    html: uniqueReasons.join('<br>'),
                    timer: 5000
                })
            }
        }

        const { getRootProps, getInputProps, ...rest } = useDropzone({
            onDrop,
            maxFiles: 1,
            multiple: false,
            accept: props.accept
        });

        return {
            getRootProps,
            getInputProps,
            ...rest,

            //data values
            file,
            filesUploading
        };
    }
}
</script>

<style lang="scss" scoped>
.upload-image-container {
    position: relative;
}
.loading-overlay {
    position: absolute;
    width: 90%;
    height: 90%;
    background-color: #00000087;
    border-radius: 12px;
}

.files-loader {
    position: absolute;
    top: 10%;
    left: 10%;
}
.upload-image-container {
    cursor: pointer
}
</style>