<template>
    <!-- liste -->
    <section class="asymetric-columns block-liste text-box pt-small pb-large" id="content">
        <div class="container px-0 px-md-3">
            <div class="px-3 px-md-0 block-title-filter d-md-block" :class="{ 'd-none': detailId }">
                <div class="breadcrumbs tincx-small text-muted text-ellipsis">
                    <a class="text-muted text-no-underline" :href="$t('fe.category.floh.url')">{{ $t('fe.category.floh.title') }}</a>
                    <span class="px-1 px-xs-2">/</span>
                    <a v-if="filterCategory" class="text-muted text-no-underline" :href="filterCategory.url">{{ filterCategory.title }}</a>
                    <span v-else class="text-ellipsis">{{ $t('generic.search_results') }}</span>
                </div>

                <h2 class="filter-title tincx-h4 mt-2">
                    {{ $t('generic.search_results_results', { count: this.count ? $f.number(this.count) : '...' }) }}
                    <span class="loader loader-sm ms-2" v-if="loading"></span>

                    <a
                        href="#"
                        @click.prevent="resetFilter"
                        class="text-no-underline text-small text-left d-block text-normal mt-1"
                        v-if="hasFilters"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15" fill="#5f6368">
                            <path
                                d="m592-481-57-57 143-182H353l-80-80h487q25 0 36 22t-4 42L592-481ZM791-56 560-287v87q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-247L56-791l56-57 736 736-57 56ZM535-538Z"
                            />
                        </svg>
                        {{ $t('fe.reset_search') }}
                    </a>
                </h2>

                <div class="filter-bar d-flex align-items-center justify-content-between">
                    <div class="d-block d-lg-flex filter-desktop">
                        <div class="d-flex d-lg-none align-items-center justify-content-between">
                            <p class="my-0">
                                <strong>{{ $t('generic.filter') }}:</strong>
                            </p>

                            <a href="#" class="close-filter p-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                                    <path
                                        d="M7.2,6L11.7,1.5c.3-.3.3-.9,0-1.2-.3-.3-.9-.3-1.2,0l-4.5,4.5L1.5.3C1.2,0,.6,0,.3.3,0,.6,0,1.2.3,1.5l4.5,4.5L.3,10.5c-.3.3-.3.9,0,1.2.2.2.4.2.6.2s.4,0,.6-.2l4.5-4.5,4.5,4.5c.2.2.4.2.6.2s.4,0,1.6-.2c.3-.3.3-.9,0-1.2l-4.5-4.5Z"
                                    />
                                </svg>
                            </a>
                        </div>

                        <div id="filter-mobile" class="d-lg-none">
                            <MultiSelect :items="categories" :title="$t('fe.generic.floh_category')" v-model="filter.category" />
                            <MultiSelect :items="districts" :title="$t('fe.generic.district')" v-model="filter.district" />
                        </div>

                        <MultiSelect
                            :multiple="false"
                            :items="[{id: 'SALE', name: 'Angebot'}, {id: 'BUY', name: 'Gesuch'}, {id: 'CHANGE', name: 'Tausch'}]"
                            :title="$t('fe.category.floh.options.sale_buy_change')"
                            v-model="filter.sale_buy_change"
                        />

                        <RangeSelect
                            :items="prices"
                            :title="$t('fe.category.immo.options.price')"
                            v-model="filter.price"
                        />

                        <div class="text-center pt-3 mt-0 d-lg-none">
                            <a href="#" class="btn btn-primary btn-large btn-te px-3 search-button">
                                {{ $t('generic.show_results', { count: count ? $f.number(count) : 0 }) }}
                            </a>
                        </div>
                    </div>

                    <div class="d-flex sorting-map align-items-center">
                        <select v-model="filter.sort" class="col-auto">
                            <option value="new">{{ $t('generic.sort_newest') }}</option>
                            <option value="old">{{ $t('generic.sort_oldest') }}</option>
                            <option value="price_asc">{{ $t('generic.sort_price_asc') }}</option>
                            <option value="price_desc">{{ $t('generic.sort_price_desc') }}</option>
                            <option v-if="false" value="relevance">{{ $t('generic.sort_relevance') }}</option>
                        </select>
                        <div class="map position-relative d-none d-lg-block" v-if="false && 'todo'">
                            <div class="cover-box">
                                <img src="/images/map.jpg" alt="Map" />
                            </div>
                            <div class="overlay p-2 d-flex align-items-center justify-content-center">
                                <div>
                                    <img class="d-block mx-auto mb-2" src="/images/icons/icon-marker.svg" alt="Marker" />
                                    <a href="#" class="btn btn-primary btn-bs">{{ $t('generic.show_map') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row-large mx-0 pt-small">
                <div class="sidebar px-2 px-xs-3 ps-md-0 d-md-flex" :class="{ 'd-none': detailId }">
                    <div class="d-flex flex-wrap w-100">

                        <div v-if="!loading && offers && offers.length==0">
                            {{ $t('fe.generic.no_items_found')}}
                            <a
                                href="#"
                                @click.prevent="resetFilter"
                                class="text-no-underline text-small text-left d-block text-normal mt-3"
                                v-if="hasFilters"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" height="15" viewBox="0 -960 960 960" width="15" fill="#5f6368">
                                    <path
                                        d="m592-481-57-57 143-182H353l-80-80h487q25 0 36 22t-4 42L592-481ZM791-56 560-287v87q0 17-11.5 28.5T520-160h-80q-17 0-28.5-11.5T400-200v-247L56-791l56-57 736 736-57 56ZM535-538Z"
                                    />
                                </svg>
                                {{ $t('fe.reset_search') }}
                            </a>
                        </div>

                        <template v-for="(offer, index) in offers" :key="offer.id">
                            <div

                                :data-id="offer.id"
                                @click="selectItem(offer)"
                                :class="{ 'top-offer': offer.offer_of_the_day, active: detailId == offer.id, first: index == 0 }"
                                class="card position-relative d-flex align-items-center"
                            >
                                <div class="offer-image">
                                            <div class="cover-box" v-if="offer.list_image ">
                                                <img :src="offer.list_image">
                                            </div>
                                            <div
                                                v-else
                                                class="bg-muted d-flex align-items-center justify-content-center h-100"
                                            >
                                                <img src="/images/logo-dolomitenmarkt.svg" width="110" height="40">
                                            </div>
                                    <span class="position-absolute badge" v-if="offer.offer_of_the_day">
                                        <span class="d-none d-sm-block d-md-none d-xl-block">{{ $t('fe.category.floh.offer_of_the_day') }}</span>
                                        <span class="d-sm-none d-md-block d-xl-none">{{ $t('fe.category.floh.offer_of_the_day_mobile') }}</span>
                                    </span>
                                    <span v-else-if="offer.new" class="position-absolute badge">{{ $t('fe.category.floh.new') }}</span>
                                </div>
                                <div class="offer-box">
                                    <span class="offer-subtitle">{{ offer.type }}</span>
                                    <span class="offer-title text-bold">{{  offer.title }}</span>
                                    <div class="d-flex justify-content-between mt-1">
                                        <span class="d-flex align-items-center text-ellipsis offer-item" v-if="offer.place">
                                            <img src="/images/icons/icon-location.svg" alt="Location" class="me-1 d-block location-icon" />
                                            <span class="text-ellipsis">{{ offer.place }}</span>
                                        </span>
                                        <span class="tincx-p text-bold ps-2 text-nowrap offer-price" v-if="offer.price">{{ $f.number(offer.price) }}&nbsp;€<template v-if="offer.verhandelbar"> {{ $t('fe.category.floh.price_verhandelbar_short') }}</template></span>
                                    </div>
                                </div>
                            </div>
                        </template>


                    </div>


                    <div class="loader-container" v-if="loading">
                        <span class="loader" ></span>
                    </div>
                    <div class="position-relative px-3 px-xs-0" style="order: 3;margin-top: 30px;" v-if="offers && offers.length && !loading && !hideLoadMore">
                        <a @click="loadMore" class="btn btn-bs btn-primary" :class="{disabled: loading}">{{ loading ? $t('generic.loading') : $t('generic.load_more') }}</a>
                    </div>
                    <div class="placeholder-item"></div>

                    <div :class="{ 'd-none d-md-block': !detailId }" class="contact-buttons-container" v-if="buttonConfig && false">
                        <ContactButton :config="buttonConfig" />
                    </div>
                </div>

                <div class="mainbar pe-0 d-md-block" :class="{'d-none': !detailId}">
                    <img class="d-md-none" src="/images/floh/header-flohmarkt-active.jpg" alt="Mobile Header Floh" />

                    <div class="scroll-box" id="scroll-box"  :class="{'d-none': !detailId}">
                        <div class="large-card">
                            <div class="row-large">
                                <a
                                    @click.prevent="selectItem(null)"
                                    href="#"
                                    class="d-flex align-items-center tincx-p text-muted mb-3 mt-0 d-md-none close-detail"
                                >
                                    <svg class="me-1" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                                        <path
                                            fill="currentcolor"
                                            d="M7.75,15.5c4.27,0,7.75-3.48,7.75-7.75S12.02,0,7.75,0,0,3.48,0,7.75s3.48,7.75,7.75,7.75ZM7.75,1.29c3.58,0,6.46,2.88,6.46,6.46s-2.88,6.46-6.46,6.46S1.29,11.33,1.29,7.75,4.17,1.29,7.75,1.29Z"
                                        ></path>
                                        <path
                                            fill="currentcolor"
                                            d="M6.46,10.53c.3.3.7.3.89,0s.3-.7,0-.89l-1.29-1.29h4.77c.4,0,.6-.3.6-.6s-.3-.6-.6-.6h-4.77l1.29-1.29c.3-.3.3-.7,0-.89s-.3-.2-.5-.2-.3,0-.5.2l-2.29,2.29h0c-.1.1-.2.3-.2.5s2.58,2.78,2.58,2.78Z"
                                        ></path>
                                    </svg>
                                    {{ $t('generic.back_to_search') }}
                                </a>
                                <div id="title-mobile" class="d-lg-none" v-if="selectedItem">
                                    <FlohTitleContent :selectedItem="selectedItem" />
                                </div>
                                <ul class="tabs d-flex d-lg-none mt-0 mb-0">
                                    <li class="active">
                                        <a href="#main" @click.prevent="onTab('main')" class="main-content">{{ $t('fe.category.floh.list.info.tab_1') }}</a>
                                    </li>
                                    <li>
                                        <a href="#info-mobile" @click.prevent="onTab('info-mobile')">{{ $t('fe.category.floh.list.info.tab_2') }}</a>
                                    </li>
                                </ul>
                                <div class="col-12 col-lg" v-if="selectedItem">
                                    <div id="title-desktop" class="d-none d-lg-block">
                                        <FlohTitleContent :selectedItem="selectedItem" />
                                    </div>
                                    <div id="main" class="mt-4 mt-lg-2">
                                        <div class="d-flex flex-wrap images mb-4" v-if="selectedItem.images && selectedItem.images.length">
                                            <div class="col-12 col-sm position-relative full-image">
                                                <a
                                                    class="response-image-1"
                                                    :href="selectedItem.images[0]"
                                                    data-type="image"
                                                    data-fancybox="gallery"
                                                >
                                                    <img :src="selectedItem.images[0]" class="w-100" />
                                                    <span class="imagesbutton" v-if="selectedItem.images.length>1">
                                                        Alle <span class="image-number">{{  selectedItem.images.length }}</span> Fotos ansehen
                                                    </span>
                                                </a>
                                            </div>
                                            <div class="col-sm-4 half-image" v-if="selectedItem.images && selectedItem.images.length" :class="{'d-none': selectedItem.images.length < 3}">
                                                <a
                                                    v-if="selectedItem.images.length>=2"
                                                    class="response-image-2"
                                                    :href="selectedItem.images[1]"
                                                    data-type="image"
                                                    data-fancybox="gallery"
                                                >
                                                    <img :src="selectedItem.images[1]" />
                                                </a>
                                                <a
                                                    v-if="selectedItem.images.length>=3"
                                                    class="response-image-3"
                                                    :href="selectedItem.images[2]"
                                                    data-type="image"
                                                    data-fancybox="gallery"
                                                >
                                                    <img class="mt-2" :src="selectedItem.images[2]" />
                                                </a>
                                                <div class="extra-images" v-if="selectedItem.images && selectedItem.images.length>3">
                                                    <a
                                                        v-for="image in selectedItem.images.slice(3)"
                                                        :href="image"
                                                        data-type="image"
                                                        data-fancybox="gallery"
                                                    ></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="response-video-container" v-if="selectedItem.video">
                                            <video
                                                class="w-100 video-container response-video mb-4"
                                                controls=""
                                                loop=""
                                                playsinline=""
                                                autoplay=""
                                                preload="auto"
                                            >
                                                <source :src="selectedItem.video" type="video/mp4" />
                                            </video>
                                        </div>
                                        <h3 class="tincx-h5 my-0">{{ $t('fe.category.floh.detail.description') }}</h3>

                                        <div class="response-description-container">
                                            <p
                                                ref="responseDescription"
                                                class="response-description"
                                                v-html="selectedItem.description"
                                                :class="{ hidden: !descriptionReadMore }"
                                            ></p>
                                            <a
                                                v-if="isTextClamped || descriptionReadMore"
                                                href="#"
                                                @click.prevent="descriptionReadMore = !descriptionReadMore"
                                                class="text-grey show-more-description"
                                            >
                                                <span class="more" v-if="!descriptionReadMore">{{ $t('generic.read_more') }}</span>
                                                <span class="less" v-else>{{ $t('generic.read_less') }}</span>
                                            </a>
                                        </div>


                                        <!-- todo: map -->
                                        <div class="w-100 mapbox my-4" v-if="false && location && 'todo-location'">
                                            <div class="col map-container"
                                                data-centerx="46.4949621"
                                                data-centery="11.3489911"
                                                data-zoom="14"></div>
                                        </div>

                                        <div class="mt-4 d-none d-md-block">
                                            <ContactButton v-if="buttonConfig" :config="buttonConfig" :inline="true" />
                                        </div>

                                        <hr>
                                        <p><strong class="mt-3 d-block">{{ $t('generic.share_ad') }}</strong></p>
                                        <div class="row-small align-items-center mt-2 social-share">
                                            <a href="#" @click.prevent="share('whatsapp')" class="whatsapp-link col-auto">
                                                <img src="/images/icons/icon-whatsapp.svg" alt="Whatsapp" />
                                            </a>
                                            <a href="#" @click.prevent="share('facebook')" class="facebook-link col-auto">
                                                <img src="/images/icons/icon-facebook.svg" alt="Facebook" />
                                            </a>
                                            <a href="#" @click.prevent="share('email')" class="email-link col-auto">
                                                <img src="/images/icons/icon-email.svg" alt="Email" />
                                            </a>
                                            <a href="#" @click.prevent="share('copy')" class="copy-link col-auto position-relative">
                                                <img src="/images/icons/icon-link.svg" alt="Link" />
                                            </a>
                                        </div>

                                        <div class="response-tags-container" v-if="selectedItem.kategorie">
                                            <hr />
                                            <h3 class="tincx-h5 mt-0 mb-2">{{ $t('generic.category') }}</h3>
                                            <div class="tag-container row">
                                                <span class="tag text-small mt-2 response-tags">{{ selectedItem.kategorie }}</span>
                                            </div>
                                        </div>
                                        <hr class="d-lg-none mb-0" />
                                    </div>
                                </div>
                                <div class="infos d-none d-lg-block" id="info-desktop" v-if="selectedItem && selectedItem.customer">
                                    <FlohTitleInfo :selectedItem="selectedItem" :buttonConfig="buttonConfig" />
                                </div>
                                <div id="info-mobile" class="d-lg-none position-relative mt-3" v-if="selectedItem">
                                    <FlohTitleInfo :selectedItem="selectedItem" :buttonConfig="buttonConfig" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Slick from './inc/Slick.vue'
import emitter from '../plugins/mitt'
import FlohTitleContent from './inc/FlohTitleContent.vue'
import FlohTitleInfo from './inc/FlohTitleInfo.vue'
import ContactButton from './inc/ContactButton.vue'
import MultiSelect from './inc/MultiSelect.vue'
import RangeSelect from './inc/RangeSelect.vue'

export default {
    name: 'FlohList',
    components: {
        Slick,
        FlohTitleContent,
        FlohTitleInfo,
        ContactButton,
        MultiSelect,
        RangeSelect
    },
    data() {
        return {
            loading: false,
            hideLoadMore: false,
            detailId: null,
            landingPage: {
                detailId: null,
                category: null,
            },
            showContactButtons: false,
            activeTab: 'main',
            descriptionReadMore: true,
            isTextClamped: false,
            advantagesReadMore: false,
            fetchStep: 20,

            filter: {
                category: null,
                price: null,
                district: null,
                search: null,
                sale_buy_change: null,
                price: {from: null, to: null},
                sort: 'new',
            },
            offers: null,
            count: null,

            prices: [],
            categories: [],
            types: [],
            equipment: [],
            districts: [],

            slickOptions: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                arrows: false,
                responsive: [
                    {
                        breakpoint: 639,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
        }
    },
    inject: ['$t', '$rtime', '$f'],
    computed: {
        filterCategory() {
            if(!this.filter || !this.filter.category) return null
            return this.categories.find(c => c.id == this.filter.category)
        },
        hasFilters() {
            var has = false
            Object.keys(this.filter).forEach((f) => {
                if (f == 'sort') return

                var key = this.filter[f]
                if (key != null && typeof key == 'object' && key.from == null && key.to == null) {
                    return
                }
                if (key != null && !Array.isArray(key)) {
                    has = true
                }
                if (key != null && Array.isArray(key) && key.length > 0) {
                    has = true
                }
            })
            return has
        },
        selectedItem() {
            if (!this.detailId || !this.offers) return null
            return this.offers.find((i) => i.id == this.detailId)
        },
        buttonConfig() {
            if (!this.selectedItem) return null
            let buttons = this.selectedItem.buttons || []
            if (!buttons.length) return null
            return {
                title: this.$t('fe.category.floh.button_contact'),
                buttons: buttons,
            }
        },
    },

    mounted: function () {
        this.checkTextClamped();
        window.addEventListener("resize", this.checkTextClamped)
        let parent = document.getElementsByClassName('v-list-component')
        if (parent.length && parent[0].dataset.detailId) {
            this.landingPage.detailId = parent[0].dataset.detailId
        }

        if (parent.length && parent[0].dataset.category) {
            var category = JSON.parse(parent[0].dataset.category)
            this.landingPage.category = category
            this.filter.category = category.id
        }

        //load filters
        var search = window.location.search ? new URLSearchParams(window.location.search) : null
        if (search) {
            var s,
                c,
                d,
                t = null
            if ((s = search.get('s'))) {
                this.filter.search = s
            }
            if ((c = search.get('floh_category'))) {
                this.filter.category = parseInt(c)
            }
            if ((t = search.getAll('floh_types[]'))) {
                this.filter.types = t
            }
            if ((d = search.get('districts'))) {
                this.filter.district = parseInt(d)
            }
        }

        emitter.on('searchbanner_search', (data) => {
            this.filter.category = null
            this.filter.search = null
            this.filter.district = null
            data.forEach((i) => {
                if (i.name == 's') {
                    this.filter.search = i.value || null
                }
                if (i.name == 'floh_category') {
                    this.filter.category = i.value || null
                }
                if (i.name == 'districts') {
                    this.filter.district = i.value || null
                }
            })
            //reload data on filter update
            this.loadData(false)
        })

        //initial load
        this.loadData(this.landingPage.detailId)

        // Listen on URL Change
        window.addEventListener('popstate', this.updateLink)
    },
    beforeUnmount() {
        window.removeEventListener('popstate', this.updateLink)
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.checkTextClamped);
    },
    watch: {
        filter: {
            handler(val) {
                this.loadData(false)
            },
            deep: true,
        },
        detailId: {
            handler(val) {},
        },
        selectedItem: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                this.checkTextClamped();
                });
            },
        },
        descriptionReadMore() {
            this.$nextTick(() => {
                this.checkTextClamped();
            });
        },
    },

    methods: {
        resetFilter: function () {
            window.location.href = '/flohmarkt/liste'
        },
        share: async function (method) {
            if (!this.selectedItem) return
            let text = $t('fe.category.floh.share_text')
            let shareUrl = this.selectedItem.url
            if (method == 'whatsapp') {
                var whatsappUrl = 'https://wa.me/?text=' + encodeURIComponent(text + shareUrl)
                window.open(whatsappUrl, '_blank')
            } else if (method == 'facebook') {
                var facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(shareUrl) + ''
                window.open(facebookUrl, '_blank')
            } else if (method == 'email') {
                var emailUrl = 'mailto:?subject=dolomitenmarkt.it&body=' + encodeURIComponent(text + shareUrl)
                window.open(emailUrl, '_blank')
            } else if (method == 'copy') {
                navigator.clipboard.writeText(shareUrl)
            }
        },
        loadMore: function () {
            if(this.hideLoadMore) return
            if (this.loading) return

            this.loading = true
            axios
                .get('/api/floh', {
                    params: {
                        step: this.fetchStep,
                        filter: this.filter,
                        ids: this.offers.map(i => i.id).join(',')
                    },
                })
                .then((response) => {
                    this.loading = false
                    this.count = response.data.count
                    if(response.data.offers.length < this.fetchStep) {
                        this.hideLoadMore = true
                    }
                    this.offers = [...this.offers, ...response.data.offers]
                })
                .catch((error) => {
                    console.log(error)
                    this.loading = false
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
        /*onMoreFilterSearch: function() {
            emitter.emit('more_filter_search')
        },*/
        onTab: function (tab) {
            emitter.emit('list_tab_change', tab)
        },
        loadData: function (loadId = null) {
            if (this.loading) return
            this.offers = null
            this.loading = true
            this.hideLoadMore = false
            this.detailId = null
            if(loadId === false){
                loadId = null
                window.history.pushState('floh_empty', '', '/flohmarkt/liste' + window.location.search)
            }
            axios
                .get('/api/floh', {
                    params: {
                        filter: this.filter,
                        step: this.fetchStep,
                        id: loadId,
                    },
                })
                .then((response) => {
                    emitter.emit('listing_page_data_loaded', true)
                    this.loading = false
                    this.offers = response.data.offers
                    this.count = response.data.count
                    if(response.data.offers.length < this.fetchStep) {
                        this.hideLoadMore = true
                    }
                    this.categories = response.data.categories
                    this.types = response.data.types
                    this.districts = response.data.districts
                    this.equipment = response.data.equipment
                    this.prices = response.data.prices

                    if (loadId) {
                        this.selectItem(
                            this.offers.find((i) => i.id == loadId),
                            false,
                        )
                        /*setTimeout(function() {
                            $('html, body').animate({scrollTop: $('#scroll-box').offset().top-150});
                        }, 1000)*/
                    } else {
                        //on larger viewports auto-select item
                        if ($(window).width() >= 768) {
                            this.$nextTick(() => {
                                if (this.offers.length && !this.detailId) {
                                    this.selectItem(this.offers[0], false)
                                }
                            })
                        }
                    }
                })
                .catch((error) => {
                    emitter.emit('listing_page_data_loaded', true)
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },

        selectItem: function (item, updateUrl = true) {
            this.descriptionReadMore = false
            this.advantagesReadMore = false

            if (item) {
                window.lastScrollPosition = $('html').scrollTop()
                this.detailId = item.id
                if (updateUrl) {
                    window.history.pushState('floh_' + item.id, '', item.url + window.location.search)

                    console.log('Update URL Tracking: '+item.url)
                    _paq.push(['setCustomUrl', item.url]);
                    _paq.push(['setDocumentTitle', item.title+" "+(item.customer ? item.customer.agency : '')+" | „Dolomiten“-Markt Online [VPW]"]);
                    _paq.push(['trackPageView']);
                }
            } else {
                //todo: - vermutlich ist dieser link nur responsive angezeigt
                //todo: wieder zum vorherigen scroll-position springen
                this.detailId = null
                window.history.pushState('floh_empty', '', '/flohmarkt/liste' + window.location.search)
            }

            emitter.emit('listing_page_detail', {
                id: this.detailId,
                initialLoad: false,
            })
        },

        checkTextClamped() {
            const element = this.$refs.responseDescription;
            if (element) {
                this.isTextClamped = element.scrollHeight > element.clientHeight;
            }
        },
        updateLink() {
            const listRegex = /\/flohmarkt\/[^\/]+(\/*e-([^\/]+)\/*)?$/

            emitter.emit('hide_all_contact_buttons', true)

            const path = location.pathname
            let item = this.offers[0]

            let match = path.match(listRegex)
            if (!match) {
                return;
            }
            if (match[2]) {
                const id = match[2] * 1
                item = this.offers.find((i) => i.id === id)
            }
            if (this.detailId === item.id) {
                return
            }
            this.selectItem(item, false)
        },
    },
}
</script>
