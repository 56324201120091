<template>
    <div class="select-container col-auto mt-2 mt-lg-0 position-relative">
        <input type="text" name="price" :value="displayedTitle" readonly="" data-default="Preis">
        <div class="select-box bg-white btn btn-ts text-left d-none">
            <div class="row-small align-items-end">
            <div class="col">
                <strong class="d-block text-small mb-2">{{ $t('fe.generic.range_select.from')}}</strong>
                <select class="popup-select" name="a"  v-model="value.from">
                    <option :value="null">{{ $t('fe.generic.range_select.unlimited')}}</option>
                    <option v-for="i in items.from" :value="i.id">{{ i.name }}</option>
                </select>
            </div>
            <div class="col-auto pb-2">-</div>
            <div class="col">
                <strong class="d-block text-small mb-2">{{ $t('fe.generic.range_select.to')}}</strong>
                <select class="popup-select" name="a"  v-model="value.to">
                    <option :value="null">{{ $t('fe.generic.range_select.unlimited')}}</option>
                    <option v-for="i in items.to" :value="i.id">{{ i.name }}</option>
                </select>
            </div>
            </div>
            <div class="row-small mt-2 align-items-center">
            <div class="col-5">
                <a href="#" @click.prevent="onReset" class="text-muted text-bold text-no-underline text-small reset-button">
                    <svg width="14.8" height="12.6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.8 12.6">
                        <path fill="#B2B2B2" d="M12.3,1.3C11.2,0.5,9.8,0,8.4,0C4.9,0,2.1,2.8,2.1,6.3H0l2.8,2.8l2.8-2.8H3.5c0-2.7,2.2-4.9,4.9-4.9
                c2.7,0,4.9,2.2,4.9,4.9c0,2.7-2.2,4.9-4.9,4.9c-1,0-2-0.3-2.9-0.9l-1,1c2.8,2.1,6.7,1.6,8.9-1.2C15.6,7.4,15.1,3.4,12.3,1.3z"></path>
                    </svg> {{ $t('fe.generic.range_select.reset')}}
                </a>
            </div>
            <div class="col-7">
                <a href="#" class="btn btn-primary text-bold text-uppercase w-100 text-small accept-button">{{ $t('fe.generic.range_select.close')}}</a>
            </div>
            </div>
        </div>
        </div>
</template>


<script>

export default {
    name: 'RangeSelect',
    data() {
        return {
            show: false,
            value: {from: null, to: null}
        }
    },
    props: {
        modelValue: {
            required: true,
            //default: {from: null, to: null}
        },
        items: {
            required: true,
            default: { from: [], to: []}
        },
        title: {
            required: true
        },
    },
    methods: {
        onReset: function() {
            this.value = {from: null, to: null}
            this.$emit('update:modelValue', this.value)
        }
    },
    watch: {
        'value.from': {
            handler(from) {
                if(from && this.value.to && from > this.value.to) {
                    this.value.to = null
                }
                this.$emit('update:modelValue', this.value)
            },
        },
        'value.to': {
            handler(to) {
                if(to && this.value.from && to < this.value.from) {
                    this.value.from = null
                }
                this.$emit('update:modelValue', this.value)
            },
        },
        'modelValue': {
            handler(v) {
                this.value = v
            },
            immediate: true
        },
    },
    computed: {
        displayedTitle: function () {
            const val = this.value
            if(!val.from && !val.to) return this.title
            if(!this.items.from || !this.items.to) return this.title
            const left = val.from ? this.items.from.find(i => i.id == val.from).name : $t('fe.generic.range_select.unlimited')
            const right = val.to ? this.items.to.find(i => i.id == val.to).name : $t('fe.generic.range_select.unlimited')
            return left + ' - '+right
        },
    },
}
</script>