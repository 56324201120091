<template>
    <div v-if="profileRedirectInfo">
        <div class="alert-warning alert" v-html="profileRedirectInfo.message">
        </div>

        <a class="btn btn-primary btn-small mt-2" v-if="profileRedirectInfo.url" :href="profileRedirectInfo.url">{{ profileRedirectInfo.buttonText }}</a>


        <template v-if="profileRedirectInfo.type=='job_sync'">

            <hr>


            <Form
                ref="form"
                novalidate
                @submit="onSubmit"
                @invalid-submit="onInvalid"
                @change="onChangeFormValues"
                class="form check-required-fields"
                v-slot="{ errors, values, meta }"
            >
                <Rating
                    :title="preferences.title"
                    :text="preferences.text2"
                    :preferences="preferences.list"
                    pagetype="profile"
                    :showAllSteps="true"
                    :company="true"
                    v-model="selectedUserRatings"
                />
                <hr />

                <button
                    :class="{ disabled: Object.keys(errors).length || !meta.valid }"
                    type="submit" class="ms-auto d-flex align-items-center justify-content-center mt-4 btn btn-primary btn-large px-3 btn-te"
                >
                        {{ editId ? 'JOB-Anzeige speichern' : 'JOB-Anzeige veröffentlichen' }}
                        <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                            <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
                    C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                            <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
                    c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
                    c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
                        </svg>
                </button>
            </Form>
        </template>

    </div>

    <Form
        v-else
        ref="form"
        novalidate
        @submit="onSubmit"
        @invalid-submit="onInvalid"
        @change="onChangeFormValues"
        class="form check-required-fields"
        v-slot="{ errors, values, meta }"
    >


        <div v-if="alertWarning">
            <div class="alert-warning alert">
                <strong>{{ alertWarning.title }}</strong><br><br>
                {{ alertWarning.message }}<br><br>

                <a class="btn btn-primary btn-bs btn-small mt-2" :href="alertWarning.url">{{ alertWarning.buttonText }}</a>

            </div>
        </div>

        <template v-if="editId">
            <h3 class="tincx-h5 mt-0 mb-0">Anzeige bearbeiten</h3>
        </template>

        <template v-else>
            <p class=" mt-0">
                Geben Sie hier die Details zu Ihrer Stellenanzeige an, um diese auf unserer Plattform zu veröffentlichen.
            </p>

            <h3 class="tincx-h5 mt-4 mb-0">Allgemeine Informationen</h3>
        </template>

        <div class="row-small">
            <label class="d-flex align-items-center mt-4">
                <input type="checkbox" name="active" v-model="active" /><span :class="{'text-success' : active, 'text-danger': !active}">{{  active ? 'Anzeige sichtbar' : 'Anzeige nicht sichtbar' }}</span>
                <Field type="hidden" name="active" :modelValue="active" />
            </label>

            <OffersPreview
                v-if="editId && formValues"
                :data="formValues"
                @onPreviewClick="onPreviewClick"
                type="job"
                class="mt-3"
                />


            <div class="col-12 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating">
                    <Field
                        @input="onChangeFormValues"
                        type="text"
                        name="title"
                        id="title"
                        class="form-control"
                        :label="$t('validation.attributes.title')"
                        :placeholder="`${$t('validation.attributes.title')}*`"
                        rules="required"
                        :class="{ 'is-invalid': errors.title }"
                    />
                    <label for="title">{{  $t('validation.attributes.title') }}*</label>
                    <ErrorMessage as="div" name="title" class="invalid-feedback" />
                </div>
            </div>

            <div class="col-12 mt-2 mt-sm-3 mt-md-2 mt-lg-3">
                <div class="form-floating-trix">
                    <Field
                        v-if="false"
                        as="textarea"
                        @input="onChangeFormValues"
                        class="form-control"
                        name="description"
                        style="height: 200px;"
                        placeholder="Stellenbeschreibung eingeben*"
                        :class="{ 'is-invalid': errors.description }"
                        rows="7"
                        rules="required" />
                    <label>Stellenbeschreibung eingeben*</label>

                    <VueTrix
                        placeholder="Stellenbeschreibung eingeben*"
                        :src-content="values.description"
                        @input="updateDescription"
                        inputName="description"
                        required
                    />

                    <ErrorMessage as="div" name="description" class="invalid-feedback" />
                </div>
            </div>


            <hr>
            <p class="mb-2 mt-0"><strong>Branche*</strong></p>
            <p class="mt-0 mb-0">Wählen Sie die Hautpbranche, welche für diese Stellenanzeige zutrifft..</p>
            <div class="col-12 mt-2">
                <div class="form-floating">
                    <Field
                        as="select"
                        :class="{ 'is-invalid': errors.job_category_id }"
                        rules="required"
                        name="job_category_id"
                        label="Branche"
                        class="form-control">
                        <option value="" disabled selected>Branche auswählen*</option>
                        <option v-for="(category, id) in options.categories" :key="id" :value="id">{{ category }}</option>
                    </Field>
                    <label>Branche</label>
                </div>
                <ErrorMessage as="div" name="job_category_id" class="invalid-feedback" />
            </div>

            <hr>
            <p class="mb-2 mt-0"><strong>Anstellungsart*</strong></p>
            <p class="mt-0 mb-0">Wählen Sie eine oder mehrere Anstellungsarten, welche für diese Stellenanzeige zutreffen.</p>
            <ErrorMessage as="div" name="types" class="invalid-feedback d-block" />
            <div class="col-12 mt-2 ">
                <div class="row">
                    <label class="d-flex align-items-center mt-2 mt-sm-1 col-12 col-lg-6" v-for="(v, k) in options.types">
                        <Field type="checkbox"
                            label="Anstellungsart"
                            rules="required"
                            :class="{ 'is-invalid': errors.types }"
                            @change="onChangeFormValues"
                            name="types"
                            :value="k" /><span>{{  v }}</span>
                    </label>
                </div>
            </div>

            <template v-if="options.customerLocations && options.customerLocations.length==0">
                <hr>
                <p class="mb-2 mt-0"><strong>Ort</strong></p>

                <p class=" mt-0">Wählen Sie hier den Arbeitsort für die Stellenausschreibung aus, falls zutreffend.</p>

                <div class="col-12">
                    <div class="form-floating">
                        <Field as="select" name="job_main_location" class="form-control">
                            <option value="" disabled selected>Arbeitsort auswählen</option>
                            <optgroup v-for="(places,k) in options.places" :key="k" :label="k">
                                <option v-for="place in places" :key="place.id" :value="place.id">{{ place.name }}</option>
                            </optgroup>
                        </Field>
                        <label>Arbeitsort</label>
                    </div>
                </div>
            </template>


            <template v-if="customer && !customer.private">
                <hr>

                <p class="mb-2 mt-0"><strong>Standorte / Filialen</strong></p>

                <p class=" mt-0">
                    Wählen Sie hier, für welche Standorte diese Stellenanzeige zutrifft.<br>Eine Mehrfachauswahl ist möglich.
                </p>


                <template v-if="options.customerLocations && options.customerLocations.length==0">
                    <div class="alert alert-warning">
                        Noch keine Standorte angelegt. Sie können in Ihrem Firmenprofil neue Standorte anlegen.<br><br>
                        <a class="btn btn-primary btn-bs btn-small mt-2" :href="customer.editUrl">Firmenprofil bearbeiten</a>
                    </div>
                </template>
                <div class="col-12" v-else>
                    <div class="row">
                        <Field type="checkbox" name="customerLocations" class="d-none" value="force-array-checkbox" />
                        <label class="d-flex align-items-center mt-2 mt-sm-1 col-12" v-for="(l, k) in options.customerLocations">
                            <Field type="checkbox" name="customerLocations" :value="l.id" /><span>{{  l.name }}&nbsp;&nbsp;({{ l.city }})</span>
                        </label>
                    </div>
                </div>

            </template>


        </div>

        <hr class="mt-4 mb-4" />

        <p class="my-2"><strong>Erforderliche Sprachkenntnisse</strong></p>
        <p class="my-2">Sie können auch mehrere Optionen wählen.</p>
        <label class="d-flex align-items-center mt-2 mt-sm-1" v-for="(v, k) in options.languages">
            <Field type="checkbox" name="languages" :value="k" /><span>{{  v }}</span>
        </label>
        <hr />

        <p class="my-2"><strong>Aufgaben des Kandidaten</strong></p>

        <p class=" mt-0">Beschreiben Sie hier die Aufgaben des Kandidaten.</p>


        <div id="tasks">
            <div class="d-flex flex-wrap mt-3 cloned-field" v-for="(task, taskIndex) in tasks" :key="task">
                <input v-model="tasks[taskIndex]" placeholder="Aufgabe eingeben" />
                <a href="#" @click.prevent="tasks.splice(taskIndex, 1)" class="ms-2 delete-field d-flex align-items-center justify-content-center">
                    <svg class="text-primary" width="17" height="21.8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21.8">
                        <path fill="currentcolor" d="M1.2,19.4c0,1.3,1.1,2.4,2.4,2.4h9.7c1.3,0,2.4-1.1,2.4-2.4V4.8H1.2v14.5Z"></path>
                        <polygon fill="currentcolor" points="12.7 1.2 11.5 0 5.4 0 4.2 1.2 0 1.2 0 3.6 17 3.6 17 1.2 12.7 1.2"></polygon>
                    </svg>
                </a>
            </div>

            <div class="d-flex flex-wrap mt-3 original-field">
                <input @keypress.enter.prevent="onTasksEnter" @change="onTasksEnter" placeholder="Aufgabe eingeben" />
                <a href="#" class="btn btn-primary text-nowrap ms-2 d-flex align-items-center px-3">+ Hinzufügen</a>
            </div>
        </div>
        <hr />
        <p class="my-2"><strong>Benefits</strong></p>

        <div class="row">
            <label class="d-flex align-items-center mt-2 mt-sm-1 col-12 col-lg-6" v-for="(v) in options.benefits" :key="v.id">
                <Field type="checkbox" name="benefits" :value="v.id" /><span>{{  v.name }}</span>
            </label>
        </div>

        <hr />
        <p class="my-2"><strong>Gehalt</strong></p>
        <p class="my-2">Geben Sie einen ungefähren Richtwert in € ein.</p>
        <div class="row-small mt-3">
            <div class="col-6 col-sm-4">
                <Field rules="numeric" type="text" name="salary_min_value" placeholder="Von" />
            </div>
            <div class="col-6 col-sm-4">
                <Field rules="numeric" type="text" name="salary_max_value" placeholder="Von" />
            </div>
        </div>
        <div class="row-small mt-3" v-show="customer && customer.external_form">
            <hr>
            <p class="mt-2 mb-3"><strong>Externes Kontaktformular</strong></p>
                <div class="col-12">
                    <Field type="text" name="external_form_url" placeholder="URL externes Kontaktformular" />
                </div>
                <div class="col-12 mt-2">
                    <label class="d-flex align-items-center mt-2 mt-sm-1 col-12">
                        <Field type="checkbox" name="external_form_only" :value="true" />
                        <span>Zeige ausschließlich das externe Kontaktformular an</span>
                    </label>
                </div>
        </div>
        <hr />
        <p class="mt-2 mb-3"><strong>Kontaktperson für diese Anzeige</strong></p>
        <label class="d-flex align-items-center mt-2 mt-sm-1 col-12">
            <Field type="checkbox" name="contact_person_show" :value="true" />
            <span>Kontaktperson für diese Anzeige anzeigen</span>
        </label>
        <div class="show-contact" v-if="values.contact_person_show">
            <p class="my-2 tincx-small text-muted">Bild hochladen (empfohlene Maße: 120 x 160px)</p>

            <FileUpload
                ref="fileUploadImages"
                v-model="contactPersonImages"
                accept=".jpg,.png,.jpeg"
                :multiple="false"
                :maxFiles="1"
                :imageIcons="true"
                :imagePreview="true"
                :imagePreviewContain="true"
            />

            <div class="row-small">
                <div class="col-6 mt-3">
                    <div class="form-floating">
                        <Field
                            type="text"
                            name="contact_person"
                            id="contact_person"
                            class="form-control"
                            placeholder="Namen eingeben*"
                            :label="'Kontaktperson '+$t('validation.attributes.name')"
                            rules="required"
                            :class="{ 'is-invalid': errors.contact_person }"
                        />
                        <label for="contact_person">{{ $t('validation.attributes.name') }}*</label>
                        <ErrorMessage as="div" name="contact_person" class="invalid-feedback" />
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <div class="form-floating">
                        <Field
                            type="text"
                            name="contact_position"
                            id="contact_position"
                            class="form-control"
                            placeholder="Position eingeben*"
                            label="Kontaktperson Position"
                            rules="required"
                            :class="{ 'is-invalid': errors.contact_position }"
                        />
                        <label for="contact_position">Position im Unternehmen*</label>
                        <ErrorMessage as="div" name="contact_position" class="invalid-feedback" />
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <div class="form-floating">
                        <Field
                            type="text"
                            name="contact_phone"
                            id="contact_phone"
                            class="form-control"
                            :label="'Kontaktperson '+$t('validation.attributes.phone')+'*'"
                            :placeholder="$t('validation.attributes.phone')"
                            rules="required"
                            :class="{ 'is-invalid': errors.contact_phone }"
                        />
                        <label for="contact_phone">{{  $t('validation.attributes.phone')  }}*</label>
                        <ErrorMessage as="div" name="contact_phone" class="invalid-feedback" />
                    </div>
                </div>
                <div class="col-6 mt-3">
                    <div class="form-floating">
                        <Field
                            type="text"
                            name="contact_email"
                            id="contact_email"
                            class="form-control"
                            :label="'Kontaktperson ' +$t('validation.attributes.email')"
                            :placeholder="$t('validation.attributes.email')+'*'"
                            rules="required|email"
                            :class="{ 'is-invalid': errors.contact_email }"
                        />
                        <label for="contact_email">{{  $t('validation.attributes.email')  }}*</label>
                        <ErrorMessage as="div" name="contact_email" class="invalid-feedback" />
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <Rating
            :title="preferences.title"
            :text="preferences.text2"
            :preferences="preferences.list"
            pagetype="profile"
            :showAllSteps="true"
            :company="true"
            v-model="selectedUserRatings"
        />
        <hr />

        <button
            :class="{ disabled: Object.keys(errors).length || !meta.valid }"
            type="submit" class="ms-auto d-flex align-items-center justify-content-center mt-4 btn btn-primary btn-large px-3 btn-te"
        >
                {{ editId ? 'JOB-Anzeige speichern' : 'JOB-Anzeige veröffentlichen' }}
                <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                    <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
            C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                    <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
            c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
            c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
                </svg>
        </button>

    </Form>
</template>

<script>
import axios from 'axios'
//import emitter from '../plugins/mitt'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'
import MapForm from '@/components/inc/form/MapForm.vue'
import FileUpload from '@/components/inc/form/FileUpload.vue'
import OffersPreview from '@/components/offers/inc/Preview.vue'
import emitter from '../../../plugins/mitt'
import Rating from '../../inc/form/Rating.vue'
import VueTrix from '@/components/inc/VueTrix.vue'

export default {
    name: 'FormJob',
    components: {
        Form,
        Field,
        ErrorMessage,
        MapForm,
        FileUpload,
        OffersPreview,
        Rating,
        VueTrix
    },
    inject: ['$t'],
    mounted: async function () {
        let parent = document.getElementById('v-offers-form-job')
        if (parent && parent.dataset.id) {
            this.editId = parent.dataset.id
        }
        this.loadData()
    },
    computed: {
        mainLocationName() {
            if (!this.formValues) return null
            if (this.formValues.customerLocations && this.formValues.customerLocations.length) {
                let location = this.options.customerLocations.find(i => i.id == this.formValues.customerLocations[0])
                return `${location.name} (${location.city})`
            }
            if(this.formValues.job_main_location) {
                var place = null;
                Object.keys(this.options.places).forEach(p => {
                    let tmp = this.options.places[p].find(t => t.id == this.formValues.job_main_location)
                    if(tmp) {
                        //place = `${tmp.name} (${tmp.district})`
                        place = `${tmp.name}`
                    }
                })
                return place
            }
            return null
        },
        preferences() {
            //todo: copied from JobAdvisor.vue, we need to refactor this ones
            return {
                title: this.$t('fe.job.preferences.title'),
                text: this.$t('fe.company_profile.preferences.text'),
                text2: this.$t('fe.company_profile.preferences.text2'),
                list: [
                    { title: this.$t('fe.job_advisor.preferences.list_company.0.title'), name: 'money' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.1.title'), name: 'career' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.2.title'), name: 'homeoffice' },
                    { title: this.$t('fe.job_advisor.preferences.list_company.3.title'), name: 'location' },
                ],
            }
        },
        offer: function () {
            return {
                ...this.$refs.form.values,
            }
        },
    },
    watch: {},
    methods: {
        updateDescription (event) {
            this.$refs.form.setFieldValue('description', event)
        },
        onPreviewClick: function() {
            if(this.active && this.options && this.options.viewUrl) {
                window.open(this.options.viewUrl, '_blank')
            }
        },
        onTasksEnter: function(event) {
            this.tasks.push(event.target.value)
            event.target.value=''
        },

        onNewFiles: function () {
            setTimeout(() => {
                this.onChangeFormValues()
            }, 100)
        },
        onChangeFormValues: function () {
            if (this.$refs.form) {
                console.log('onChangeFormValues FormJob', this.$refs.form.values)
                this.formValues = { ...this.$refs.form.values }
            } else {
                this.formValues = null
            }
            this.$emit('formValues', {...this.formValues, customer: this.customer, main_location_name: this.mainLocationName})
        },

        onInvalid: function ({ values, errors, results }) {
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        onSubmit: function (values) {
            Swal.fire({
                title: this.$t('popup.register.title'),
                text: this.$t('generic.loading'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            var data = {}
            if(this.editId && this.profileRedirectInfo && this.profileRedirectInfo.type=='job_sync') {
                data = {
                    selectedUserRatings: this.selectedUserRatings,
                    editType: 'job_sync'
                }
            } else {
                data = {
                    ...this.$refs.form.values,
                    selectedUserRatings: this.selectedUserRatings,
                    tasks: this.tasks,
                    contact_person_image: this.contactPersonImages
                }
            }

            axios
                .post(`/api/profile/offers/${this.type}/create${this.editId ? '/' + this.editId : ''}`, data)
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    if (this.editId) {
                        Swal.fire({
                            icon: 'success',
                            text: $t('generic.save_alert'),
                            didClose: () => window.scrollTo(0, 0),
                        })
                        this.loadData()
                        emitter.emit('profile_reload_offer_list', true)
                        return
                    }

                    Swal.close()
                    this.$emit('success', response.data)
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
        loadData: function () {
            if (this.loading) return
            this.loading = true
            var editUrlPostfix = ''
            if (this.editId) {
                editUrlPostfix = '/' + this.editId
            }
            axios
                .get(`/api/profile/offers/${this.type}/create${editUrlPostfix}`)
                .then(async (response) => {
                    this.loading = false

                    this.options = { ...response.data.options }
                    this.profileRedirectInfo = response.data.profileRedirectInfo
                    this.alertWarning = response.data.alertWarning
                    this.customer = response.data.customer
                    let formData = response.data.formData
                    if(formData.tasks) {
                        this.tasks = formData.tasks
                    }
                    if(formData.selectedUserRatings) {
                        this.selectedUserRatings = formData.selectedUserRatings
                    }
                    if(formData.contactPersonImages) {
                        this.contactPersonImages = formData.contactPersonImages
                    }
                    this.active = response.data.formData.active

                    setTimeout(() => {
                        if (this.$refs && this.$refs.form) {
                            this.$refs.form.resetForm({
                                values: formData,
                            })

                            this.onChangeFormValues()
                        }
                    }, 100)
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                    Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
                })
        },
    },
    data() {
        return {
            editId: null,
            active: true,
            type: 'job',
            profileRedirectInfo: null,
            alertWarning: null,
            success: false,
            options: {},
            formValues: null,
            customer: null,
            selectedUserRatings: {},


            contactPersonImages: [],
            tasks: []
        }
    },
}
</script>
