<template>
    <div class="bewertungen" v-if="this.rating >= 0">
        <svg
            v-for="index in 5"
            :key="index"
            :class="index <= rating ? 'text-gold' : 'text-muted'"
            width="14.7"
            height="14"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.7 14"
        >
            <path fill="currentcolor" d="M7.3,11.3l4.6,2.7-1.2-5.2,4-3.5-5.3-.5L7.3,0l-2.1,4.9L0,5.4l4,3.5-1.2,5.2,4.6-2.7h0Z" />
        </svg>
    </div>
</template>
<script>
export default {
    name: "ProfileRating",
    props: {
        rating: {
            type: Number,
            default: -1,
        }
    }
}
</script>
