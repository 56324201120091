<template>
    <Form v-if="!formSubmitted" class="form row-small complain" novalidate @submit="onSubmit" @invalid-submit="onInvalid" v-slot="{ errors, values, meta }">
        <div class="col-12 col-sm-6 mt-3">
            <Field
                type="text"
                name="firstname"
                v-model="firstname"
                :placeholder="`${$t('validation.attributes.firstname')}*`"
                rules="required"
                :class="{ 'is-invalid': errors.firstname }"
            />
            <ErrorMessage as="div" name="firstname" class="invalid-feedback" />
        </div>

        <div class="col-12 col-sm-6 mt-2 mt-sm-3">
            <Field
                type="text"
                name="lastname"
                v-model="lastname"
                :placeholder="`${$t('validation.attributes.lastname')}*`"
                rules="required"
                :class="{ 'is-invalid': errors.lastname }"
            />
            <ErrorMessage as="div" name="lastname" class="invalid-feedback" />
        </div>

        <div class="col-12 col-sm-6 mt-2 mt-sm-3">
            <Field
                type="email"
                name="email"
                autocomplete="email"
                v-model="email"
                :placeholder="`${$t('validation.attributes.email')}*`"
                rules="required"
                :class="{ 'is-invalid': errors.email }"
            />
            <ErrorMessage as="div" name="email" class="invalid-feedback" />
        </div>

        <div class="col-12 col-sm-6 mt-2 mt-sm-3">
            <Field
                type="text"
                name="phone"
                v-model="phone"
                :placeholder="`${$t('validation.attributes.phone')}`"
                rules=""
                :class="{ 'is-invalid': errors.phone }"
            />
            <ErrorMessage as="div" name="phone" class="invalid-feedback" />
        </div>

        <div class="col-12 mt-2 mt-sm-3">
            <div class="position-relative">
                <Field
                    as="select"
                    name="reason"
                    v-model="reason"
                    :placeholder="`${$t('validation.attributes.reason')}*`"
                    rules="required"
                    :class="{ 'is-invalid': errors.reason }"
                >
                    <option value="" disabled>{{$t('validation.attributes.reason')}}*</option>
                    <option v-for="(value, key) of JSON.parse(this.reasons)" :value="key">{{ value }}</option>
                </Field>
                <ErrorMessage as="div" name="reason" class="invalid-feedback" />
            </div>
        </div>

        <div class="col-12 mt-2 mt-sm-3">
            <div class="position-relative">
                <Field
                    as="textarea"
                    name="message"
                    v-model="message"
                    :placeholder="`${$t('validation.attributes.complain')}*`"
                    rules="required"
                    class="message-container"
                    rows="11"
                    :class="{ 'is-invalid': errors.message }"
                />
                <ErrorMessage as="div" name="message" class="invalid-feedback" />
            </div>
        </div>

        <Field as="hidden" name="url" v-model="url"></Field>

        <div class="col-12 mt-3">
            <div class="position-relative">
                <label class="d-flex align-items-center me-2 text-small">
                    <Field type="checkbox" v-model="privacy" :value="true" rules="required" name="privacy" />
                    <span v-html="$t('fe.application.privacy_text', { link: `* [<a target='_blank' href='/privacy'>${$t('fe.application.link')}</a>]` })"></span>
                </label>
            </div>
        </div>
        <div class="col-12 mt-3">
            <div class="position-relative">
                <label class="d-flex align-items-center me-2 text-small">
                    <Field type="checkbox" v-model="usage" :value="true" rules="required" name="usage" />
                    <span v-html="$t('fe.application.usage_text', { link: `* [<a target='_blank' href='/nutzungsbedingungen'>${$t('fe.application.link')}</a>]`, linkAgb: `* [<a target='_blank' href='/agb'>${$t('fe.application.link')}</a>]` })"></span>
                </label>
            </div>
        </div>

        <div class="col-12 mt-4">
            <button class="btn btn-primary btn-bs">{{ $t('fe.complain.send') }}</button>
        </div>
    </Form>
    <div v-else class="form row-small" >
        <p>{{ $t('fe.complain.success') }}.</p>
        <div class="col-12 mt-4">
            <a onclick="history.back()" class="btn btn-primary btn-bs">{{ $t('fe.generic.back') }}</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default {
    name: 'GenericComplain',
    components: { Form, Field, ErrorMessage },
    inject: ['$t'],
    methods: {
        onInvalid: function ({ values, errors, results }) {
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        onSubmit: function (values) {
            axios
                .post(this.submitUrl, { ...values })
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    Swal.close()
                    Swal.fire({
                        icon: 'success',
                        title: this.$t('fe.complain.success_title'),
                        text: this.$t('fe.complain.success_message'),
                    })
                    this.formSubmitted = true;
                    //TODO: where to redirect
                    //this.successMessage = response.data.message
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
    },
    data() {
        return {
            firstname: null,
            lastname: null,
            phone: null,
            email: null,
            reason: null,
            url: null,
            message: null,
            formSubmitted: false,
        }
    },
    props: {
        theme: String,
        userfirstname: String,
        userlastname: String,
        useremail: String,
        userphone: String,
        submitUrl: String,
        reasons: String,
    },
    mounted: function () {
        this.firstname = this.userfirstname
        this.lastname = this.userlastname
        this.email = this.useremail
        this.phone = this.userphone
        this.url = location.href
    },
}
</script>
