<template>
    <form>
        <div class="container">
            <Rating
                :title="preferences.title"
                :text="preferences.text"
                :preferences="preferences.list"
                pagetype="berater"
                @onFinishRating="onFinishRating"
                v-model="selectedUserRatings"
            />

            <template v-if="step >= 2">
                <hr />
                <h3 id="category-advisor" class="tincx-h4 mb-2">{{ $t('fe.job_advisor.category.title') }}</h3>
                <div v-if="categories.length" class="row-small categories-small">
                    <div
                        v-for="(category, index) in categories"
                        :key="index"
                        class="col-12 col-sm-6 col-md-4 col-xl-3 mt-3 px-2 position-relative"
                        @click.prevent="onChangeCategory(category.id)"
                    >
                        <input class="d-none" type="radio" v-model="selectedCategory" :value="category.id" />
                        <div
                            class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start btn-be btn w-100 bg-primary-light h-100 py-3 px-3 px-lg-4"
                        >
                            <img :src="`/images/icons/${category.icon}.svg`" :alt="category.title" />
                            <h4 class="mt-2 mb-0 text-lg-left w-100">{{ category.title }}</h4>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="step>=3">
                <hr />
                <h3 id="district-advisor" class="tincx-h4 mt-0 mb-4">{{ district.title }}</h3>
                <div class="map-svg input-map d-none d-sm-block">
                    <MapSvg :selectedDistrict="selectedDistrict" @districtChange="onDistrictChange" />
                </div>
                <div class="col-12 col-sm-6 col-md-12 col-lg-4 mt-2 mt-sm-3 form-floating d-block d-sm-none">
                    <select v-model="selectedDistrict" @change="onDistrictChange(selectedDistrict)" name="bezirk" class="form-control">
                        <option disabled selected>Bitte wählen</option>
                        <option value="pustertal">Pustertal</option>
                        <option value="wipptal">Wipptal</option>
                        <option value="eisacktal">Eisacktal</option>
                        <option value="bozen">Bozen</option>
                        <option value="burggrafenamt">Burggrafenamt</option>
                        <option value="vinschgau">Vinschgau</option>
                        <option value="saltenschlern">Salten- & Schlerngebiet</option>
                        <option value="unterland">Überetsch/Unterland</option>
                    </select>
                    <label>Bezirk</label>
                </div>
                <div class="pt-medium" id="submit-advisor" v-if="step >=4">
                    <button
                        :class="{disabled: count==null || count <= 0}"
                        @click.prevent="onSearchButtonClick"
                        :data-badge="count==0 ? 'Keine Jobs mit deinen Einstellungen gefunden!' : null"
                        class="btn btn-primary btn-large btn-te d-inline-flex align-items-center justify-content-center px-3 badge-text"
                    >
                        {{ $t('fe.job_advisor.show_results') }} <span class="ms-2">({{  count == null ? '...' : count }})</span>
                        <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                            <path
                                fill="#FFFFFF"
                                d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
                C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"
                            ></path>
                            <path
                                fill="#FFFFFF"
                                d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
                c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
                c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"
                            ></path>
                        </svg>
                    </button>
                </div>
    
            </template>
        </div>
    </form>
</template>
<script>
import Rating from '@/components/inc/form/Rating.vue'
import MapSvg from '@/components/inc/MapSvg.vue'
import axios from 'axios';
import debounce from "lodash/debounce"
import Swal from 'sweetalert2';

export default {
    name: 'JobAdvisor',
    components: { MapSvg, Rating },
    inject: ['$t'],
    data: () => {
        return { 
            step: 1,
            selectedCategory: null, 
            selectedDistrict: null, 
            selectedUserRatings: {},
            loading: false,
            count: null,

            categories: []
        }
    },
    mounted: function() {
        axios.get('/api/jobs/advisor').then((response) => {
            this.categories = response.data.categories
        })
        .catch((error) => {
            console.log(error)
            this.loading = false
            Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
        })
    },
    watch: {
        selectedCategory: function() {
            this.updateSearchCounter()            
        },
        selectedDistrict: function() {
            this.updateSearchCounter()            
        },
        selectedUserRatings: {
            handler: function() {
                this.updateSearchCounter()            
            },
            deep: true
        },
    },
    methods: {
        updateSearchCounter: function() {
            if(!this.selectedCategory) return
            if(!this.selectedDistrict) return
            if(Object.keys(this.selectedUserRatings).length!=4) return
            if(this.step<3) return
            this.count = null
            this.updateSearchCounterDebounced()            
        },
        updateSearchCounterDebounced: debounce(function(event) {
            this.search('count')
        }, 500),
        onSearchButtonClick: function() {
            this.search('button')
        },
        search(type) {
            if (this.loading) return
            this.loading = true
            if(type=='button') {
                Swal.fire({
                    text: this.$t('generic.loading'),
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading()
                    },
                });
            }

            axios.post('/api/jobs/advisor', {
                type: type,
                category: this.selectedCategory,
                district: this.selectedDistrict,
                rating: this.selectedUserRatings
            }).then((response) => {
                this.loading = false
                this.count = response.data.count
                if(type=='button') {
                    if(response.data.count==0) {
                        Swal.fire({
                            icon: 'info',
                            title: 'JOB-Berater',
                            text: 'Leider haben wir keine Jobs gefunden. Bitte passe die Suchkriterien an!',                            
                        });
                    } else {
                        window.location.href = response.data.url
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                this.loading = false
                Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
            })
        },
        onFinishRating() {
            if(this.step==1) {
                this.step = 2;
                setTimeout(() => {
                    $('html').animate(
                        {
                            scrollTop: $('#category-advisor').offset().top-$('header').height()-80,
                        }
                    )
                }, 500)
            }
        },
        onChangeCategory(cat) {
            this.selectedCategory = cat
            if(this.step==2) {
                this.step = 3;
                setTimeout(() => {
                    $('html').animate(
                        {
                            scrollTop: $('#district-advisor').offset().top-$('header').height()-80,
                        }
                    )
                }, 500)
            }
        },
        onDistrictChange(cat) {
            this.selectedDistrict = cat
            if(this.step==3) {
                this.step=4
                setTimeout(() => {
                    $('html').animate(
                        {
                            scrollTop: $('#submit-advisor').offset().top-$('header').height()-80,
                        }
                    )
                }, 500)
            }
        },
    },
    computed: {
        preferences() {
            return {
                title: this.$t('fe.job_advisor.preferences.title'),
                text: this.$t('fe.job_advisor.preferences.text'),
                // list: this.$t('fe.job_advisor.preferences.list'), // TODO should return a Array?!
                list: [
                    { title: '1. Wie wichtig ist dir das Gehalt?', name: 'money' },
                    { title: '2. Wie wichtig sind dir Karrierechancen?', name: 'career' },
                    { title: '3. Wie wichtig sind dir flexible Arbeitszeiten oder Home Office?', name: 'homeoffice' },
                    { title: '4. Wie wichtig ist dir, dass Dein Arbeitsort nah bei Deinem Wohnort liegt?', name: 'location' },
                ],
            }
        },
        district() {
            return {
                title: this.$t('fe.job_advisor.district.title'),
            }
        },
    },
}
</script>
