<template>
    <div
        v-if="type == 'floh'"
        class="card position-relative d-flex align-items-center first"
        style="order: unset"
        @click="onClick"
        :class="{ active: data.active !== false }"
    >
        <div class="offer-image">
            <div class="bg-muted d-flex align-items-center justify-content-center h-100">
                <img
                    v-if="data.files && data.files.length"
                    class="insert-image offer-image-cover"
                    :src="data.files[0].preview"
                    width="110"
                    height="40"
                    alt="Placeholder"
                    data-default="/images/logo-dolomitenmarkt.svg"
                />
                <img
                    v-else
                    class="insert-image"
                    src="/images/logo-dolomitenmarkt.svg"
                    width="110"
                    height="40"
                    alt="Placeholder"
                    data-default="/images/logo-dolomitenmarkt.svg"
                />
            </div>
            <span v-if="data.new" class="position-absolute badge">Neu</span>
        </div>
        <div class="offer-box">
            <span class="text-bold text-muted offer-subtitle insert-type" data-default="Art einfügen">{{
                data.type ? $t(`floh.entry.typeselect.${data.type}`) : 'Art'
            }}</span>
            <span class="offer-title text-bold mt-1 mb-1 mb-sm-2 insert-title opacity-3" data-default="Titel der Anzeige">{{
                data.title ? data.title : 'Titel der Anzeige'
            }}</span>
            <div class="d-flex justify-content-between">
                <span class="d-flex align-items-center text-ellipsis offer-item opacity-3" v-if="data.location">
                    <img src="/images/icons/icon-location.svg" alt="Location" width="15" height="17" class="me-1 d-block location-icon" />
                    <span class="text-ellipsis insert-place" data-defaut="Ort">{{ data.location ? data.location : 'Ort' }}</span>
                </span>
                <span class="tincx-p text-bold ps-2 text-nowrap offer-price" v-if="data.price">
                    <span class="insert-price opacity-3" data-defaut="Preis"
                        >{{ data.price ? `${$f.number(data.price)} €` : 'k.A.' }} {{ data.price_detail == 'NEGOTIABLE' ? 'VB' : '' }}</span
                    >
                    <span class="insert-verhandelbar"></span>
                </span>
            </div>
        </div>
    </div>
    <div
        v-if="type == 'job'"
        @click="onClick"
        class="card position-relative mt-4"
        style="order: unset"
        :class="{ active: data.active !== false }"
    >
        <div
            v-if="data.customer && data.customer.logo"
            class="job-logo d-flex align-items-center justify-content-center position-absolute text-no-underline"
        >
            <img :src="data.customer.logo" alt="Logo" />
        </div>
        <span class="position-absolute badge" v-if="data.new">Neu</span>
        <div class="tincx-p" v-if="data.customer">{{ data.customer.agency }}</div>
        <span class="tincx-h5 d-block my-3 mt-lg-1 mb-lg-3 insert-title opacity-3">{{ data.title || 'Titel der Anzeige' }}</span>
        <div class="d-flex justify-content-between job-info align-items-center mb-2 mb-xl-0">
            <span class="d-flex align-items-center tincx-small opacity-3">
                <img src="/images/icons/icon-location.svg" alt="Location" class="me-1 location-icon" />
                <span class="insert-place" data-default="Ort">{{ data.main_location_name || 'Ort' }}</span>
            </span>
            <span class="d-flex align-items-center tincx-small opacity-3 max-width">
                <img src="/images/icons/icon-time.svg" alt="Job Art" class="me-1" />
                <span class="insert-art" data-default="Art">
                    {{ data.types && data.types.length ? data.types.map((t) => $t(`jobs.type.${t}`)).join(', ') : 'Art' }}
                </span>
            </span>
        </div>
        <p class="description mt-2 insert-description opacity-3" data-default="Beschreibung der Anzeige" v-html="shownDescription"></p>
    </div>

    <div
        v-if="type == 'auto'"
        @click="onClick"
        class="card position-relative d-flex align-items-center"
        style="order: unset"
        :class="{ active: data.active !== false }"
    >
        <div class="offer-image">
            <div class="bg-muted d-flex align-items-center justify-content-center h-100">
                <img
                    v-if="data.files && data.files.length"
                    class="insert-image offer-image-cover"
                    :src="data.files[0].preview"
                    width="110"
                    height="40"
                    alt="Placeholder"
                    data-default="/images/logo-dolomitenmarkt.svg"
                />
                <img
                    v-else
                    class="insert-image"
                    src="/images/logo-dolomitenmarkt.svg"
                    width="110"
                    height="40"
                    alt="Placeholder"
                    data-default="/images/logo-dolomitenmarkt.svg"
                />
            </div>
            <span class="position-absolute badge" v-if="data.new">Neu</span>
        </div>
        <div class="offer-box">
            <div v-if="data.customer" class="offer-subtitle text-underline">{{ data.customer.agency }}</div>
            <span class="offer-title text-bold opacity-3 insert-title" data-default="Titel der Anzeige">{{
                data.title || 'Titel der Anzeige'
            }}</span>
            <div class="d-flex justify-content-between">
                <span class="d-flex align-items-center text-ellipsis offer-item opacity-3"
                    ><img src="/images/icons/icon-km.svg" alt="Kilometerstand" class="me-1 d-block location-icon" /><span
                        class="text-ellipsis insert-kilometerstand"
                        data-default="km"
                        >{{ data.km }} km</span
                    ></span
                >

                <span class="d-flex align-items-center text-ellipsis offer-item opacity-3">
                    <img src="/images/icons/icon-calendar.svg" alt="Erstzulassung" class="me-1 d-block location-icon" />
                    <span class="text-ellipsis">
                        {{ data.first_registration ? formatMonthYear(data.first_registration) : '00/0000' }}
                    </span>
                </span>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <span class="d-flex align-items-center text-ellipsis offer-item opacity-3">
                    <img src="/images/icons/icon-location.svg" alt="Location" class="me-1 d-block location-icon" />
                    <span class="text-ellipsis insert-place" v-if="data.customer" data-default="Ort">{{
                        data.customer.city || 'Ort'
                    }}</span>
                </span>
                <span class="tincx-p text-bold ps-2 text-nowrap offer-price insert-price opacity-3" data-default="Preis"
                    >{{ data.price ? $f.number(data.price) : 'Preis' }} €</span
                >
            </div>
        </div>
    </div>

    <div
        v-if="type == 'immo'"
        @click="onClick"
        class="card position-relative d-flex align-items-center"
        style="order: unset"
        :class="{ active: data.active !== false }"
    >
        <div class="offer-image">
            <div class="bg-muted d-flex align-items-center justify-content-center h-100">
                <img
                    v-if="data.files && data.files.length"
                    class="insert-image offer-image-cover"
                    :src="data.files[0].preview"
                    width="110"
                    height="40"
                    alt="Placeholder"
                    data-default="/images/logo-dolomitenmarkt.svg"
                />
                <img
                    v-else
                    class="insert-image"
                    src="/images/logo-dolomitenmarkt.svg"
                    width="110"
                    height="40"
                    alt="Placeholder"
                    data-default="/images/logo-dolomitenmarkt.svg"
                />
            </div>
            <span class="position-absolute badge" v-if="data.new">Neu</span>
        </div>
        <div class="offer-box">
            <div v-if="data.customer" class="offer-subtitle text-underline">{{ data.customer.agency }}</div>
            <span class="offer-title text-bold opacity-3 insert-title" data-default="Titel der Anzeige">{{
                data.title || 'Titel der Anzeige'
            }}</span>
            <div class="d-flex justify-content-between">
                <span class="d-flex align-items-center text-ellipsis offer-item opacity-3">
                    <img src="/images/icons/icon-flaeche.svg" alt="Fläche" class="me-1 d-block location-icon" />
                    <span class="text-ellipsis insert-grundflaeche" data-default="Fläche">{{ data.area ? `${$f.number(data.area)} m²` : 'Fläche' }}</span>
                </span>
                <span class="d-flex align-items-center text-ellipsis offer-item opacity-3">
                    <img src="/images/icons/icon-zimmer.svg" alt="Zimmer" class="me-1 d-block location-icon" />
                    <span class="text-ellipsis insert-zimmer" data-default="Zimmer">{{ data.rooms || 'Zimmer' }}</span>
                </span>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <span class="d-flex align-items-center text-ellipsis offer-item opacity-3">
                    <img src="/images/icons/icon-location.svg" alt="Location" class="me-1 d-block location-icon" />
                    <span class="text-ellipsis insert-place" data-default="Ort">
                        {{ data.location || 'Ort' }}
                    </span>
                </span>
                <span class="tincx-p text-bold ps-2 text-nowrap offer-price insert-price opacity-3" data-default="Preis">{{ data.price ? $f.number(data.price) : 'Preis' }} €</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OffersPreview',
    inject: ['$t', '$f'],
    props: {
        data: {
            type: Object,
            default: {},
        },
        type: {
            type: String,
            default: null,
        },
    },
    methods: {
        onClick: function () {
            this.$emit('onPreviewClick')
        },
        formatMonthYear: function (val) {
            if (!val) return null
            let x = val.split('-')
            return x[1] + '/' + x[0]
        },
    },
    computed: {
        shownDescription() {
            var data = this.data
            return data.description ? data.description.replace('\n', '<br>') : null
        },
    },
}
</script>
<style lang="css" scoped>
.offer-image img.offer-image-cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
</style>
