<template>
    <div class="info-content pt-3 pt-lg-0 pb-3">
       <div class="contact-buttons position-relative active">
          <div class="active-buttons position-absolute opens-bottom">
             <a href="/user-form.php?id=1#anfrage" data-link="/user-form.php?id=" class="response-standard_contact d-flex mt-2 btn btn-primary justify-content-center btn-large btn-bs">Kontaktformular</a><a href="tel:#" class="d-flex mt-2 btn btn-primary justify-content-center btn-large btn-bs">Anrufen</a><a href="mailto:#" class="d-flex mt-2 btn btn-primary justify-content-center btn-large btn-bs">E-Mail senden</a>
             <a href="#" class="d-flex align-items-center justify-content-center mt-2 btn btn-primary btn-large btn-bs hide-contact bg-primary-muted">
                <svg class="me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                   <path fill="#FFFFFF" d="M10.4,20.8c5.7,0,10.4-4.7,10.4-10.4S16.2,0,10.4,0S0,4.7,0,10.4v0C0,16.2,4.7,20.8,10.4,20.8z M10.4,2
                      c4.6,0,8.4,3.8,8.4,8.4c0,4.6-3.8,8.4-8.4,8.4S2,15,2,10.4C2,5.8,5.8,2,10.4,2z"></path>
                   <path fill="#FFFFFF" d="M5.6,11.2C5.6,11.2,5.6,11.2,5.6,11.2l3.1,3.1c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-1.4-1.4h5.9
                      c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6L10.1,8c0.4-0.4,0.4-1,0-1.4C9.9,6.4,9.6,6.3,9.4,6.3S8.9,6.4,8.7,6.6l-3,3c0,0,0,0,0,0.1
                      c-0.2,0.2-0.3,0.4-0.3,0.7C5.2,10.7,5.4,11,5.6,11.2z"></path>
                </svg>
                Zurück
             </a>
          </div>
          <a href="#" class="d-flex align-items-center justify-content-center mt-2 btn btn-primary btn-large btn-te show-contact">Kontakt aufnehmen</a>
       </div>
       <div class="contact-buttons-direct d-none active">
          <a href="chat.php" class="d-flex align-items-center justify-content-center btn btn-primary btn-large btn-te">Kontakt aufnehmen</a>
       </div>
    </div>
 </template>

<script>
export default {
    name: 'JobCandidateInfo',
    props: ['selectedItem'],
    inject: ['$t', '$f'],
    computed: {
        private: function() {
            return this.selectedItem && this.selectedItem.customer.type =='private'
        }
    }
}
</script>