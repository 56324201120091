<template>
    <div class="dashboard-profile-box bg-white p-3">
        <!-- Name und Verifizierungsstatus -->
        <p class="tincx-h5 mt-0 mb-1 align-items-center d-flex">
            {{ name }} <span style="font-weight: normal;font-size: 80%" class=" ms-2">({{ private ? 'Privat' : 'Firma' }})</span>

            <span v-if="verified" class="ms-1" data-tooltip="Verifiziert">
                <img src="/images/icons/icon-verified.svg" />
            </span>
        </p>

        <!-- Bewertung TODO -->
        <ProfileRating :rating="rating" />

        <!-- Kontaktinformationen -->
        <div class="d-flex align-items-end justify-content-between">
            <div>
                <div class="d-flex align-items-center mt-2" v-if="email">
                    <img src="/images/icons/icon-mail.svg" alt="Icon Email" class="me-2 mt-1" />
                    <a class="text-no-underline hover-opacity" :href="`mailto:${email}`">
                        {{ email }}
                    </a>
                </div>
                <div v-if="phone" class="d-flex align-items-center mt-3">
                    <img src="/images/icons/icon-phone.svg" alt="Icon Phone" class="me-3" />
                    <a class="text-no-underline hover-opacity" :href="`tel:${phone.replace(/ /g, '').replace('+', '00')}`">
                        {{ phone }}
                    </a>
                </div>
                <div class="d-flex align-items-center mt-2" v-if="!private && profile.workers">
                    <img src="/images/icons/icon-user.svg" class="me-2 mt-1" />
                    <a class="text-no-underline hover-opacity">
                        {{ profile.workers }} Mitarbeiter
                    </a>
                </div>
                <div class="d-flex align-items-center mt-2" v-if="!private && profile.city">
                    <img src="/images/icons/icon-map.svg" class="me-2 mt-1" />
                    <a class="text-no-underline hover-opacity">
                        {{ profile.city }}
                    </a>
                </div>
            </div>

            <a v-if="editButton" href="/profile/edit" class="text-small text-muted">{{ $t('fe.generic.modify') }}</a>
        </div>
    </div>
</template>
<script>
import ProfileRating from '@/components/inc/profile/ProfileRating.vue'

export default {
    name: 'Dashboard',
    components: { ProfileRating },
    inject: ['$t'],
    methods: {},
    computed: {
        firstname: function() {
            return this.profile.firstname
        },
        lastname: function() {
            return this.profile.firstname
        },
        rating: function() {
            return this.profile.rating
        },
        phone: function() {
            return this.profile.phone
        },
        email: function() {
            return this.profile.email
        },
        verified: function() {
            return this.profile.verified
        },
        private: function() {
            return this.profile.private
        },
        name: function() {
            return this.profile.name
        }
    },
    props: {
        profile: Object,
        editButton: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
