<template v-if="customer">
    <span class="tincx-small text-muted">{{ $t('fe.application.customer') }}</span>
    <p class="tincx-h5 mt-2 mb-1 response-user_name">{{ customer.display_name }}</p>
    <ProfileRating :rating="customer.rating"></ProfileRating>

    <p class="d-flex align-items-center mt-2 mb-0 response-offers">
        <img src="/images/icons/icon-rakete.svg" alt="Offers" class="me-2" />
        <a :href="customer.url">
            {{ transChoice('fe.application.' + this.entryType + '.entry_online', customer.active_entries, { value: customer.active_entries }) }}
        </a>
    </p>

    <!--    <p class="d-flex align-items-center mt-3 mb-0 inserat-melden-container">-->
    <!--        <img src="/images/icons/icon-flag.svg" alt="Offers" class="me-2" />-->
    <!--        <a href="" class="text-muted inserat-melden">-->
    <!--            <strong>{{ $t('fe.generic.report_ad') }}</strong>-->
    <!--        </a>-->
    <!--    </p>-->
</template>
<script>
import ProfileRating from '@/components/inc/profile/ProfileRating.vue'
import { transChoice } from 'laravel-vue-i18n'

export default {
    name: 'ContactUser',
    methods: { transChoice },
    components: { ProfileRating },
    props: {
        customer: {
            type: Object,
            default: null,
        },
        entryType: {
            type: String,
            default: null,
        },
    },
}
</script>
