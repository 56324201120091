<template>
    <div class="d-md-flex bg-white chat-container">
        <div class="sidebar d-flex justify-content-between d-md-block p-md-2">
            <div
                v-for="menuEntry, index in this.menu"
                class="filter-applications position-relative more"
                :key="index"
            >
                <a
                    @click.prevent="onTabClick(menuEntry.type)"
                    :href="`/profile/${this.theme}/chat`"
                    class="sidebar-item d-flex flex-wrap justify-content-center justify-content-md-start align-items-center text-no-underline text-nowrap"
                    :class="{ active: this.openTab === menuEntry.type }"
                >
                    <div class="icon-box mb-1 mb-md-0 me-md-2">
                        <svg
                            v-if="menuEntry.icon === 'paperplane'"
                            width="18.2"
                            height="15.8"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18.2 15.8"
                        >
                            <path
                                fill="currentcolor"
                                d="M17.8,0c-.1,0-.2,0-.3,0L.3,6.6c-.3.1-.4.4-.3.7,0,.1.2.3.3.3l4.8,1.9v5.9c0,.3.2.5.5.5.2,0,.4-.1.5-.3l2-4,4.9,3.6c.2.2.6.1.7-.1,0,0,0-.1,0-.2C18.4,0,18.2.7,18.2.6c0-.3-.1-.6-.4-.6ZM5.6,8.5l-3.6-1.4L14,2.5l-8.3,5.9ZM7.5,10.5c0,0,0-.2-1.3,2.6v-3.7l7.3-5.2c-6.3,6.6-5.9,6.3-6,6.3ZM13.1,13.9l-4.3-3.2,7.7-8.2-3.5,11.3Z"
                            />
                        </svg>
                        <svg
                            v-if="menuEntry.icon === 'email'"
                            width="19.1"
                            height="13.4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 19.1 13.4"
                        >
                            <path
                                fill="currentcolor"
                                d="M17.4,0H1.7C.8,0,0,.8,0,1.7v10.1c0,.9.8,1.7,1.7,1.7h15.8c.9,0,1.7-.8,1.7-1.7V1.7C19.1.8,18.4,0,17.4,0ZM17.2,1.1l-6.5,6.4c-.7.7-1.7.7-2.4,0,0,0,0,0,0,0L1.9,1.1h15.3ZM1.1,11.5V1.9l4.8,4.8L1.1,11.5ZM1.9,12.3l4.8-4.8.8.8c1.1,1.1,2.9,1.1,4,0h0s.8-.8.8-.8l4.8,4.8H1.9ZM18,11.5l-4.8-4.8,4.8-4.8v9.6Z"
                            />
                        </svg>
                        <svg
                            v-if="menuEntry.icon === 'trash'"
                            width="13.6"
                            word-spacing="16.8"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 13.6 16.8"
                        >
                            <path
                                fill="currentcolor"
                                d="M12.3,2.1h-2.9v-.5C9.4.7,8.7,0,7.9,0h-2.1c-.9,0-1.6.7-1.6,1.6v.5H1.3c-.7,0-1.3.6-1.3,1.3v1.8c0,.3.2.5.5.5h.3l.5,9.5c0,.8.7,1.5,1.6,1.5h8c.8,0,1.5-.7,1.6-1.5l.5-9.5h.3c.3,0,.5-.2.5-.5v-1.8c0-.7-.6-1.3-1.3-1.3ZM5.2,1.6c0-.3.2-.5.5-.5h2.1c.3,0,.5.2.5.5v.5h-3.1v-.5ZM11.3,15.2c0,.3-.2.5-.5.5H2.8c-.3,0-.5-.2-.5-.5l-.5-9.5h9.9l-.5,9.5ZM12.6,4.7H1v-1.3c0-.1.1-.3.3-.3h11c.1,0,.3.1.3.3v1.3Z"
                            ></path>
                            <path
                                fill="currentcolor"
                                d="M6.8,14.8h0c.3,0,.5-.2.5-.5v-6.3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v6.3c0,.3.2.5.5.5Z"
                            ></path>
                            <path
                                fill="currentcolor"
                                d="M9.5,14.8h0c.3,0,.5-.2.5-.5v-6.3c0-.3-.2-.5-.5-.5-.3,0-.5.2-.5.5h0v6.3c0,.3.2.5.5.5Z"
                            ></path>
                            <path
                                fill="currentcolor"
                                d="M4.2,14.8h0c.3,0,.5-.2.5-.5v-6.3c0-.3-.2-.5-.5-.5s-.5.2-.5.5v6.3c0,.3.2.5.5.5Z"
                            ></path>
                        </svg>

                        <svg
                            v-if="menuEntry.icon === 'archive'"
                            width="17.9"
                            height="17.9"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 17.9 17.9"
                        >
                            <path
                                fill="currentcolor"
                                d="M17.9,5.6s0,0,0-.1c0,0,0,0,0-.1,0,0,0,0,0,0L15.6.8C15.3.3,14.8,0,14.3,0H3.6c-.6,0-1.1.3-1.3.8L0,5.4s0,0,0,0c0,0,0,.2,0,.3v10c0,1.3,1,2.3,2.3,2.3h13.3c1.3,0,2.3-1,2.3-2.3V5.6s0,0,0,0ZM3.5,1.4c0,0,.1-.1.2-.1h10.6c0,0,.2,0,.2.1l1.8,3.6H1.6l1.8-3.6ZM11.9,11.9h0s-2.5,2.5-2.5,2.5c-.2.2-.6.2-.9,0l-2.5-2.5c-.2-.3-.2-.7,0-.9.2-.2.6-.2.8,0l1.4,1.4v-3.5c0-.3.3-.6.6-.7.3,0,.6.3.7.6,0,0,0,0,0,0v3.5l1.4-1.4c.3-.2.7-.2.9,0,.2.2.2.6,0,.8Z"
                            />
                        </svg>
                    </div>
                    <span class="d-none d-md-inline-block">
                        {{ menuEntry.title }}
                    </span>
                    <span class="d-inline-block d-md-none">
                        {{ menuEntry.title_short ?? menuEntry.title }}
                    </span>
                </a>
                <a
                    href="#"
                    @click.prevent="toogleCustomerFilter()"
                    class="toggle-agencies position-absolute d-none d-md-flex align-items-center text-no-underline"
                    data-filter="applications"
                >
                    <strong class="ms-2">{{ menuEntry.unread_count }}</strong>
                            <svg  v-show="currentCompanies.length > 1" class="ms-2 arrow" width="11.6" height="7.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.6 7.1">
                                <path
                                    fill="currentcolor"
                                    d="M.4,2.2l4.5,4.5c.5.5,1.2.5,1.7,0l4.5-4.5c.2-.2.4-.5.4-.9,0-.3-.1-.7-.3-.9-.2-.2-.5-.4-.9-.4h0c-.3,0-.6.1-.9.4l-3.6,3.6L2.1.4c-.2-.2-.5-.4-.9-.4h0C.9,0,.6.2.3.4c-.5.5-.5,1.3,0,1.8Z"
                                />
                            </svg>
                </a>
                <div class="agencies agencies-applications" v-show="showCustomerFilter">
                    <template v-if="currentCompanies.length > 1">
                    <div class="pb-3">
                        <a v-for="company in currentCompanies" :key="company.id" :class="'mt-2 d-flex align-items-center justify-content-center text-no-underline'+ (company.customer_id === filteredCustomer ? ' active': '') "
                            @click="setFilteredCustomer(company.customer_id)"
                            :data-id="company.id">
                            <img
                                v-if="company.logo"
                                :src="company.logo"
                                alt="Logo"
                            />
                            <strong v-else>{{ company.display_name }}</strong>
                        </a>
                    </div>
                    </template>
                </div>
            </div>
        </div>
        <ChatList
            v-if="openView === 'list'"
            :menu="this.activeMenuTab"
            :theme="this.theme"
            :filteredCustomer="this.filteredCustomer"
            @trashChat="this.trashChat"
            @archiveChat="this.archiveChat"
            @replaceUrl="this.replaceUrl"
        />
        <ChatItem
            v-if="openView === 'item'"
            :type="openChat.type"
            :entryId="openChat.entryId"
            :entryType="openChat.entryType"
            :chatId="openChat.chatId"
            @messageSent="this.messageSent"
            @trashChat="this.trashChat"
            @archiveChat="this.archiveChat"
            @replaceUrl="this.replaceUrl"
        />
    </div>
</template>

<script>
import ChatList from '@/components/inc/chat/ChatList.vue'
import ChatItem from '@/components/inc/chat/ChatItem.vue'

const chatItemRegex = /\/profil\/([^\/]+)\/chat\/(e|c)-([^\/]+)\/*(.+)?$/
const chatListRegex = /\/profil\/([^\/]+)\/chat\/*$/

export default {
    name: 'Chat',
    components: { ChatItem, ChatList },
    data() {
        return {
            baseUrl: `/profil/${this.theme}/chat`,
            menu: [],
            openTab: null,
            openChat: {
                type: null,
                entryType: null,
                entryId: null,
                chatId: null,
            },
            openView: 'list', // "list" or "item"
            filteredCustomer: null,
            showCustomerFilter: false
        }
    },
    created() {
        this.fetchMenu()
    },
    mounted() {
        window.addEventListener('popstate', this.updateOpenChat)
    },
    beforeUnmount() {
        window.removeEventListener('popstate', this.updateOpenChat)
    },
    methods: {
        async fetchMenu() {
            try {
                const response = await fetch(`/api/profil/${this.theme}/chat`)
                const data = await response.json()
                this.menu = data.menu
                if (this.menu.length && this.openTab === null) {
                    this.openTab = this.menu[0].type
                }
                this.unreadTotal()
                this.updateOpenChat()
            } catch (error) {
                console.error('Fehler beim Laden des Menüs:', error)
            }
        },
        updateOpenChat() {
            const path = location.pathname

            let match = path.match(chatListRegex)
            if (match) {
                this.resetOpenChat()
                this.openView = 'list'
                return
            }
            match = path.match(chatItemRegex)
            if (match) {
                this.openChat.type = match[1]
                this.openChat.entryType = match[2]
                this.openChat.entryId = parseInt(match[3], 10)
                this.openChat.chatId = match[4] ? parseInt(match[4], 10) : null
                this.openView = 'item'
                if(this.openChat.entryType == 'c'){
                    this.openTab = this.menu[1].type
                }

                return
            }
            this.resetOpenChat()
        },
        changeTabBadge(unreadCount) {
            const activeTab = $('.type-tabs li a.active')
            const badges = activeTab.find('.badge-counter')
            if(unreadCount == 0) {
                badges.addClass('d-none')
            } else {
                badges.removeClass('d-none')
            }
            badges.text(unreadCount)
        },
        onTabClick(tabSlug) {
            this.openTab = tabSlug
            this.replaceUrl(this.baseUrl)
            this.fetchMenu()
        },
        toogleCustomerFilter(){
            this.showCustomerFilter = !this.showCustomerFilter
            const sideBar = $('.sidebar-item.active').closest('.filter-applications')
            if(!this.showCustomerFilter){
                sideBar.removeClass('open')
            } else {
                sideBar.addClass('open')
            }
            
        },
        setFilteredCustomer(customerId){
            if(this.filteredCustomer == customerId){
                this.filteredCustomer = null
            } else {
                this.filteredCustomer = customerId
            }
        },
        async archiveChat(chatId) {
            try {
                const response = await fetch(`/api/profil/${this.theme}/chat/${chatId}/archive`, {
                    method: 'POST',
                })
            } catch (error) {
                console.error('Fehler beim Absenden', error)
            }
            this.replaceUrl(this.baseUrl)
            this.fetchMenu()
            this.resetOpenChat()
        },
        async trashChat(chatId) {
            alert('TODO Trash Chat: ' + chatId)
            this.replaceUrl(this.baseUrl)
            this.resetOpenChat()
        },
        async messageSent() {
            return this.fetchMenu()
        },
        replaceUrl(newUrl) {
            window.history.pushState('', '', newUrl)
            this.updateOpenChat()
        },
        resetOpenChat() {
            this.openChat.type = null
            this.openChat.entryType = null
            this.openChat.entryId = null
            this.openChat.chatId = null
        },
        unreadTotal(){
            let unreadTotal = 0
            this.menu.forEach((menuEntry) => unreadTotal += menuEntry.unread_count)
            this.changeTabBadge(unreadTotal)
        }
    },
    computed: {
        activeMenuTab() {
            const openMenu = this.menu.find((menuEntry) => menuEntry.type === this.openTab)
            if (!openMenu) {
                return []
            }
            return openMenu
        },
        currentCompanies(){
            const customers = this.activeMenuTab.items.map(item => ({
                customer_id: item.customer.id,
                display_name: item.customer.display_name,
                logo: item.customer.logo
            })).filter((value, index, self) =>
                index === self.findIndex(c => c.customer_id === value.customer_id)
            );
            return customers;
        },
    },
    props: {
        theme: String,
    },
}
</script>
