<template>
    <div id="changepassword" class="popup-container d-flex align-items-center justify-content-center text-box" :class="{'d-none': !show}">
        <div class="popup-box bg-white px-3 px-sm-4 px-lg-5 pt-5 pb-4 position-relative">

            <a href="#" @click.prevent="$emit('close')" class="close-popup position-absolute">
                <img src="/images/icons/icon-close-popup.svg" title="Close"></a>

            <p class="my-0 tincx-h4 mb-3 mt-0">{{ $t('fe.profile.change_password') }}</p>

            <Form
                ref="form"
                novalidate
                @submit="onSubmit"
                @invalid-submit="onInvalid"
                class="form"
                v-slot="{ errors, values, meta }"
            >

                <div class="mt-2 position-relative">
                    <Field
                        :type="showPassword ? 'text' : 'password'"
                        name="current_password"
                        rules="required|min:5"
                        autocomplete="current-password"
                        :placeholder="`${$t('validation.attributes.current_password')}*`"
                        :class="{'is-invalid': errors.current_password}"
                    />
                    <ErrorMessage
                        as="div"
                        name="current_password"
                        class="invalid-feedback" />
                    <a 
                        href="#"
                        tabindex="-1"
                        @click="showPassword = !showPassword"
                        :class="{show: !showPassword}"
                        class="show-password position-absolute">
                        <svg width="18.8" height="17.4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.8 17.4">
                            <path fill="#B2B2B2" d="M9.4,6.1L9.4,6.1C8,6.1,6.8,7.3,6.8,8.7c0,0,0,0,0,0c0,1.4,1,2.6,2.4,2.6c0.1,0,0.1,0,0.2,0
    c1.4,0,2.6-1,2.6-2.4c0-0.1,0-0.1,0-0.2c0-1.4-1.1-2.6-2.5-2.6H9.4z"/>
                            <path fill="#B2B2B2" d="M9.4,2.3C5.2,2.3,1.5,4.8,0,8.7c2,5.2,7.9,7.7,13.1,5.7c2.6-1,4.7-3.1,5.7-5.7C17.3,4.9,13.5,2.3,9.4,2.3z
    M9.4,13L9.4,13C7,13,5.1,11.1,5.1,8.7S7,4.4,9.4,4.4s4.3,1.9,4.3,4.3l0,0C13.7,11.1,11.8,13,9.4,13C9.4,13,9.4,13,9.4,13z"/>
                            <rect x="8.4" y="-2.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.4044 9.1977)" fill="#B2B2B2" class="unvisible-password" width="2"
                                height="22.6"/>
                        </svg>
                    </a>
                </div>

                <div class="mt-2 position-relative">
                    <Field
                        :type="showPassword ? 'text' : 'password'"
                        name="new_password"
                        :rules="{required: true, min: 8, regex1: /[A-Z]/, regex2: /[a-z]/, regex3: /[0-9]/ }"
                        autocomplete="none"
                        :placeholder="`${$t('validation.attributes.new_password')}*`"
                        :class="{'is-invalid': errors.new_password}"
                    />
                    <ErrorMessage
                        v-if="false"
                        as="div"
                        name="new_password"
                        class="invalid-feedback" />

                    <div class="invalid-feedback" v-if="errors.new_password">
                        Das Password ist erforderlich und muss aus mindestens 8 Zeichen, einem Großbuchstaben, einem Kleinbuchstaben und einer Zahl bestehen!
                    </div>
                    <a 
                        href="#"
                        tabindex="-1"
                        @click="showPassword = !showPassword"
                        :class="{show: !showPassword}"
                        class="show-password position-absolute">
                        <svg width="18.8" height="17.4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.8 17.4">
                            <path fill="#B2B2B2" d="M9.4,6.1L9.4,6.1C8,6.1,6.8,7.3,6.8,8.7c0,0,0,0,0,0c0,1.4,1,2.6,2.4,2.6c0.1,0,0.1,0,0.2,0
    c1.4,0,2.6-1,2.6-2.4c0-0.1,0-0.1,0-0.2c0-1.4-1.1-2.6-2.5-2.6H9.4z"/>
                            <path fill="#B2B2B2" d="M9.4,2.3C5.2,2.3,1.5,4.8,0,8.7c2,5.2,7.9,7.7,13.1,5.7c2.6-1,4.7-3.1,5.7-5.7C17.3,4.9,13.5,2.3,9.4,2.3z
    M9.4,13L9.4,13C7,13,5.1,11.1,5.1,8.7S7,4.4,9.4,4.4s4.3,1.9,4.3,4.3l0,0C13.7,11.1,11.8,13,9.4,13C9.4,13,9.4,13,9.4,13z"/>
                            <rect x="8.4" y="-2.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.4044 9.1977)" fill="#B2B2B2" class="unvisible-password" width="2"
                                height="22.6"/>
                        </svg>
                    </a>
                </div>

                <div class="mt-2 position-relative">
                    <Field
                        :type="showPassword ? 'text' : 'password'"
                        name="new_password_confirmation"
                        rules="required|confirmed:@new_password"
                        autocomplete="none"
                        :placeholder="`${$t('validation.attributes.new_password_confirmation')}*`"
                        :class="{'is-invalid': errors.new_password_confirmation}"
                    />
                    <ErrorMessage
                        as="div"
                        name="new_password_confirmation"
                        class="invalid-feedback" />
                    <a 
                        href="#"
                        tabindex="-1"
                        @click="showPassword = !showPassword"
                        :class="{show: !showPassword}"
                        class="show-password position-absolute">
                        <svg width="18.8" height="17.4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.8 17.4">
                            <path fill="#B2B2B2" d="M9.4,6.1L9.4,6.1C8,6.1,6.8,7.3,6.8,8.7c0,0,0,0,0,0c0,1.4,1,2.6,2.4,2.6c0.1,0,0.1,0,0.2,0
    c1.4,0,2.6-1,2.6-2.4c0-0.1,0-0.1,0-0.2c0-1.4-1.1-2.6-2.5-2.6H9.4z"/>
                            <path fill="#B2B2B2" d="M9.4,2.3C5.2,2.3,1.5,4.8,0,8.7c2,5.2,7.9,7.7,13.1,5.7c2.6-1,4.7-3.1,5.7-5.7C17.3,4.9,13.5,2.3,9.4,2.3z
    M9.4,13L9.4,13C7,13,5.1,11.1,5.1,8.7S7,4.4,9.4,4.4s4.3,1.9,4.3,4.3l0,0C13.7,11.1,11.8,13,9.4,13C9.4,13,9.4,13,9.4,13z"/>
                            <rect x="8.4" y="-2.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.4044 9.1977)" fill="#B2B2B2" class="unvisible-password" width="2"
                                height="22.6"/>
                        </svg>
                    </a>
                </div>

                <button class="my-3 btn btn-large btn-general btn-te w-100 text-uppercase d-flex align-items-center justify-content-center">
                    {{ $t('fe.profile.change_password') }}
                    <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                        <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
	C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                        <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
	c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
	c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
                    </svg>
                </button>

            </Form>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate'
import emitter from '../../plugins/mitt';
export default {
    name: 'PopupChangePassword',
    props: ['show'],
    inject: ['$t'],
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            showPassword: false,
        }
    },
    methods: {
        onInvalid: function ({ values, errors, results }) {
            let html =
                '<ul>' +
                Object.values(errors)
                    .map((e) => '<li>' + e + '</li>')
                    .join('') +
                '</ul>'
            Swal.fire({
                title: this.$t('generic.error'),
                html: html,
                icon: 'error',
            })
        },
        onSubmit: function (values) {
            Swal.fire({
                text: this.$t('generic.loading'),
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })

            axios
                .post('/api/profile/pw-change', { ...values })
                .then((response) => {
                    console.log(response)
                    if (response.data.error) {
                        Swal.fire(this.$t('generic.error'), response.data.error, 'error')
                        return
                    }

                    //Swal.close()
                    Swal.fire({
                        icon: 'success',
                        text: response.data.success
                    })
                    this.$emit('close')
                    this.$refs.form.resetForm()
                })
                .catch((error) => {
                    console.log(error)
                    if (error.status == 422) {
                        var errors = error.response.data.errors
                        var errorBag = {}
                        for (var field in errors) {
                            errorBag[field] = errors[field].join(', ')
                        }
                        this.$refs.form.setErrors(errorBag)

                        let html =
                            '<ul>' +
                            Object.values(errorBag)
                                .map((e) => '<li>' + e + '</li>')
                                .join('') +
                            '</ul>'
                        Swal.fire({
                            title: this.$t('generic.error'),
                            icon: 'error',
                            html: html,
                        })
                        return
                    }
                    Swal.fire(this.$t('generic.error'), this.$t('generic.error_try_again_later'), 'error')
                })
        },
    }
}
</script>
