<template>
    <div class="mainbar text-box d-xl-flex">
        <div class="chat-box position-relative">
            <div class="chat-title text-no-underline d-flex align-items-center">
                <a
                    :href="`/profil/${this.type}/chat/`"
                    @click.prevent="$emit('replace-url', `/profil/${this.type}/chat/`)"
                    class="back-link d-flex d-md-none align-items-center tincx-p text-muted text-bold text-no-underline"
                >
                    <svg class="me-1" width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                        <path
                            fill="currentcolor"
                            d="M7.75,15.5c4.27,0,7.75-3.48,7.75-7.75S12.02,0,7.75,0,0,3.48,0,7.75s3.48,7.75,7.75,7.75ZM7.75,1.29c3.58,0,6.46,2.88,6.46,6.46s-2.88,6.46-6.46,6.46S1.29,11.33,1.29,7.75,4.17,1.29,7.75,1.29Z"
                        ></path>
                        <path
                            fill="currentcolor"
                            d="M6.46,10.53c.3.3.7.3.89,0s.3-.7,0-.89l-1.29-1.29h4.77c.4,0,.6-.3.6-.6s-.3-.6-.6-.6h-4.77l1.29-1.29c.3-.3.3-.7,0-.89s-.3-.2-.5-.2-.3,0-.5.2l-2.29,2.29h0c-.1.1-.2.3-.2.5s2.58,2.78,2.58,2.78Z"
                        ></path>
                    </svg>
                    {{ $t('fe.generic.back') }}
                </a>

                <div v-if="customer" class="logo-box">
                    <img
                        v-if="customer.type === 'private'"
                        width="20"
                        height="20"
                        src="/images/icons/icon-privatperson.svg"
                        alt="Icon Privatperson"
                    />
                    <img v-else-if="customer.logo" :src="customer.logo" :alt="'Logo ' + customer.display_name" />
                    <strong v-else class="text-muted text-center">{{ customer.display_name }}</strong>
                </div>
                <p v-if="entry" class="ms-2 ms-xs-3 m3-0 mx-md-2 mx-lg-3 text-ellipsis">
                    <strong>{{ entry.title }}</strong>
                </p>

                <div v-if="this.chatId" class="justify-content-end mt-1 d-flex ms-auto action-box">
                    <a
                        v-if="!this.archived"
                        href="#"
                        @click.prevent="$emit('archive-chat', this.chatId)"
                        class="action-button text-no-underline d-flex align-items-center"
                    >
                        <img src="/images/icons/icon-archiv.svg" alt="Icon Archiv" />
                        <span>{{ $t('fe.generic.archive') }}</span>
                    </a>
                    <!-- <a
                        href="#"
                        @click.prevent="$emit('trash-chat', this.chatId)"
                        class="me-1 me-md-0 ms-3 ms-lg-4 action-button text-no-underline d-flex align-items-center"
                    >
                        <img src="/images/icons/icon-trash.svg" alt="Icon Reply" />
                        <span>{{ $t('fe.generic.delete') }}</span>
                    </a> -->
                </div>
            </div>

            <div class="chat" ref="chatContainer" >
                <template v-for="message in this.messages">
                    <small class="d-block text-center text-muted">{{ message.date }}</small>
                    <p :class="{ mychat: !message.other, yourchat: message.other }" v-html="message.text"></p>
                </template>
            </div>

            <template v-if="!this.archived">
                <Form v-if="this.canWrite()" class="d-flex align-items-center chat-input position-absolute" ref="form" novalidate @submit="postMessage">
                    <Field as="textarea"
                        v-on:keydown.enter.exact="onEnter"
                        name="message"
                        rows="3"
                        class="col search-text"
                        :placeholder="chatId != null ? $t('fe.chat.message_placeholder') : $t('fe.chat.message_new_placeholder')"

                        />

                    <button type="submit" class="ms-2 btn btn-bs btn-primary d-flex align-items-center justify-content-center">
                        <span class="loader loader-sm" v-if="loading"></span>
                        <svg v-if="!loading" width="24.9" height="24.9" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.9 24.9">
                            <path
                                fill="#fff"
                                d="M24.7,11.7c-.2-.4-.5-.7-.8-.8h0S2,.1,2,.1C1.3-.2.5,0,.1.8,0,1,0,1.2,0,1.4h0c0,.2,0,.4,0,.6l2.1,8.4c0,.2.3.4.5.4l9.2,1c.3,0,.6.4.6.7,0,.3-.3.5-.6.6l-9.2,1c-.2,0-.4.2-.5.4L0,22.9c0,.2,0,.4,0,.5h0c0,.8.6,1.4,1.4,1.4.2,0,.4,0,.6-.1l21.9-10.7c.9-.4,1.3-1.5.8-2.4Z"
                            />
                        </svg>
                    </button>
                </Form>
                <div v-else class="d-flex align-items-center chat-input position-absolute">
                    <template v-if="this.accepted_state == null">
                        <a @click="changeState(0)" class="btn btn-bs btn-primary ms-2 bg-immo"> {{$t('fe.chat.decline')}}</a>
                        <a @click="changeState(1)" class="btn btn-bs btn-general ms-2 bg-auto"> {{$t('fe.chat.accept')}}</a>
                    </template>
                    <template v-else>

                        <template v-if="this.requesting_job == false">
                            <p class="alert alert-warning btn-bs ms-2">{{ $t('fe.chat.declined_info') }}</p><hr>
                            <a  @click="changeState(1)" class="btn btn-bs btn-primary ms-2 bg-auto"> {{$t('fe.chat.accept')}}</a>
                        </template>
                        <template v-else>
                            <p class="alert alert-warning btn-bs ms-2">{{ $t('fe.chat.declined_info_customer') }}</p><hr>
                        </template>
                    </template>
                </div>
            </template>


        </div>

        <div v-if="entry && (customer || job_candidate)" class="info">
            <template v-if="job_candidate">
                <div class="mb-4">
                <h5 class="mt-0 mb-3">{{ job_candidate.name }}</h5>
                <span class="d-flex mb-2 align-items-center response-ort-container" v-if="job_candidate.preferred_workplaces.length">
                    <img width="15" height="17" src="/images/icons/icon-location.svg" alt="Location" class="me-1 d-block"><span class="response-ort tincx-small">{{ job_candidate.preferred_workplaces.join(', ')}}</span>
                </span>
                <span class="text-ellipsis mb-2 d-flex align-items-center response-arbeitszeitmodell-container" v-if="job_candidate.types.length">
                    <img width="14.23" height="14.23" src="/images/icons/icon-time.svg" alt="Job Arbeitszeitmodell" class="me-1 d-block">
                    <span class="response-arbeitszeitmodell tincx-small text-ellipsis">{{ job_candidate.types.join(', ')}}</span>
                </span>
                <span class="d-flex mb-2 align-items-center response-language-container" v-if="job_candidate.languages.length">
                    <img width="14.6" height="16.4" src="/images/icons/icon-language.svg" alt="Job Language" class="me-1 d-block">
                    <span class="response-language tincx-small">{{ job_candidate.languages.join(', ') }}</span>
                </span>
                <br>
                <a v-if="job_candidate.url" :href="job_candidate.url"  target="_blank" class="btn btn-general btn-bs text-uppercase">Bewerber ansehen</a>
                <template v-if="job">
                    <h5 class="mt-5 mb-3">Anfrage für Job</h5>
                    <p v-if="job.company_url"><a :href="job.company_url" target="_blank" >{{ job.company }}</a></p>
                    <p v-else>{{ job.company }}</p>
                    <a :href="job.url" target="_blank" >{{ job.title }}</a>
                </template>
                </div>
            </template>
            <template v-if="entry.type!='jobCandidate'">
            <h5 class="mt-0 mb-3">{{ $t(`fe.category.${entry.type}.entry_description`) }}</h5>
            <p v-html="entry.description"></p>
            <a :href="entry.url" target="_blank" class="btn align-items-center d-flex text-start btn-transparent">
                {{ $t('fe.generic.learn_more') }}
                <svg width="15.6" height="15.6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.6 15.6">
                    <path
                        fill="#231f20"
                        d="M7.8,0C3.5,0,0,3.5,0,7.8s3.5,7.8,7.8,7.8,7.8-3.5,7.8-7.8S12.1,0,7.8,0ZM7.8,14.3c-3.6,0-6.5-2.9-6.5-6.5S4.2,1.3,7.8,1.3c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5Z"
                    ></path>
                    <path
                        fill="#231f20"
                        d="M11.4,7.3s0,0,0,0l-2.3-2.3c-.3-.3-.7-.3-.9,0s-.3.7,0,.9l1.3,1.3h-4.8c-.4,0-.6.3-.6.6s.3.6.6.6h4.8l-1.3,1.3c-.3.3-.3.7,0,.9s.3.2.5.2.3,0,.5-.2l2.3-2.3s0,0,0,0c.1-.1.2-.3.2-.5s0-.4-.2-.5Z"
                    ></path>
                </svg>
            </a>
            </template>
            <template v-if="customer">
                <div v-if="customer.type === 'private'" class="mt-3">
                    <ContactUser :customer="customer" :entryType="entry.type" />
                </div>
                <div v-else-if="customer.type === 'business'">
                    <h3 class="tincx-h5 mt-4">{{ $t(`fe.request.${entry.type}.contact_person`) }}</h3>

                    <div class="d-block d-xs-flex d-xl-block">
                        <div v-if="customer.logo" class="contact-image me-xs-3 me-xl-0 mb-3 mb-xs-0 mb-xl-3">
                            <a v-if="customer.url" :href="customer.url" target="_blank">
                                <img :src="customer.logo" />
                            </a>
                            <template v-else>
                                <img :src="customer.logo" />
                            </template>
                        </div>
                        <div>
                            <p class="mt-0">
                                <strong>{{ customer.display_name }}</strong>
                                <br />
                                <!--                            Abteilung -->
                            </p>
                            <p class="my-0">
                                <a :href="`mailto:${customer.email}`">{{ customer.email }}</a>
                                <br />
                                Tel. <a :href="`tel:${customer.phone.replace(' ', '').replace('+', '00')}`">{{ customer.phone }}</a>
                            </p>
                        </div>
                    </div>
                    <template v-if="job">
                        <h5 class="mt-4 mb-3">Anfrage für Job</h5>
                        <p v-if="job.company_url"><a :href="job.company_url" target="_blank" >{{ job.company }}</a></p>
                        <p v-else>{{ job.company }}</p>
                        <a :href="job.url" target="_blank" >{{ job.title }}</a>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import ContactUser from '@/components/inc/ContactUser.vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { required } from '@vee-validate/rules'
import Swal from 'sweetalert2'

export default {
    name: 'ChatItem',
    components: { Form, Field, ContactUser },
    data() {
        return {
            entry: null,
            customer: null,
            job_candidate: null,
            messages: [],
            requesting_job: null,
            accepted_state: null,
            archived: true,
            loading:false,
            jobId: null,
            job: null
        }
    },
    created() {
        this.fetchMessages()
    },
    methods: {
        required,
        async fetchMessages() {
            const params = new URLSearchParams(window.location.search);
            this.jobId = params.get('j');
            try {
                let url = `/api/profil/${this.type}/chat/${this.entryType}-${this.entryId}/${this.chatId ?? ''}`;
                if(this.jobId){
                    url = url+'?j='+this.jobId
                }
                const response = await fetch(url)
                const data = await response.json()
                if(data.redirect_url){
                    window.location.href = data.redirect_url;
                }
                this.entry = data.entry
                this.customer = data.customer
                this.job_candidate = data.job_candidate
                this.job= data.job
                this.messages = data.messages
                this.requesting_job = data.requesting_job
                this.accepted_state = data.accepted_state
                this.archived = data.archived

                //scroll to bottom
                this.$nextTick(() => {
                    this.scrollToBottom();
                });
            } catch (error) {
                Swal.fire('Fehler', 'Fehler beim Laden des Chats:', 'error')
            }
        },

        async changeState(state){
            try {
                const response = await fetch(`/api/profil/${this.type}/chat/${this.entryType}-${this.entryId}/${this.chatId}/changeState/${state}`, {
                    method: 'POST',
                })
                const data = await response.json()
                this.entry = data.entry
                this.customer = data.customer
                this.job_candidate = data.job_candidate
                this.messages = data.messages
                this.requesting_job = data.requesting_job
                this.accepted_state = data.accepted_state
            } catch (error) {
                Swal.fire('Fehler', this.$t('generic.error_loading'), 'error')
            }
            this.$emit('message-sent')
        },
        onEnter: function(event) {
            event.preventDefault();

            let message = this.$refs.form.values.message
            if(message) {
                message = message.trim()
            }
            if(message && message.length) {
                this.postMessage(this.$refs.form.values, {resetForm: () => this.$refs.form.reset() })
            }
        },
        async postMessage(form, { resetForm }) {
            this.loading = true
            if (!form.message) {
                this.loading = false
                return false
            }
            try {
                let url = `/api/profil/${this.type}/chat/${this.entryType}-${this.entryId}${this.chatId ? `/${this.chatId}` : ''}${this.jobId ? '?j='+this.jobId : ''}`

                const response = await fetch(url, {
                    method: 'POST',
                    redirect: 'manual',
                    body: JSON.stringify(form),
                })
                const data = await response.json()
                this.entry = data.entry
                this.customer = data.customer
                this.messages = data.messages
                if(!this.chatId && data.id){
                    //TODO: working but performance can be improved
                    window.location.href = `/profil/${this.type}/chat/${this.entryType}-${this.entryId}/${data.id}`
                }
                resetForm()
                this.$nextTick(() => {
                    this.scrollToBottom();
                });
                this.loading = false
            } catch (error) {
                console.log(error)
                this.loading = false
                Swal.fire('Fehler', this.$t('generic.error_try_again_later'), 'error')
            }
            this.$emit('message-sent')
        },

        canWrite(){
            if(this.requesting_job == true && this.accepted_state != false){
                return true
            } else if(this.accepted_state == true){
                return true
            }
            return false
        },

        scrollToBottom() {
            const container = this.$refs.chatContainer;
            container.scrollTop = container.scrollHeight;
        }
    },
    props: {
        chatEntries: {
            type: Array,
            default: [],
        },
        type: {
            type: String,
            required: true,
        },
        entryId: {
            type: Number,
            required: true,
        },
        entryType: {
            type: String,
            required: true,
        },
        chatId: {
            type: Number,
            default: null,
        },
    },
}
</script>
