<template>
    <div id="resetemail" class="popup-container d-flex align-items-center justify-content-center text-box" :class="{'d-none': !show}">
        <div class="popup-box bg-white px-3 px-sm-4 px-lg-5 pt-5 pb-4 position-relative">

            <a href="#" 
                @click.prevent="hide"
                class="close-popup position-absolute">
                <img src="/images/icons/icon-close-popup.svg">
            </a>

            <a 
                href="#"                
                @click="backToLogin"
                class="text-bold d-flex align-items-center tincx-small text-no-underline text-muted hover-opacity step-back position-absolute">
                    <svg class="me-1" width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                        <path fill="currentcolor"
                            d="M7.75,15.5c4.27,0,7.75-3.48,7.75-7.75S12.02,0,7.75,0,0,3.48,0,7.75s3.48,7.75,7.75,7.75ZM7.75,1.29c3.58,0,6.46,2.88,6.46,6.46s-2.88,6.46-6.46,6.46S1.29,11.33,1.29,7.75,4.17,1.29,7.75,1.29Z"></path>
                        <path fill="currentcolor"
                            d="M6.46,10.53c.3.3.7.3.89,0s.3-.7,0-.89l-1.29-1.29h4.77c.4,0,.6-.3.6-.6s-.3-.6-.6-.6h-4.77l1.29-1.29c.3-.3.3-.7,0-.89s-.3-.2-.5-.2-.3,0-.5.2l-2.29,2.29h0c-.1.1-.2.3-.2.5s2.58,2.78,2.58,2.78Z"></path>
                    </svg>
                Zurück
            </a>

            <p v-if="!resetToken" class="mb-0 tincx-h4 mt-2 mb-2">{{ $t('popup.login.password_reset') }}</p>

            <div class="alert alert-success d-flex align-items-center" v-if="successMessage">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"  class="bi flex-shrink-0 me-2">
                    <path fill="currentColor" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                </svg>
                <span v-html="successMessage"></span>
            </div>


            <template v-else-if="resetToken">
                <p class="my-0 tincx-h4 mb-3 mt-0">{{ $t('popup.password_reset.password_reset_do') }}</p>
                <p class="">{{ $t('popup.password_reset.password_reset_do_subtitle') }}</p>
                <Form 
                    class="form"
                    ref="form"
                    novalidate
                    @submit="onSubmitReset"
                    @invalid-submit="onInvalid"
                    v-slot="{errors}">

                    <div class="position-relative mt-2">
                        {{ $t('validation.attributes.email') }}: {{  username }}
                    </div>

                    <div class="position-relative mt-4">
						<Field
                            :type="showPassword ? 'text' : 'password'"
                            name="password"
                            :rules="{required: true, min: 8, regex1: /[A-Z]/, regex2: /[a-z]/, regex3: /[0-9]/ }"
                            autocomplete="current-password"
                            :placeholder="`${$t('validation.attributes.password')}*`"
                            :class="{'is-invalid': errors.password}"
                        />
                        <ErrorMessage
                            v-if="false"
                            as="div"
                            name="password"
                            class="invalid-feedback" />
                        <div class="invalid-feedback" v-if="errors.password">
                            Das Password ist erforderlich und muss aus mindestens 8 Zeichen, einem Großbuchstaben, einem Kleinbuchstaben und einer Zahl bestehen!
                        </div>
                    </div>

                    <div class="position-relative mt-2">
						<Field
                            :type="showPassword ? 'text' : 'password'"
                            name="password_confirmation"
                            rules="required|confirmed:@password"
                            autocomplete="current-password"
                            :placeholder="`${$t('validation.attributes.password_confirmation')}*`"
                            :class="{'is-invalid': errors.password_confirmation}"
                        />
                        <ErrorMessage
                            as="div"
                            name="password_confirmation"
                            class="invalid-feedback" />
                    </div>

                    <button class="my-3 btn btn-large btn-general btn-te w-100 text-uppercase d-flex align-items-center justify-content-center">
                        {{  $t('popup.password_reset.password_reset_button') }}
                        <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                            <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
            C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                            <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
            c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
            c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
                        </svg>
                    </button>
                </Form>
            </template>

            <template v-else>
                <p class="">{{ $t('popup.password_reset.subtitle') }}</p>
                <Form 
                    class="form"
                    ref="form"
                    novalidate
                    @submit="onSubmit"
                    @invalid-submit="onInvalid"
                    v-slot="{errors}">


                    <Field
                        type="email" 
                        autocomplete="email"
                        v-model="username"
                        name="username"
                        :placeholder="`${$t('popup.password_reset.username_email')}*`"
                        rules="required|email"
                        :class="{'is-invalid': errors.username}"
                    />
                    <ErrorMessage
                        as="div"
                        name="username"
                        class="invalid-feedback" />

                    <button :class="{disabled: Object.keys(errors).length }" class="mt-2 mb-3 btn btn-large btn-general btn-te w-100 text-uppercase d-flex align-items-center justify-content-center">
                        {{ $t('popup.password_reset.button') }}
                        <svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                            <path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
                                C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
                            <path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
                                c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
                                c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
                        </svg>
                    </button>
                </Form>
            </template>

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import emitter from '../plugins/mitt';
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
  name: 'PasswordReset',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  inject: ['$t'],
  mounted: function() {
    emitter.on('popup', (val) => {
        this.show = val=='password_reset' ? true : false
        if(this.show) {
        }
    })
    emitter.on('password_reset_email', (val) => {
        if(val) {
            this.username = val
        }
    })


    let found = window.location.pathname.match(/^\/reset-password\/(.+)$/)
    let found2 = window.location.search.match(/^\?email=(.+)$/)
    if(found && found2) {
        this.resetToken = found[1]
        this.username = decodeURIComponent(found2[1])
        emitter.emit('popup', 'password_reset')
    }
  },
  watch: {
    show: function(val) {
        if(!val) {
            this.successMessage = null
            this.username = null
            this.showPassword = false
            this.resetToken = null
        }
        if(this.$refs.form) {
            this.$refs.form.resetForm()
        }
    }
  },
  methods: {
    hide: function() {
        emitter.emit('popup', false)
    },
    backToLogin: function() {
        emitter.emit('popup', 'login')
    },
    onInvalid: function({values, errors, results}) {
        let html = '<ul>'+Object.values(errors).map(e => '<li>'+e+'</li>').join('')+'</ul>'
        Swal.fire({
            title: this.$t('generic.error'),
            html: html,
            icon: 'error'
		});
    },

	onSubmit: function(values) {
		Swal.fire({
            title: this.$t('popup.password_reset.password_reset_do'),
            text: this.$t('generic.loading'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
		});

        axios.post('/api/password-reset', values)
            .then((response) => {
                console.log(response);
                if(response.data.error) {
                    Swal.fire(
                        this.$t('generic.error'),
                        response.data.error,
                        'error'
                    )
                    return
                }

                Swal.close()

                this.successMessage = response.data.message
            }).catch( (error) => {
                console.log(error);
                if(error.status==422) {
                    var errors = error.response.data.errors
                    var errorBag = {}
                    for (var field in errors) {
                        errorBag[field] = errors[field].join(', ')
                    }
                    this.$refs.form.setErrors(errorBag)

                    let html = '<ul>'+Object.values(errorBag).map(e => '<li>'+e+'</li>').join('')+'</ul>'
                    Swal.fire({
                        title: this.$t('generic.error'),
                        icon: 'error',
                        html: html
                    })           
                    return         
                }
                Swal.fire(
                    this.$t('generic.error'),
                    this.$t('generic.error_try_again_later'),
                    'error'
                )
            });
	},

    onSubmitReset: function(values) {
		Swal.fire({
            title: this.$t('popup.password_reset.password_reset_do'),
            text: this.$t('generic.loading'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
		});

        axios.post('/api/password-reset-reset', {...values, username: this.username, token: this.resetToken})
            .then((response) => {
                console.log(response);
                if(response.data.error) {
                    Swal.fire(
                        this.$t('generic.error'),
                        response.data.error,
                        'error'
                    )
                    return
                }

                Swal.close()

                Swal.fire({
                    icon: 'success',
                    title: this.$t('popup.password_reset.password_reset_do'),
                    html: response.data.message
                });
                emitter.emit('login_email', this.username)
                emitter.emit('popup', 'login')
                window.history.pushState('logged_in', '', '/');
            }).catch( (error) => {
                console.log(error);
                if(error.status==422) {
                    var errors = error.response.data.errors
                    var errorBag = {}
                    for (var field in errors) {
                        errorBag[field] = errors[field].join(', ')
                    }
                    this.$refs.form.setErrors(errorBag)

                    let html = '<ul>'+Object.values(errorBag).map(e => '<li>'+e+'</li>').join('')+'</ul>'
                    Swal.fire({
                        title: this.$t('generic.error'),
                        icon: 'error',
                        html: html
                    })           
                    return         
                }
                Swal.fire(
                    this.$t('generic.error'),
                    this.$t('generic.error_try_again_later'),
                    'error'
                )
            });
	}
  },
  data() {
	return {
		username: '',
        resetToken: null,
        showPassword: false,
        successMessage: null,
        show: false
	}
  }
}
</script>