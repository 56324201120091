<template>
	<div id="registration" class="popup-container d-flex align-items-center justify-content-center text-box" :class="{'d-none': !show}">
        <div v-if="errorMessage" class="popup-box bg-white px-3 px-sm-4 px-lg-5 pt-5 pb-4 position-relative popup-registrierung">
            <div class="alert alert-danger">
                {{ errorMessage }}
            </div>
        </div>
        <div v-else  class="popup-box bg-white px-3 px-sm-4 px-lg-5 pt-5 pb-4 position-relative popup-registrierung">
            <a
                href="#"
                @click="hide"
                class="close-popup position-absolute"
            >
                <img src="/images/icons/icon-close-popup.svg">
            </a>

            <a
                href="#"
                v-if="step>1 && step<4"
                @click="step--"
                class="text-bold d-flex align-items-center tincx-small text-no-underline text-muted hover-opacity step-back position-absolute">
                <svg class="me-1" width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.5 15.5">
                    <path fill="currentcolor"
                        d="M7.75,15.5c4.27,0,7.75-3.48,7.75-7.75S12.02,0,7.75,0,0,3.48,0,7.75s3.48,7.75,7.75,7.75ZM7.75,1.29c3.58,0,6.46,2.88,6.46,6.46s-2.88,6.46-6.46,6.46S1.29,11.33,1.29,7.75,4.17,1.29,7.75,1.29Z"></path>
                    <path fill="currentcolor"
                        d="M6.46,10.53c.3.3.7.3.89,0s.3-.7,0-.89l-1.29-1.29h4.77c.4,0,.6-.3.6-.6s-.3-.6-.6-.6h-4.77l1.29-1.29c.3-.3.3-.7,0-.89s-.3-.2-.5-.2-.3,0-.5.2l-2.29,2.29h0c-.1.1-.2.3-.2.5s2.58,2.78,2.58,2.78Z"></path>
                </svg>
                {{ $t('generic.back') }}
            </a>


			<p class="my-0 tincx-h4">{{ $t('popup.register.title') }}</p>
			<p class="mt-2 info-text">{{ $t('popup.register.subtitle') }}</p>


            <div class="steps-box" data-steps="4">
                <p>{{ $t('generic.step') }} <strong><span class="counter">{{ step }}</span> von 4</strong></p>
                <span class="steps d-block bg-light">
                    <span class="active-step d-block bg-primary" :style="{width: `${step*100/4}%`}"></span>
                </span>
            </div>

            <Form
                ref="form"
                novalidate
                :initial-values="{country: 'IT'}"
                validate-on-mount
                keep-values
                @submit="nextStep"
                @invalid-submit="onInvalid"
                class="form steps-content position-relative"
                v-slot="{errors, values, meta}"
            >

                <div v-if="false" class="d-block mb-2" style="position: fixed; background: white; top:0;right:0; padding: 20">
                    <pre class="ms-2">{{ {errors: errors, values: values, meta: meta.valid} }}</pre>
                </div>

                <div class="py-4 active" v-if="step==1">


                    <template v-if="competition && competition.type=='matura'">
                        <strong class=" d-block">{{ $t('popup.register.competition.matura.title') }}</strong><br>
                        <span class="d-block" v-html="$t('popup.register.competition.matura.subtitle')" />
                        <ul>
                            <li><b>{{ $t('popup.register.competition.matura.school') }}</b>: {{ competition.school }} ({{ competition.location }})</li>
                            <li><b>{{ $t('popup.register.competition.matura.class') }}</b>: {{ competition.class }}</li>
                            <li><b>{{ $t('popup.register.competition.matura.students') }}</b>: {{ competition.students }}</li>
                        </ul>
                        <Field class="invisible-radio" v-model="userType" rules="required" type="hidden" name="userType" value="private" />
                    </template>

                    <template v-else>
                        <strong>{{ $t('popup.register.i_am') }}</strong>
                            <div class="position-relative type-button">
                                <Field class="invisible-radio" v-model="userType" rules="required" type="radio" name="userType" value="private" />
                                <div @click="userType='private'" class="d-flex align-items-center justify-content-center mt-2">{{ $t('popup.register.type_private') }}</div>
                            </div>
                            <div class="position-relative type-button">
                                <Field class="invisible-radio" v-model="userType" rules="required" type="radio" name="userType" value="business" />
                                <div @click="userType='business'" class="d-flex align-items-center justify-content-center mt-2">{{ $t('popup.register.type_business') }}</div>
                            </div>
                            <div class="position-relative type-button">
                                <Field class="invisible-radio" v-model="userType" rules="required" type="radio" name="userType" value="public" />
                                <div @click="userType='public'" class="d-flex align-items-center justify-content-center mt-2">
                                    {{ $t('popup.register.type_public') }}
                                </div>
                            </div>
                    </template>

                    <div class="text-right mt-3">
                        <button
                            class="btn btn-general btn-bs"
                            :class="{disabled: (Object.keys(errors).length || !meta.valid) }"
                            :data-badge="userType ? undefined : $t('generic.please_select_option')">
                            {{ $t('generic.next') }}
                        </button>
                    </div>

                </div>

                <div class="py-4 active" v-if="step==2">

                    <strong>{{  $t('popup.register.step_2.title') }}</strong>

                    <div class="row row-xsmall mt-3">
                        <div class="col-md-12">
                            <Field
                                type="email"
                                autocomplete="email"
                                v-model="username"
                                name="email"
                                rules="required|email"
                                :placeholder="`${$t('validation.attributes.email')}*`"
                                :class="{'is-invalid': errors.email}"
                                required
                            />
                            <ErrorMessage
                                    as="div"
                                    name="email"
                                    class="invalid-feedback" />
                        </div>
                    </div>
                    <div class="row row-xsmall mt-3">
                        <div class="col-md-6 mt-2 mt-md-0">
                            <div class="position-relative">
                                <Field
                                    :type="showPassword ? 'text' : 'password'"
                                    name="password"
                                    :rules="{required: true, min: 8, regex1: /[A-Z]/, regex2: /[a-z]/, regex3: /[0-9]/ }"
                                    autocomplete="current-password"
                                    :placeholder="`${$t('validation.attributes.password')}*`"
                                    :class="{'is-invalid': errors.password}"
                                />
                                <ErrorMessage
                                    v-if="false"
                                    as="div"
                                    name="password"
                                    class="invalid-feedback" />

                                <div class="invalid-feedback" v-if="errors.password">
                                    Das Password ist erforderlich und muss aus mindestens 8 Zeichen, einem Großbuchstaben, einem Kleinbuchstaben und einer Zahl bestehen!
                                </div>

                                <a
                                    href="#"
                                    tabindex="-1"
                                    @click="showPassword = !showPassword"
                                    :class="{show: !showPassword}"
                                    class="show-password position-absolute">
                                    <svg width="18.8" height="17.4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.8 17.4">
                                        <path fill="#B2B2B2" d="M9.4,6.1L9.4,6.1C8,6.1,6.8,7.3,6.8,8.7c0,0,0,0,0,0c0,1.4,1,2.6,2.4,2.6c0.1,0,0.1,0,0.2,0
c1.4,0,2.6-1,2.6-2.4c0-0.1,0-0.1,0-0.2c0-1.4-1.1-2.6-2.5-2.6H9.4z"/>
                                        <path fill="#B2B2B2" d="M9.4,2.3C5.2,2.3,1.5,4.8,0,8.7c2,5.2,7.9,7.7,13.1,5.7c2.6-1,4.7-3.1,5.7-5.7C17.3,4.9,13.5,2.3,9.4,2.3z
        M9.4,13L9.4,13C7,13,5.1,11.1,5.1,8.7S7,4.4,9.4,4.4s4.3,1.9,4.3,4.3l0,0C13.7,11.1,11.8,13,9.4,13C9.4,13,9.4,13,9.4,13z"/>
                                        <rect x="8.4" y="-2.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.4044 9.1977)" fill="#B2B2B2" class="unvisible-password" width="2"
                                            height="22.6"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-6 mt-2 mt-md-0">
                            <div class="position-relative">
                                <Field
                                    :type="showPassword ? 'text' : 'password'"
                                    name="password_confirmation"
                                    rules="required|confirmed:@password"
                                    autocomplete="current-password"
                                    :placeholder="`${$t('validation.attributes.password_confirmation')}*`"
                                    :class="{'is-invalid': errors.password_confirmation}"
                                />
                                <ErrorMessage
                                    as="div"
                                    name="password_confirmation"
                                    class="invalid-feedback" />
                                <a
                                    href="#"
                                    @click="showPassword = !showPassword"
                                    tabindex="-1"
                                    :class="{show: !showPassword}"
                                    class="show-password position-absolute">
                                    <svg width="18.8" height="17.4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.8 17.4">
                                        <path fill="#B2B2B2" d="M9.4,6.1L9.4,6.1C8,6.1,6.8,7.3,6.8,8.7c0,0,0,0,0,0c0,1.4,1,2.6,2.4,2.6c0.1,0,0.1,0,0.2,0
c1.4,0,2.6-1,2.6-2.4c0-0.1,0-0.1,0-0.2c0-1.4-1.1-2.6-2.5-2.6H9.4z"/>
                                        <path fill="#B2B2B2" d="M9.4,2.3C5.2,2.3,1.5,4.8,0,8.7c2,5.2,7.9,7.7,13.1,5.7c2.6-1,4.7-3.1,5.7-5.7C17.3,4.9,13.5,2.3,9.4,2.3z
        M9.4,13L9.4,13C7,13,5.1,11.1,5.1,8.7S7,4.4,9.4,4.4s4.3,1.9,4.3,4.3l0,0C13.7,11.1,11.8,13,9.4,13C9.4,13,9.4,13,9.4,13z"/>
                                        <rect x="8.4" y="-2.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.4044 9.1977)" fill="#B2B2B2" class="unvisible-password" width="2"
                                            height="22.6"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="text-right mt-3">
                        <button
                            :class="{disabled: Object.keys(errors).length || !meta.valid }"
                            :data-badge="Object.keys(errors).length ? $t('generic.validation_required_fields') : undefined"
                            class="btn btn-general btn-bs"
                        >
                            {{ $t('generic.next') }}
                        </button>
                    </div>

                </div>

                <div class="py-4 active" v-if="step==3 && userType=='private'">

                    <strong>{{  $t('popup.register.step_3.title_private') }}</strong>

                    <div class="row row-xsmall mt-3">
                        <div class="col-md-6">
                            <Field
                                type="text"
                                name="firstname"
                                :placeholder="`${$t('validation.attributes.firstname')}*`"
                                rules="required"
                                :class="{'is-invalid': errors.firstname}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="firstname"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2 mt-md-0">
                            <Field
                                type="text"
                                name="lastname"
                                :placeholder="`${$t('validation.attributes.lastname')}*`"
                                rules="required"
                                :class="{'is-invalid': errors.lastname}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="lastname"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="text"
                                name="phone"
                                :placeholder="`${$t('validation.attributes.phone')}${competition ? '*' : ''}`"
                                :rules="competition ? 'required' : ''"
                                :class="{'is-invalid': errors.phone}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="phone"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="text"
                                name="tax"
                                :rules="competition ? 'required' : ''"
                                :placeholder="`${$t('validation.attributes.tax')}${competition ? '*' : ''}`"
                                :class="{'is-invalid': errors.tax}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="tax"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="number"
                                name="birth_year"
                                rules="minValue:1900|maxValue:2100"
                                :placeholder="`${$t('validation.attributes.birth_year')}`"
                                placeholder="Geburtsjahr" :class="{'is-invalid': errors.birth_year}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="birth_year"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field as="select" name="gender" :class="{'is-invalid': errors.gender}">
                                <option value="" selected>{{ $t('validation.attributes.gender') }}</option>
                                <option value="male">{{ $t('generic.gender.male')}}</option>
                                <option value="female">{{ $t('generic.gender.female')}}</option>
                                <option value="other">{{ $t('generic.gender.other')}}</option>
                            </Field>
                            <ErrorMessage
                                    as="div"
                                    name="gender"
                                    class="invalid-feedback" />
                        </div>

                        <hr class="mt-3">

                        <div class="col-md-8 mt-2">
                            <Field
                                type="text"
                                name="street"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.street')}*`"
                                :class="{'is-invalid': errors.street}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="street"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-4 mt-2">
                            <Field
                                type="text"
                                name="street_number"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.street_number')}*`"
                                :class="{'is-invalid': errors.street_number}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="street_number"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="number"
                                name="plz"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.plz')}*`"
                                :class="{'is-invalid': errors.plz}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="plz"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="text"
                                name="place"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.place')}*`"
                                :class="{'is-invalid': errors.place}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="place"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-12 mt-2">
                            <Field as="select" name="country" rules="required" :class="{'is-invalid': errors.country}">
                                <option value="" selected>{{ $t('validation.attributes.country') }}*</option>
                                <option value="IT">{{ $t('generic.countries.IT' )}}</option>
                                <option value="AT">{{ $t('generic.countries.AT' )}}</option>
                                <option value="DE">{{ $t('generic.countries.DE' )}}</option>
                                <option value="CH">{{ $t('generic.countries.CH' )}}</option>
                            </Field>
                            <ErrorMessage
                                    as="div"
                                    name="country"
                                    class="invalid-feedback" />
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <div class="position-relative">
                            <label class="d-flex align-items-center me-2 text-small">
                                <Field type="checkbox" v-model="privacy" :value="true" rules="required" name="privacy" />
                                <span  v-if="competition && competition.type=='matura'" v-html="$t('fe.application.privacy_text_matura', {
                                    link: `* [<a target='_blank' href='/privacy'>${$t('fe.application.link')}</a>]`,
                                    competitionLink: `* [<a target='_blank' href='/privacy-gewinnspiel'>${$t('fe.application.link')}</a>]`
                                })"></span>
                                <span v-else v-html="$t('fe.application.privacy_text', { link: `* [<a target='_blank' href='/privacy'>${$t('fe.application.link')}</a>]` })"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="position-relative">
                            <label class="d-flex align-items-center me-2 text-small">
                                <Field type="checkbox" v-model="usage" :value="true" rules="required" name="usage" />
                                <span v-html="$t('fe.application.usage_text', { link: `* [<a target='_blank' href='/nutzungsbedingungen'>${$t('fe.application.link')}</a>]`, linkAgb: `* [<a target='_blank' href='/agb'>${$t('fe.application.link')}</a>]` })"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="position-relative">
                            <label class="d-flex align-items-center mt-3 text-small">
                                <Field type="checkbox" :value="true" name="marketing" />
                                <span v-html="$t('fe.application.marketing_text')"></span>
                            </label>
                        </div>
                    </div>
                    <div class="text-right mt-3">
                        <button
                            :class="{disabled: Object.keys(errors).length || !meta.valid }"
                            class="btn btn-general btn-bs"
                            :data-badge="Object.keys(errors).length ? $t('generic.validation_required_fields') : undefined"
                        >
                            {{ $t('popup.register.step_3.register_button') }}
                        </button>
                    </div>

                </div>

                <div class="py-4 active" data-height="585px" v-if="step==3 && userType!='private'">

                    <strong>{{  $t('popup.register.step_3.title_business') }}</strong>

                    <div class="row row-xsmall mt-3">

                        <div class="col-md-8">
                            <Field
                                type="text"
                                name="company_name"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.company_name')}*`"
                                :class="{'is-invalid': errors.company_name}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="company_name"
                                    class="invalid-feedback" />
                        </div>

                        <div class="col-md-4">
                            <Field
                                type="text"
                                name="employees_count"
                                rules="required|numeric"
                                :placeholder="`${$t('validation.attributes.employees_count')}*`"
                                :class="{'is-invalid': errors.employees_count}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="employees_count"
                                    class="invalid-feedback" />
                        </div>

                        <strong class="mt-4 d-block">{{ $t('popup.register.step_3.section_address_data') }}</strong>

                        <div class="col-md-8 mt-2">
                            <Field
                                type="text"
                                name="street"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.street')}*`"
                                :class="{'is-invalid': errors.street}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="street"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-4 mt-2">
                            <Field
                                type="text"
                                name="street_number"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.street_number')}*`"
                                :class="{'is-invalid': errors.street_number}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="street_number"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="number"
                                name="plz"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.plz')}*`"
                                :class="{'is-invalid': errors.plz}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="plz"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="text"
                                name="place"
                                rules="required"
                                :placeholder="`${$t('validation.attributes.place')}*`"
                                :class="{'is-invalid': errors.place}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="place"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-12 mt-2">
                            <Field as="select" name="country" rules="required" :class="{'is-invalid': errors.country}">
                                <option value="" selected>{{ $t('validation.attributes.country') }}*</option>
                                <option value="IT">{{ $t('generic.countries.IT' )}}</option>
                                <option value="AT">{{ $t('generic.countries.AT' )}}</option>
                                <option value="DE">{{ $t('generic.countries.DE' )}}</option>
                                <option value="CH">{{ $t('generic.countries.CH' )}}</option>
                            </Field>
                            <ErrorMessage
                                    as="div"
                                    name="country"
                                    class="invalid-feedback" />
                        </div>

                        <strong class="mt-4 d-block">{{ $t('popup.register.step_3.section_invoice_data') }}</strong>

                        <div class="col-md-6 mt-2">
                            <Field
                                type="text"
                                name="sdi_code"
                                :placeholder="`${$t('validation.attributes.sdi_code')}`"
                                :rules="userType=='public' ? 'required|min:6|max:6' : 'min:7|max:7'"
                                :class="{'is-invalid': errors.sdi_code}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="sdi_code"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="text"
                                name="pec"
                                :placeholder="`${$t('validation.attributes.pec')}`"
                                rules="email"
                                :class="{'is-invalid': errors.pec}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="pec"
                                    class="invalid-feedback" />
                        </div>

                        <div class="col-md-6 mt-2">
                            <Field
                                type="text"
                                name="tax"
                                :placeholder="`${$t('validation.attributes.tax')}*`"
                                rules="required"
                                :class="{'is-invalid': errors.tax}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="tax"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2">
                            <Field
                                type="text"
                                name="vat"
                                :placeholder="`${$t('validation.attributes.vat')}`"
                                rules=""
                                :class="{'is-invalid': errors.vat}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="vat"
                                    class="invalid-feedback" />
                        </div>

                    </div>

                    <strong class="mt-4 d-block">{{ $t('popup.register.step_3.section_personal_data') }}</strong>

                    <div class="row row-xsmall mt-3">
                        <div class="col-md-6">
                            <Field
                                type="text"
                                name="firstname"
                                :placeholder="`${$t('validation.attributes.firstname')}*`"
                                rules="required"
                                :class="{'is-invalid': errors.firstname}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="firstname"
                                    class="invalid-feedback" />
                        </div>
                        <div class="col-md-6 mt-2 mt-md-0">
                            <Field
                                type="text"
                                name="lastname"
                                :placeholder="`${$t('validation.attributes.lastname')}*`"
                                rules="required"
                                :class="{'is-invalid': errors.lastname}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="lastname"
                                    class="invalid-feedback" />
                        </div>

                        <div class="col-md-12 mt-2">
                            <Field
                                type="text"
                                name="phone"
                                :placeholder="`${$t('validation.attributes.phone')}`"
                                rules="required"
                                :class="{'is-invalid': errors.phone}"
                            />
                            <ErrorMessage
                                    as="div"
                                    name="phone"
                                    class="invalid-feedback" />
                        </div>
                    </div>



                    <label class="d-flex align-items-center mt-3 text-small">
                        <Field
                            type="checkbox"
                            :value="true"
                            name="job_recruitment_agency" />
                            <span class="text-nowrap"> {{ $t('popup.register.step_3.job_recruitment_agency_flag') }}</span>
                    </label>

                    <div class="col-12 mt-3">
                        <div class="position-relative">
                            <label class="d-flex align-items-center me-2 text-small">
                                <Field type="checkbox" v-model="privacy" :value="true" rules="required" name="privacy" />
                                <span v-html="$t('fe.application.privacy_text', { link: `* [<a target='_blank' href='/privacy'>${$t('fe.application.link')}</a>]` })"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="position-relative">
                            <label class="d-flex align-items-center me-2 text-small">
                                <Field type="checkbox" v-model="usage" :value="true" rules="required" name="usage" />
                                <span v-html="$t('fe.application.usage_text', { link: `* [<a target='_blank' href='/nutzungsbedingungen'>${$t('fe.application.link')}</a>]`, linkAgb: `* [<a target='_blank' href='/agb'>${$t('fe.application.link')}</a>]` })"></span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="position-relative">
                            <label class="d-flex align-items-center mt-3 text-small">
                                <Field type="checkbox" :value="true" name="marketing" />
                                <span v-html="$t('fe.application.marketing_text')"></span>
                            </label>
                        </div>
                    </div>
                    <div class="text-right mt-3">
                        <button
                            :class="{disabled: Object.keys(errors).length || !meta.valid }"
                            class="btn btn-general btn-bs"
                            :data-badge="Object.keys(errors).length ? $t('generic.validation_required_fields') : undefined"
                        >
                            {{ $t('popup.register.step_3.register_button') }}
                        </button>
                    </div>

                </div>

                <div class="py-4 active" v-if="step==4">

                    <div class="alert alert-success d-flex align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"  class="bi flex-shrink-0 me-2">
                            <path fill="currentColor" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        <p>
                            <strong>{{  $t('popup.register.step_4.title') }}</strong><br><br>
                            <span v-html="$t('popup.register.step_4.subtitle')" /><br><br>
                            <span v-html="$t('popup.register.step_4.info_email', {email: this.username})" /><br><br>
                            {{ $t('popup.register.step_4.thank_you') }}
                        </p>
                    </div>

                    <a href="#" @click="newMailConfirmation" class="btn btn-white btn-bs">{{ $t('popup.register.step_4.request_new_link') }}</a>

                </div>


                <div class="py-4 active" v-if="step==10">

                    <div class="alert alert-warning d-flex align-items-center">
                        <p>
                            <strong>{{  $t('popup.register.step_10.title') }}</strong><br><br>
                            <span v-html="$t('popup.register.step_10.subtitle')" /><br><br>
                            <span v-html="$t('popup.register.step_10.info_email', {email: this.username})" /><br><br>
                        </p>
                    </div>

                    <a href="#" @click="newMailConfirmation(true)" class="btn btn-white btn-bs">{{ $t('popup.register.step_10.request_new_link') }}</a>

                </div>

            </Form>



            <hr>
            <p class="mb-0 mb-lg-3 mt-4 text-center">
                <span class="anmelden">{{ $t('popup.register.do_you_have_alerady_an_account') }}</span>&nbsp;
                <a href="#" @click="loginNow" class="text-general text-bold text-no-underline d-block d-sm-inline-block" data-popup="login">{{ $t('popup.register.login_now') }}</a>
            </p>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import emitter from '../plugins/mitt';
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
  name: 'Register',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  inject: ['$t'],
  mounted: function() {

    if(document.getElementById('v-register-container').dataset.competition) {
        debugger
        let competitionData = JSON.parse(document.getElementById('v-register-container').dataset.competition)
        if(!competitionData) {
            this.errorMessage = 'Fehler!'
            //this.show = true;
        } else {
            if(competitionData.error) {
                this.errorMessage = competitionData.error
            } else {
                this.competition = competitionData
                this.userType = 'private'
            }
            //this.show = true;
        }

        setTimeout(() => {
            emitter.emit('popup', 'register')
        }, 200)
    }

    setTimeout(() => {
        var search = window.location.search ? new URLSearchParams(window.location.search) : null
        var register = search ? search.get('register') : null
        var user = search ? search.get('user') : null
        if(register=='expiredVerifyUrl' && user) {
            emitter.emit('popup', 'register')
            this.step = 10
            this.userId = user
            window.history.pushState('logged_in', '', '/');
        }
    }, 500)

    /*let found = window.location.pathname.match(/^\/maturantengewinnspiel\/(.+)$/)
    if(found) {
        JSON.parse(document.getElementById('v-register-container').dataset.competition)
        this.competition = {
            type: 'matura',
            class: '??',
            school: '??',
            location: '??',
            students: -1,
            id: 0
        }
        axios.post('/api/competition/matura', {token: found[1]})
            .then((response) => {
                console.log(response);
                if(response.data.error) {
                    this.errorMessage = response.data.error
                } else {
                    this.competition = response.data
                }
            }).catch( (error) => {
                console.log(error);
                this.errorMessage = 'Fehler!'
            })
    }*/

    emitter.on('popup', (val) => {
        this.show = val=='register' ? true : false
    })

    emitter.on('register_email', (val) => {
        if(val) {
            this.username = val
        }
    })

    setTimeout(() => {
        //emitter.emit('popup', 'register')
    }, 1000)
  },
  watch: {
    show: function(val) {
        if(!val) {
            this.step = 1
            this.userId = null
            this.username = ''
            this.userType = null
            this.errorMessage = null
        } else {
            if(this.competition && this.competition.type=='matura') {
                this.userType = 'private'
            }
        }

        if(!val && this.$refs.form) {
            this.$refs.form.resetForm()
        }
    }
  },
  methods: {
    hide: function() {
        emitter.emit('popup', false)
    },
    nextStep: function(values) {
        console.log(values)


        if(this.step==3) {
            this.onSubmit(values)
            return
        }

        this.step++

        //this.$nextTick(() => {
        //    this.$refs.form.validate()
        //})
    },
    onInvalid: function({values, errors, results}) {
        let html = '<ul>'+Object.values(errors).map(e => '<li>'+e+'</li>').join('')+'</ul>'
        Swal.fire({
            title: this.$t('generic.error'),
            html: html,
            icon: 'error'
		});
    },
    loginNow: function() {
        if(this.username) {
            emitter.emit('login_email', this.username)
        }
        emitter.emit('popup', 'login')
    },

	onSubmit: function(values) {
		Swal.fire({
            title: this.$t('popup.register.title'),
            text: this.$t('generic.loading'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
		});

        axios.post('/api/register', {...values, competition: this.competition})
            .then((response) => {
                console.log(response);
                if(response.data.error) {
                    Swal.fire(
                        this.$t('generic.error'),
                        response.data.error,
                        'error'
                    )
                    return
                }

                Swal.close()
                //this.successMessage = response.data.message
                this.step++;
            }).catch( (error) => {
                console.log(error);
                if(error.status==422) {
                    var errors = error.response.data.errors
                    var errorBag = {}
                    for (var field in errors) {
                        errorBag[field] = errors[field].join(', ')
                    }
                    this.$refs.form.setErrors(errorBag)

                    let html = '<ul>'+Object.values(errorBag).map(e => '<li>'+e+'</li>').join('')+'</ul>'
                    Swal.fire({
                        title: this.$t('generic.error'),
                        icon: 'error',
                        html: html
                    })
                    return
                }
                Swal.fire(
                    this.$t('generic.error'),
                    this.$t('generic.error_try_again_later'),
                    'error'
                )
            });
	},
    newMailConfirmation: function(resend = false) {
        Swal.fire({
            //title: this.$t('popup.register.title'),
            text: this.$t('generic.loading'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
		});

        axios.post('/api/resend-mail-confirmation', this.userId ? {id: this.userId} : {email: this.username})
            .then((response) => {
                console.log(response);
                if(response.data.error) {
                    Swal.fire(
                        this.$t('generic.error'),
                        response.data.error,
                        'error'
                    )
                    return
                }



                Swal.fire({
                    icon: 'success',
                    //title: this.$t('popup.register.title'),
                    text: this.$t('popup.register.mail_confirmation_resent', {email: response.data.email}),
                });
            }).catch( (error) => {
                console.log(error);
                if(error.status==422) {
                    var errors = error.response.data.errors
                    var errorBag = {}
                    for (var field in errors) {
                        errorBag[field] = errors[field].join(', ')
                    }
                    this.$refs.form.setErrors(errorBag)

                    let html = '<ul>'+Object.values(errorBag).map(e => '<li>'+e+'</li>').join('')+'</ul>'
                    Swal.fire({
                        title: this.$t('generic.error'),
                        icon: 'error',
                        html: html
                    })
                    return
                }
                Swal.fire(
                    this.$t('generic.error'),
                    this.$t('generic.error_try_again_later'),
                    'error'
                )
            });
    }
  },
  data() {
	return {
        userType: null,
        step: 1,
        competition: null,
        errorMessage: null,
        userId: null,

		username: '',
        showPassword: false,
        show: false
	}
  }
}
</script>