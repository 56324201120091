<template>
	<div id="login" class="popup-container d-flex align-items-center justify-content-center text-box" :class="{'d-none': !show}">
		<div class="popup-box bg-white px-3 px-sm-4 px-lg-5 pt-5 pb-4 position-relative">

			<a 
                href="#"
                @click.prevent="hide"
                class="close-popup position-absolute">
                <img src="/images/icons/icon-close-popup.svg"
				>
            </a>

			<p class="my-0 tincx-h4">{{ $t('popup.login.title') }}</p>
			<p class="mt-2">{{ $t('popup.login.subtitle') }}</p>

			<Form 
                class="form"
                ref="form"
                novalidate
                @submit="onLogin"
                @invalid-submit="onInvalidLogin"
                v-slot="{errors}">
				<div class="row row-xsmall mt-4">
					<div class="col-md-6">
    					<Field
                            type="email" 
                            autocomplete="email"
                            v-model="username"
                            name="username"
                            :placeholder="`${$t('validation.attributes.username')}*`"
                            rules="required|email"
                            :class="{'is-invalid': errors.username}"
                        />
                        <ErrorMessage
                            as="div"
                            name="username"
                            class="invalid-feedback" />
					</div>
					<div class="col-md-6 mt-2 mt-md-0">
					<div class="position-relative">
						<Field
                            :type="showPassword ? 'text' : 'password'"
                            v-model="password"
                            name="password"
                            rules="required|min:5"
                            autocomplete="current-password"
                            :placeholder="`${$t('validation.attributes.password')}*`"
                            :class="{'is-invalid': errors.password}"
                        />
                        <ErrorMessage
                            as="div"
                            name="password"
                            class="invalid-feedback" />

						<a href="#" @click="showPassword = !showPassword" :class="{show: !showPassword}" class="show-password position-absolute">
                            <svg width="18.8" height="17.4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.8 17.4">
                                <path fill="#B2B2B2" d="M9.4,6.1L9.4,6.1C8,6.1,6.8,7.3,6.8,8.7c0,0,0,0,0,0c0,1.4,1,2.6,2.4,2.6c0.1,0,0.1,0,0.2,0
	c1.4,0,2.6-1,2.6-2.4c0-0.1,0-0.1,0-0.2c0-1.4-1.1-2.6-2.5-2.6H9.4z"/>
                                <path fill="#B2B2B2" d="M9.4,2.3C5.2,2.3,1.5,4.8,0,8.7c2,5.2,7.9,7.7,13.1,5.7c2.6-1,4.7-3.1,5.7-5.7C17.3,4.9,13.5,2.3,9.4,2.3z
	 M9.4,13L9.4,13C7,13,5.1,11.1,5.1,8.7S7,4.4,9.4,4.4s4.3,1.9,4.3,4.3l0,0C13.7,11.1,11.8,13,9.4,13C9.4,13,9.4,13,9.4,13z"/>
                                <rect x="8.4" y="-2.6" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.4044 9.1977)" fill="#B2B2B2" class="unvisible-password" width="2"
                                      height="22.6"/>
                            </svg>
						</a>
					</div>
					</div>

				</div>

				<div class="d-xs-flex justify-content-between align-items-center mt-3">
					<label class="d-flex align-items-center me-2 text-small">
					<Field type="checkbox" v-model="rememberMe" :value="true" name="rememberMe" /><span class="text-nowrap"> {{  $t('popup.login.remember_me') }}</span>
					</label>
					<a href="#" @click="passwordReset" class="text-general text-bold text-no-underline mt-2 mt-xs-0 d-block">{{  $t('popup.login.password_reset') }}</a>
				</div>

				<button :class="{disabled: Object.keys(errors).length }" class="my-4 btn btn-large btn-general btn-te w-100 text-uppercase d-flex align-items-center justify-content-center">
					{{ $t('popup.login.button') }}
					<svg class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
					<path fill="#FFFFFF" d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
				C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"></path>
					<path fill="#FFFFFF" d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
				c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
				c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"></path>
					</svg>
				</button>
				<hr>

				<p class="mb-0 mb-lg-3 mt-4 mt-lg-5 text-center">{{ $t('popup.login.register_hint') }}
					<a href="#" @click="registerNow" class="text-general text-bold text-no-underline d-block d-sm-inline-block">{{  $t('popup.login.register_button') }}</a>
                </p>
			</form>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import emitter from '../plugins/mitt';
import Swal from 'sweetalert2'
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  inject: ['$t'],
  mounted: function() {
    if(/*window.location.search=='?login' || */ localStorage.getItem('login_success')) {
        setTimeout(() => {
            Swal.fire(
                {
                    title: this.$t('popup.login.title'),
                    text: this.$t('popup.login.login_success'),
                    icon: 'success',
                }
            )
            window.history.pushState('logged_in', '', '/');

            var registerSource = localStorage.getItem('register_source')
            if(registerSource=='discret_job' || true) {
                localStorage.removeItem('discret_job_search_popup') //show popup again
                localStorage.removeItem('register_source') //show popup again
            }

        }, 500)
        localStorage.removeItem('login_success')
    }

    emitter.on('popup', (val) => {
        this.show = val=='login' ? true : false
    })

    emitter.on('login_email', (val) => {
        if(val) {
            this.username = val
        }
    })

    if(window.location.search=='?logout') {
        setTimeout(() => {
            Swal.fire(
                {
                    //title: this.$t('popup.login.title'),
                    text: this.$t('popup.login.logout_success'),
                    icon: 'success',
                }
            )
            window.history.pushState('logged_in', '', '/');
        }, 500)
    }
  },
  watch: {
    show: function() {
        this.$refs.form.resetForm()
    }
  },
  methods: {
    hide: function() {
        emitter.emit('popup', false)
    },
    onInvalidLogin: function({values, errors, results}) {
        let html = '<ul>'+Object.values(errors).map(e => '<li>'+e+'</li>').join('')+'</ul>'
        Swal.fire({
            title: this.$t('generic.error'),
            html: html,
            icon: 'error'
		});
    },
    passwordReset: function() {
        if(this.username) {
            emitter.emit('password_reset_email', this.username)
        }
        emitter.emit('popup', 'password_reset')
    },
    registerNow() {
        emitter.emit('popup', 'register')
    },

	onLogin: function(values) {
		Swal.fire({
            title: this.$t('popup.login.title'),
            text: this.$t('generic.loading'),
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
		});

        axios.post('/api/login', values)
            .then((response) => {
                console.log(response);
                if(response.data.error) {

                    if(response.data.buttonText) {
                        Swal.fire({
                            icon: 'error',
                            title: this.$t('generic.error'),
                            text: response.data.error,
                            cancelButtonText: this.$t('generic.close'),
                            confirmButtonText: response.data.buttonText,
                            showCancelButton: true,
                            showConfirmButton: true
                        }).then(val => {
                            if(val.isConfirmed) {
                                Swal.showLoading()
                                window.location.href = response.data.buttonUrl
                            }
                        })

                        return
                    }

                    Swal.fire(
                        this.$t('generic.error'),
                        response.data.error,
                        'error'
                    )
                    return
                }

                let redirect = localStorage.getItem('auth_redirect_url')
                if(redirect) {
                    localStorage.removeItem('auth_redirect_url')
                }
                localStorage.setItem('login_success', 'true')
                if(!redirect && response.data.redirect) {
                    redirect = response.data.redirect
                }
                if(redirect) {
                    if(redirect.match(/\/admin/)) {
                        localStorage.removeItem('login_success')
                    }
                    window.location.href = redirect                   
                } else {
                    window.location.reload()
                }
                // todo
            }).catch( (error) => {
                console.log(error);
                if(error.status==422) {
                    var errors = error.response.data.errors
                    var errorBag = {}
                    for (var field in errors) {
                        errorBag[field] = errors[field].join(', ')
                    }
                    this.$refs.form.setErrors(errorBag)

                    let html = '<ul>'+Object.values(errorBag).map(e => '<li>'+e+'</li>').join('')+'</ul>'
                    Swal.fire({
                        title: this.$t('generic.error'),
                        icon: 'error',
                        html: html
                    })           
                    return         
                }
                Swal.fire(
                    this.$t('generic.error'),
                    this.$t('generic.error_try_again_later'),
                    'error'
                )
            });
	}
  },
  data() {
	return {
		username: '',
        password: '',
        rememberMe: false,
        showPassword: false,
        show: false
	}
  }
}
</script>