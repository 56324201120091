<template>
    <div class="title-content">
        <div class="d-flex justify-content-between align-items-center">
            <span v-if="selectedItem.offer_of_the_day" class="badge response-offer_of_the_day me-3">{{ $t('fe.category.auto.offer_of_the_day') }}</span>
            <span v-if="selectedItem.new" class="badge response-new me-3 d-none">{{ $t('fe.category.auto.new') }}</span>
            <strong class="text-muted response-type">{{ selectedItem.type }}</strong>
            <span class="job-time response-date ms-auto">{{ $rtime(selectedItem.date) }}</span>
        </div>
        <div id="logo-mobile" class="d-flex d-lg-none align-items-center justify-content-between mt-3">
            <a v-if="'todo-wishlist'==false"  href="#" class="wishlist">
                <svg class="text-primary" xmlns="http://www.w3.org/2000/svg" width="18" height="16.6" viewBox="0 0 18 16.6">
                    <path
                        fill="currentcolor"
                        d="M13.1,0c-1.6,0-3,.7-4.1,1.9C8,.7,6.5,0,5,0,2.2,0,0,2.1,0,4.8c0,0,0,0,0,.1,0,3.4,3.1,6.2,7.7,10.5l1.3,1.2,1.3-1.2c4.6-4.3,7.7-7,7.7-10.5C18.1,2.3,15.9,0,13.2,0c0,0,0,0-.1,0Z"
                    ></path>
                    <path
                        class="active-path"
                        d="M9.6,14.2l-.2.2-.4.3-.4-.3-.2-.2c-1.9-1.6-3.7-3.4-5.3-5.3-1-1.1-1.7-2.5-1.7-4,0-1,.4-1.9,1-2.6.7-.7,1.6-1,2.5-1,1.2,0,2.2.5,3,1.4l1.1,1.3,1.1-1.3c.7-.9,1.8-1.4,3-1.4,1,0,1.9.4,2.5,1,.7.7,1,1.6,1,2.6,0,1.5-.7,2.9-1.7,4-1.6,1.9-3.4,3.6-5.3,5.3h0Z"
                    ></path>
                </svg>
            </a>
            <div class="logo-sidebar response-logo d-lg-none" v-if="selectedItem.agency && selectedItem.agency.logo">
                <img :src="selectedItem.agency.logo" :alt="`Logo ${selectedItem.agency.name}`" />
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
            <h2 class="tincx-h4 text-uppercase response-title pe-2">{{ selectedItem.title }}</h2>
            <a href="#" class="wishlist d-none d-lg-block" v-if="false && 'todo-wishlist'">
                <svg class="text-primary" xmlns="http://www.w3.org/2000/svg" width="18" height="16.6" viewBox="0 0 18 16.6">
                    <path
                        fill="currentcolor"
                        d="M13.1,0c-1.6,0-3,.7-4.1,1.9C8,.7,6.5,0,5,0,2.2,0,0,2.1,0,4.8c0,0,0,0,0,.1,0,3.4,3.1,6.2,7.7,10.5l1.3,1.2,1.3-1.2c4.6-4.3,7.7-7,7.7-10.5C18.1,2.3,15.9,0,13.2,0c0,0,0,0-.1,0Z"
                    ></path>
                    <path
                        class="active-path"
                        d="M9.6,14.2l-.2.2-.4.3-.4-.3-.2-.2c-1.9-1.6-3.7-3.4-5.3-5.3-1-1.1-1.7-2.5-1.7-4,0-1,.4-1.9,1-2.6.7-.7,1.6-1,2.5-1,1.2,0,2.2.5,3,1.4l1.1,1.3,1.1-1.3c.7-.9,1.8-1.4,3-1.4,1,0,1.9.4,2.5,1,.7.7,1,1.6,1,2.6,0,1.5-.7,2.9-1.7,4-1.6,1.9-3.4,3.6-5.3,5.3h0Z"
                    ></path>
                </svg>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlohTitleContent',
    props: ['selectedItem'],
    inject: ['$t', '$rtime'],
}
</script>
