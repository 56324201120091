<template>
    <div v-if="config && config.buttons.length==1" class="contact-buttons position-relative active show">
        <a
            :href="config.buttons[0].url"
            class="d-flex align-items-center justify-content-center mt-2 btn btn-primary btn-large btn-bs hide-contact bg-primary">
            <svg class="me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                <path
                    fill="#FFFFFF"
                    d="M10.4,20.8c5.7,0,10.4-4.7,10.4-10.4S16.2,0,10.4,0S0,4.7,0,10.4v0C0,16.2,4.7,20.8,10.4,20.8z M10.4,2
        c4.6,0,8.4,3.8,8.4,8.4c0,4.6-3.8,8.4-8.4,8.4S2,15,2,10.4C2,5.8,5.8,2,10.4,2z"
                />
                <path
                    fill="#FFFFFF"
                    d="M5.6,11.2C5.6,11.2,5.6,11.2,5.6,11.2l3.1,3.1c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-1.4-1.4h5.9
        c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6L10.1,8c0.4-0.4,0.4-1,0-1.4C9.9,6.4,9.6,6.3,9.4,6.3S8.9,6.4,8.7,6.6l-3,3c0,0,0,0,0,0.1
        c-0.2,0.2-0.3,0.4-0.3,0.7C5.2,10.7,5.4,11,5.6,11.2z"
                />
            </svg>
            {{ config.buttons[0].title }}
        </a>
    </div>

    <div v-else-if="config" class="contact-buttons position-relative" :class="{open: show, active: show}">
        <div class="active-buttons position-absolute opens-top"><!-- or opens-bottom-->
            <a v-for="button in config.buttons" :href="button.url" class="d-flex mt-2 btn btn-primary justify-content-center btn-large btn-bs">
                {{ button.title }}
            </a>
            <a
                href="#"
                @click.prevent="show = false"
                class="d-flex align-items-center justify-content-center mt-2 btn btn-primary btn-large btn-bs hide-contact bg-primary-muted">
                <svg class="me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                    <path
                        fill="#FFFFFF"
                        d="M10.4,20.8c5.7,0,10.4-4.7,10.4-10.4S16.2,0,10.4,0S0,4.7,0,10.4v0C0,16.2,4.7,20.8,10.4,20.8z M10.4,2
            c4.6,0,8.4,3.8,8.4,8.4c0,4.6-3.8,8.4-8.4,8.4S2,15,2,10.4C2,5.8,5.8,2,10.4,2z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M5.6,11.2C5.6,11.2,5.6,11.2,5.6,11.2l3.1,3.1c0.4,0.4,1,0.4,1.4,0c0.4-0.4,0.4-1,0-1.4l-1.4-1.4h5.9
            c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6L10.1,8c0.4-0.4,0.4-1,0-1.4C9.9,6.4,9.6,6.3,9.4,6.3S8.9,6.4,8.7,6.6l-3,3c0,0,0,0,0,0.1
            c-0.2,0.2-0.3,0.4-0.3,0.7C5.2,10.7,5.4,11,5.6,11.2z"
                    />
                </svg>
                {{ $t('generic.back') }}
            </a>
        </div>
        <a
            href="#"
            @click.prevent="show = true"
            class="d-flex align-items-center justify-content-center mt-2 btn btn-primary btn-large btn-te show-contact"
            >
            {{ config.title }}
            
            <svg v-if="config.icon" class="ms-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20.8">
                <path
                    fill="#FFFFFF"
                    d="M10.4,0C4.7,0,0,4.7,0,10.4s4.7,10.4,10.4,10.4s10.4-4.7,10.4-10.4v0C20.8,4.7,16.1,0,10.4,0z M10.4,18.8
            C5.8,18.8,2,15,2,10.4C2,5.8,5.8,2,10.4,2s8.4,3.8,8.4,8.4C18.8,15,15,18.8,10.4,18.8z"
                />
                <path
                    fill="#FFFFFF"
                    d="M15.2,9.7C15.2,9.6,15.2,9.6,15.2,9.7l-3.1-3.1c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l1.4,1.4H6.2
            c-0.6,0-1,0.4-1,1s0.4,1,1,1h6l-1.4,1.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l3-3c0,0,0,0,0-0.1
            c0.2-0.2,0.3-0.4,0.3-0.7C15.6,10.1,15.5,9.8,15.2,9.7z"
                />
            </svg>
        </a>
    </div>
</template>

<script>
import emitter from '../../plugins/mitt';

export default {
    name: 'ContactButton',
    props: ['config', 'inline'],
    data() {
        return {
            show: false
        }
    },
    mounted: function() {
        emitter.on('hide_all_contact_buttons', (val) => {
            this.show = false
        })
    },
    watch: {
        show: function(val) {
            emitter.emit('show_contact_button', {show: val, inline: this.inline ? true : false})
        }
    }
}
</script>
